<template>
    <ValidationProvider tag="div" :rules="rules" :name="name" v-slot="{ errors, required }">
        <div class="p-fluid custom-input-number">
            <span class="p-float-label">
                <InputNumber :id="name" v-model="internalValue" @input="inputListener" :min="min"
                             :max="max" :useGrouping="useGrouping" :mode="mode"
                             :minFractionDigits="minFractionDigits"
                             :maxFractionDigits="maxFractionDigits"
                             :class="{'p-invalid': errors.length > 0}"/>
                <label :for="name">{{ label }}
                    <span style="color: red">{{ required ? " *" : ""}}</span>
                </label>
            </span>
        </div>
        <span v-if="errors.length > 0" class="p-error">{{ errors[0] }}</span>
    </ValidationProvider>
</template>

<script>
    import InputNumber from "primevue/components/inputnumber/InputNumber";
    import {ValidationProvider} from "vee-validate";

    export default {
        name: "CustomInputNumber",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            mode: {
                type: String,
                default: "decimal",
            },
            min: {
                type: Number,
            },
            max: {
                type: Number,
            },
            minFractionDigits: {
                type: Number,
            },
            maxFractionDigits: {
                type: Number,
            },
            useGrouping: {
                type: Boolean,
            },
            rules: {
                type: [String, Object],
                default: "",
            },
            value: null,
        },

        watch: {
            value: {
                immediate: true,
                handler(val) {
                    this.internalValue = val;
                },
            },
        },

        data() {
            return {internalValue: this.value};
        },

        methods: {
            inputListener(value) {
                this.$emit("input", value);
            },
        },

        components: {InputNumber, ValidationProvider},
    };
</script>

<style scoped>
    .custom-input-number {
        margin-bottom: 20px;
    }
</style>
