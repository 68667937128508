<template>
    <div>
        <h2 class="page-header"><i class="pi pi-file p-mr-3"></i>{{title}}</h2>

        <Toolbar class="actions">
            <template slot="left">
                <Button v-if="role.isAdmin()" :label="$t('button.addAttachment')" icon="pi pi-plus"
                        @click="createAttachment()"/>
            </template>
        </Toolbar>

        <DataTable :value="attachmentList" dataKey="index" v-if="attachmentList.length > 0">
            <Column field="name" :header="$t('label.name')"></Column>
            <Column :header="$t('label.action')" v-if="role.isAdmin()">
                <template #body="slotProps">
                    <router-link :to="{name: 'attachmentEdit', params: {'id': slotProps.data.id,
                                                                        'type': type.toString().toLowerCase()}}">
                        <Button type="button" icon="pi pi-pencil" class="p-button-info p-mr-1"
                                v-tooltip.top="$t('button.edit')"></Button>
                    </router-link>
                    <Button type="button" icon="pi pi-chevron-up" class="p-button-info p-mr-1"
                            @click="increaseSortOrder(slotProps.data)" v-tooltip.top="$t('button.changeOrder')">
                    </Button>
                    <Button type="button" icon="pi pi-chevron-down" class="p-button-info p-mr-1"
                            @click="decreaseSortOrder(slotProps.data)" v-tooltip.top="$t('button.changeOrder')">
                    </Button>
                    <Button type="button" icon="pi pi-trash" class="p-button-danger"
                            @click="openConfirmationDialog(slotProps.data.id)"
                            v-tooltip.top="$t('button.remove')"></Button>
                </template>
            </Column>
        </DataTable>

        <CustomDialog :show="displayConfirmation" :header="$t('dialog.attachmentRemoval')"
                      :message="$t('dialog.attachmentRemovalMessage') + ' ' + selectedAttachmentName +
                          '? ' + $t('dialog.attachmentRemovalWarning')"
                      v-on:action="removeAttachment" v-on:close="closeConfirmationDialog"/>
    </div>
</template>

<script>
    import {
        decreaseAttachmentSortOrderUsingPUT as decreaseSortOrder,
        increaseAttachmentSortOrderUsingPUT as increaseSortOrder,
        getAllAttachmentsUsingGET as getAllAttachments,
        removeAttachmentUsingDELETE as removeAttachment,
    } from "@/swagger/vue-api-client";
    import Button from "primevue/components/button/Button";
    import Toolbar from "primevue/components/toolbar/Toolbar";
    import DataTable from "primevue/components/datatable/DataTable";
    import Column from "primevue/components/column/Column";
    import Tooltip from "primevue/tooltip";
    import {SystemRole} from "../../../utils/SystemRole";
    import {RouterUtils} from "../../../utils/RouterUtils";
    import CustomDialog from "../../../components/form/CustomDialog";
    import {AttachmentType} from "../../../utils/AttachmentType";

    export default {
        name: "AttachmentListView",

        components: {
            CustomDialog, Button, Toolbar, DataTable, Column,
        },

        directives: {
            tooltip: Tooltip,
        },

        mixins: [RouterUtils],

        data() {
            return {
                role: SystemRole,
                attachmentList: [],
                selectedAttachment: null,
                displayConfirmation: false,
            };
        },

        computed: {
            selectedAttachmentName() {
                let attName = "";
                if (this.selectedAttachment) {
                    this.attachmentList.forEach((att) => {
                        if (att.id === this.selectedAttachment) {
                            attName = att.name;
                        }
                    });
                }
                return attName;
            },

            title() {
                if (this.type === AttachmentType.AUDIO) return this.$t("menu.attachments.audios");
                if (this.type === AttachmentType.DOCUMENT) return this.$t("menu.attachments.documents");
                if (this.type === AttachmentType.VIDEO) return this.$t("menu.attachments.videos");
                return "";
            },
        },

        methods: {
            createAttachment() {
                const formattedType = this.type.toString().toLowerCase();
                this.pushAsync({name: "attachmentCreate", params: {poiId: this.$route.params.id, type: formattedType}});
            },

            removeAttachment() {
                removeAttachment({attachmentId: this.selectedAttachment}).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.attachmentRemoveSuccess"));
                    this.attachmentList = this.attachmentList.filter(a => a.id !== this.selectedAttachment);
                    this.closeConfirmationDialog();
                });
            },

            openConfirmationDialog(panoramaId) {
                this.selectedAttachment = panoramaId;
                this.displayConfirmation = true;
            },

            closeConfirmationDialog() {
                this.selectedAttachment = null;
                this.displayConfirmation = false;
            },

            decreaseSortOrder(attachment) {
                decreaseSortOrder({
                    id: attachment.id,
                }).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.attachmentOrderChangeSuccess"));
                    this.refresh();
                });
            },

            increaseSortOrder(attachment) {
                increaseSortOrder({
                    id: attachment.id,
                }).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.attachmentOrderChangeSuccess"));
                    this.refresh();
                });
            },

            refresh() {
                getAllAttachments({poiId: this.$route.params.id, attachmentType: this.type}).then((response) => {
                    this.attachmentList = response.data.sort(
                        (item1, item2) => (item1.sortOrder > item2.sortOrder ? 1 : -1),
                    );
                });
            },
        },

        created() {
            this.refresh();
        },

        props: {
            type: String,
        },
    };
</script>

<style scoped>

</style>
