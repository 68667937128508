<template>
    <div>
        <DataTable :value="answers" dataKey="id">
            <Column field="plContent" :header="$t('label.plContent')" sortable></Column>
            <Column field="enContent" :header="$t('label.enContent')" sortable></Column>
            <Column :header="$t('label.answerOk')" headerStyle="width: 200px">
                <template #body="slotProps">
                    <i class="pi pi-check" v-if="slotProps.data.isCorrect"></i>
                </template>
            </Column>
            <Column :header="$t('label.action')" v-if="role.isAdmin()" headerStyle="width: 240px">
                <template #body="slotProps">
                    <router-link :to="{name: 'quizAnswerEdit', params: {
                        'id': slotProps.data.id,
                        'questionId': questionId}}">
                        <Button type="button" icon="pi pi-pencil" class="p-button-success p-mr-1"
                                v-tooltip.top="$t('button.edit')"></Button>
                    </router-link>
                    <Button type="button" icon="pi pi-chevron-up" class="p-button-success p-mr-1"
                            @click="decreaseSortOrder(slotProps.data.id)" v-tooltip.top="$t('button.changeOrder')">
                    </Button>
                    <Button type="button" icon="pi pi-chevron-down" class="p-button-success p-mr-1"
                            @click="increaseSortOrder(slotProps.data.id)" v-tooltip.top="$t('button.changeOrder')">
                    </Button>
                    <Button type="button" icon="pi pi-trash" class="p-button-danger"
                            @click="openConfirmationDialog(slotProps.data.id)" v-tooltip.top="$t('button.remove')">
                    </Button>
                </template>
            </Column>
        </DataTable>
        <CustomDialog :show="displayConfirmation" :header="$t('dialog.quizAnswerRemoval')"
                      :message="$t('dialog.quizAnswerRemovalMessage') + ' ' + selectedAnswerName + '?'"
                      v-on:action="removeQuizAnswer" v-on:close="closeConfirmationDialog"/>
    </div>
</template>

<script>
    import {
        deleteQuizAnswerUsingDELETE as deleteAnswer,
        getQuizAnswerListUsingGET as getQuizAnswerList,
        decreaseQuizAnswerSortOrderUsingPUT as decreaseSortOrder,
        increaseQuizAnswerSortOrderUsingPUT as increaseSortOrder,
    } from "@/swagger/vue-api-client";
    import {SystemRole} from "@/utils/SystemRole";
    import Button from "primevue/components/button/Button";
    import Column from "primevue/components/column/Column";
    import DataTable from "primevue/components/datatable/DataTable";
    import Tooltip from "primevue/tooltip";
    import CustomDialog from "../../../components/form/CustomDialog";

    export default {
        name: "QuizAnswerTable",

        props: {
            questionId: {
                type: Number,
            },
        },

        components: {
            Button, Column, DataTable, CustomDialog,
        },

        directives: {
            tooltip: Tooltip,
        },

        data() {
            return {
                answers: [],
                selectedAnswer: null,
                role: SystemRole,
                displayConfirmation: false,
            };
        },

        computed: {
            selectedAnswerName() {
                let name = "";
                if (this.selectedAnswer) {
                    this.answers.forEach((answer) => {
                        if (answer.id === this.selectedAnswer) {
                            name = this.appLange === "pl" ? answer.plContent : answer.enContent;
                        }
                    });
                }
                return name ? "\"".concat(name).concat("\"") : name;
            },
        },

        methods: {
            removeQuizAnswer() {
                deleteAnswer({id: this.selectedAnswer}).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.answerRemoveSuccess"));
                    this.closeConfirmationDialog();
                    this.refresh();
                });
            },

            openConfirmationDialog(answerId) {
                this.selectedAnswer = answerId;
                this.displayConfirmation = true;
            },

            closeConfirmationDialog() {
                this.selectedAnswer = null;
                this.displayConfirmation = false;
            },

            refresh() {
                getQuizAnswerList({questionId: this.questionId}).then((response) => {
                    this.answers = response.data.sort((item1, item2) => {
                        if (item1.sortOrder > item2.sortOrder) {
                            return 1;
                        }
                        return -1;
                    });
                });
            },

            increaseSortOrder(answerId) {
                increaseSortOrder({
                    id: answerId,
                }).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.answerOrderChangeSuccess"));
                    this.refresh();
                });
            },

            decreaseSortOrder(answerId) {
                decreaseSortOrder({
                    id: answerId,
                }).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.answerOrderChangeSuccess"));
                    this.refresh();
                });
            },

        },

        created() {
            this.refresh();
        },
    };
</script>

<style scoped>

</style>
