<template>
    <router-link :to="{name: 'walk', params: {id: markerId}}">
        <g class="marker-group">
            <circle :cx="point.x" :cy="point.y"
                    r="20" :class="'hotspot ' + classes"
                    :data-tooltip="tooltip"
                    :data-original-x="point.x" :data-original-y="point.y"
                    :data-is-singular="point.isSingular"
                    @mouseover="showTooltip($event)" @mouseleave="hideTooltip()"
            />
            <g :transform="'translate(' + point.x + ', ' + point.y + ')'" class="direction-arrow">
                <g class="scale-group">
                    <g class="rotation-group">
                        <g transform="translate(13, 0)">
                            <path d="M 15 0 L 15 15 L 30 0 L 15 -15 L 15 15" class="arrow"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
        <div class="visually-hidden">{{tooltip}}</div>
    </router-link>
</template>

<script>
    import {HTMLUtils} from "@/utils/HTMLUtils";

    export default {
        name: "",

        props: {
            markerId: Number,
            point: Object,
            classes: String,
            tooltip: String,
        },

        mixins: [HTMLUtils],
    };

</script>

<style scoped>
    .hotspot {
        stroke-width: 5;
        fill-opacity: 50%;
    }

    .hotspot.panorama {
        cursor: pointer;
        stroke: #ff9100;
        fill: white;
        fill-opacity: 0.8;
        transition: .1s all;
    }

    .hotspot.panorama:hover {
        fill: #ff9100;
        fill-opacity: 100%;
        stroke-width: 6;
    }
    .hotspot.panorama.current-poi {
        fill: #ff9100;
        fill-opacity: 100%;
        stroke-width: 6;
    }
    g.direction-arrow {
        display: none;
    }
    g.direction-arrow.visible {
        display: inherit;
    }
    path.arrow {
        fill: #ff9100;
    }
</style>
