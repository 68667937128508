<template>
    <div>
        <Toast/>
        <h2 class="page-header"><i class="pi pi-map-marker p-mr-3"></i>{{headerTitle}}</h2>

        <div class="p-grid p-ml-4 p-mr-4 p-mt-5">
            <div class="p-field p-col-12 p-md-4">
                <CustomInputNumber name="poiNumber" v-model="poi.number" :use-grouping="false"
                                   :min="1" :label="$t('label.number')"/>
            </div>
            <div class="p-field p-col-12 p-md-4">
                <CustomInputNumber name="poiLatitude" v-model="poi.lat" :min="-90"
                                   :max="90" :minFractionDigits="1" :maxFractionDigits="7"
                                   :label="$t('label.lat')"/>
            </div>
            <div class="p-field p-col-12 p-md-4">
                <CustomInputNumber name="poiLongitude" v-model="poi.lng" :min="-180"
                                   :max="180" :minFractionDigits="1" :maxFractionDigits="7"
                                   :label="$t('label.lng')"/>
            </div>
        </div>

        <hr />

        <ValidationObserver ref="formObserver">
            <div class="p-grid p-ml-4 p-mr-4 p-mt-3">
                <div class="p-col-6">
                    <CustomInputText name="plName" :label="$t('label.plName')"
                                     v-model="poi.plName" rules="required"/>
                    <CustomEditor :value="poi.plDescription" :label="$t('label.plDescription')" ref="plEditor"/>
                </div>
                <div class="p-col-6">
                    <CustomInputText name="enName" :label="$t('label.enName')"
                                     v-model="poi.enName" rules="required"/>
                    <CustomEditor :value="poi.enDescription" :label="$t('label.enDescription')" ref="enEditor"/>
                </div>
            </div>
        </ValidationObserver>

        <div class="p-grid p-ml-4 p-mr-4 p-mt-3">
            <div class="p-col">
                <label for="poiArea" class="p-mr-3">{{$t('label.poiArea')}}</label>
                <div id="poiArea" class="p-mt-2" style="display: flex; justify-content: center; align-items: center;">
                    <Dropdown v-model="selectedArea" :options="areas" class="p-ml-2 p-mr-2"
                              :optionLabel="appLang === 'pl' ? 'plName' : 'enName'"
                              :placeholder="$t('label.chooseArea')"
                              @change="handleAreaSelection($event.value)"/>
                    <Dropdown v-if="selectedArea && selectedArea.areas.length" v-model="selectedSubArea"
                              class="p-ml-2 p-mr-2" :options="selectedArea.areas"
                              :optionLabel="appLang === 'pl' ? 'plName' : 'enName'"
                              :placeholder="$t('label.chooseSubArea')"
                              @change="handleSubAreaSelection($event.value)"/>
                    <Button icon="pi pi-times" @click="resetAreas()"/>
                </div>
            </div>
        </div>

        <div class="p-grid p-ml-4 p-mr-4 p-mt-3" style="display: flex; justify-content: center;">
            <div class="p-field-checkbox p-mt-3 p-mb-5">
                <CustomCheckbox name="poiOptional" v-model="poi.optional"
                                :label="$t('label.optionalPOI')" />
            </div>
        </div>

        <NeighbourListView class="p-mt-5 p-mb-5" v-if="$route.params.id" :poi-list.sync="poi.neighbours"/>
        <div class="p-mr-4" style="margin-top: 60px; margin-bottom: 60px;">
            <Toolbar class="actions">
                <template slot="left">
                    <Button :label="$t('button.savePOIData')" icon="pi pi-save" @click="savePOI"/>
                </template>
            </Toolbar>
        </div>

        <PanoramaListView class="p-mt-5" v-if="$route.params.id"/>
        <AttachmentListView class="p-mt-5 p-mb-5" v-if="$route.params.id" type="AUDIO"/>
        <AttachmentListView class="p-mt-5 p-mb-5" v-if="$route.params.id" type="DOCUMENT"/>
        <AttachmentListView class="p-mt-5 p-mb-5" v-if="$route.params.id" type="VIDEO"/>

        <vue-qrcode :value="poiPublicUrl" />
    </div>
</template>

<script>
    import {
        getPOIUsingGET as getPOI,
        getAreaListUsingGET as getAreaList,
        createOrUpdatePOIUsingPOST as createOrUpdatePOI,
    } from "@/swagger/vue-api-client";
    import Button from "primevue/components/button/Button";
    import Toolbar from "primevue/components/toolbar/Toolbar";
    import Toast from "primevue/toast";
    import Dropdown from "primevue/components/dropdown/Dropdown";
    import VueQrcode from "vue-qrcode";
    import {ValidationObserver} from "vee-validate";
    import CustomInputText from "../../components/form/CustomInputText";
    import PanoramaListView from "./components/PanoramaListView";
    import AttachmentListView from "./components/AttachmentListView";
    import CustomInputNumber from "../../components/form/CustomInputNumber";
    import CustomCheckbox from "../../components/form/CustomCheckbox";
    import NeighbourListView from "./components/NeighbourListView";
    import CustomEditor from "../../components/form/CustomEditor";

    export default {
        name: "POIFormView",

        components: {
            CustomEditor,
            CustomCheckbox,
            CustomInputNumber,
            NeighbourListView,
            AttachmentListView,
            PanoramaListView,
            Button,
            Toolbar,
            Toast,
            CustomInputText,
            Dropdown,
            ValidationObserver,
            VueQrcode,
        },

        data() {
            return {
                poi: {
                    id: null,
                    areaId: null,
                    number: null,
                    lat: null,
                    lng: null,
                    optional: false,
                    plName: null,
                    enName: null,
                    plDescription: null,
                    enDescription: null,
                    neighbours: [],
                },
                areas: [],
                selectedArea: null,
                selectedSubArea: null,
                headerTitle: this.$t("menu.pois.add"),
            };
        },

        methods: {
            savePOI() {
                this.$refs.formObserver.validate().then((valid) => {
                    if (!valid) {
                        return;
                    }
                    this.poi.plDescription = this.$refs.plEditor.getHTMLContent();
                    this.poi.enDescription = this.$refs.enEditor.getHTMLContent();
                    createOrUpdatePOI({poiDto: this.poi}).then(() => {
                        this.$router.go(-1);
                        this.$eventBus.$emit("success", this.$t("snackbar.poiAddSuccess"));
                    }).catch(() => {
                        this.$eventBus.$emit("error", this.$t("snackbar.poiAddError"));
                    });
                });
            },

            prepareAreaList() {
                getAreaList().then((response) => {
                    this.areas = response.data.sort((item1, item2) => (item1.sortOrder > item2.sortOrder ? 1 : -1));
                    this.areas.forEach((area) => {
                        area.areas.sort((item1, item2) => (item1.sortOrder > item2.sortOrder ? 1 : -1));
                    });
                    if (this.$route.params.id) {
                        this.headerTitle = this.$t("menu.pois.edit");
                        this.getPOIData();
                    }
                });
            },

            getPOIData() {
                getPOI({id: this.$route.params.id}).then((response) => {
                    this.poi = response.data;
                    if (this.poi.areaId) this.matchArea();
                });
            },

            matchArea() {
                this.areas.every((area) => {
                    if (area.id === this.poi.areaId) {
                        this.selectedArea = area;
                        return false; // break loop
                    }
                    this.selectedSubArea = area.areas.find(a => a.id === this.poi.areaId);
                    if (this.selectedSubArea) {
                        this.selectedArea = area;
                        return false; // break loop
                    }
                    return true; // continue loop
                });
            },

            handleAreaSelection(area) {
                this.selectedSubArea = null;
                if (area.areas.length) this.poi.areaId = null;
                else this.poi.areaId = area.id;
            },

            handleSubAreaSelection(area) {
                if (area) this.poi.areaId = area.id;
            },

            resetAreas() {
                this.selectedArea = null;
                this.selectedSubArea = null;
                this.poi.areaId = null;
            },
        },

        computed: {
            poiPublicUrl() {
                return process.env.VUE_APP_APP_URL + "/#/spacer/" + this.$route.params.id;
            },
        },

        created() {
            this.prepareAreaList();
        },
    };
</script>

<style scoped>

</style>
