// eslint-disable-next-line import/prefer-default-export
export const TextUtils = {
    methods: {
        numeralForm(number, lang) {
            if (lang === "en") {
                return this.numeralFormEN(number);
            }
            if (lang === "pl") {
                return this.numeralFormPL(number);
            }
            return null;
        },

        numeralFormEN(n) {
            if (n === 1) {
                return "I";
            }
            return "II";
        },

        numeralFormPL(n) {
            if (n === 1) {
                return "I";
            }
            if (n % 100 > 10 && n % 100 <= 20) {
                return "III";
            }
            if (n % 10 >= 2 && n % 10 <= 4) {
                return "II";
            }
            return "III";
        },
    },
};
