<template>
    <div>
        <h2 class="page-header"><i class="pi pi-list p-mr-3"></i>{{ $t('menu.quizzes.list') }}</h2>

        <Toolbar class="actions">
            <template slot="left">
                <Button v-if="role.isAdmin()" :label="$t('button.addQuiz')" icon="pi pi-plus" @click="createQuiz"/>
            </template>
        </Toolbar>

        <QuizTable/>
    </div>
</template>

<script>
    import Button from "primevue/components/button/Button";
    import Toolbar from "primevue/components/toolbar/Toolbar";
    import {SystemRole} from "@/utils/SystemRole";
    import QuizTable from "@/views/quiz/components/QuizTable";

    export default {
        name: "QuizListView",
        components: {QuizTable, Button, Toolbar},

        data() {
            return {
                role: SystemRole,
            };
        },

        methods: {
            createQuiz() {
                this.$router.push({name: "quizCreate"});
            },
        },
    };
</script>

<style scoped>

</style>
