<template>
    <div>
        <Toast/>
        <h2 class="page-header"><i class="pi pi-list p-mr-3"></i>{{headerTitle}}</h2>

        <ValidationObserver ref="formObserver">
            <div class="p-col-4">
                <CustomInputText name="plName" :label="$t('label.plName')" v-model="quiz.plName" rules="required"/>
                <CustomInputText name="enName" :label="$t('label.enName')" v-model="quiz.enName" rules="required"/>
                <div id="poiArea" class="p-mt-2" style="display: flex; justify-content: center; align-items: center;">
                    <Dropdown v-model="selectedArea" :options="areas" class="p-ml-2 p-mr-2"
                              :optionLabel="appLang === 'pl' ? 'plName' : 'enName'"
                              :placeholder="$t('button.chooseArea')"
                              @change="handleAreaSelection()"/>
                    <Button icon="pi pi-times" @click="resetArea()"/>
                </div>
            </div>
        </ValidationObserver>

        <QuizQuestionList v-if="quiz.id" :quiz-id="quiz.id" />

        <Toolbar class="actions p-ml-2 p-mt-3">
            <template slot="left">
                <Button :label="$t('button.saveQuizData')" icon="pi pi-plus" @click="saveQuiz"/>
            </template>
        </Toolbar>
    </div>
</template>


<script>
    import Button from "primevue/components/button/Button";
    import Toolbar from "primevue/components/toolbar/Toolbar";
    import Dropdown from "primevue/components/dropdown/Dropdown";
    import CustomInputText from "@/components/form/CustomInputText";
    import Toast from "primevue/toast";
    import {ValidationObserver} from "vee-validate";
    import {
        createOrUpdateQuizUsingPOST as createOrUpdateQuiz,
        getQuizUsingGET as getQuiz,
        getAreaListUsingGET as getAreaList,
    } from "@/swagger/vue-api-client";
    import QuizQuestionList from "./components/QuizQuestionList";

    export default {
        name: "QuizFormView",
        components: {
            QuizQuestionList, Button, Toolbar, CustomInputText, Toast, ValidationObserver, Dropdown,
        },

        data() {
            return {
                headerTitle: this.$t("menu.quizzes.add"),
                quiz: {
                    id: this.$route.params.id,
                    plName: null,
                    enName: null,
                    areaId: null,
                },
                selectedArea: null,
                areas: [],
            };
        },

        methods: {
            saveQuiz() {
                this.$refs.formObserver.validate().then((valid) => {
                    if (!valid) {
                        return;
                    }
                    if (!this.quiz.areaId) {
                        this.$eventBus.$emit("error", this.$t("snackbar.quizAddError"));
                        return;
                    }
                    createOrUpdateQuiz({quizDto: this.quiz}).then(() => {
                        this.$router.go(-1);
                        this.$eventBus.$emit("success", this.$t("snackbar.quizAddSuccess"));
                    });
                });
            },

            handleAreaSelection() {
                this.quiz.areaId = this.selectedArea.id;
            },

            resetArea() {
                this.selectedArea = null;
                this.quiz.areaId = null;
            },

            prepareAreaList() {
                getAreaList().then((response) => {
                    this.areas = response.data.sort((item1, item2) => (item1.sortOrder > item2.sortOrder ? 1 : -1));
                    if (this.$route.params.id) {
                        this.selectedArea = this.areas.find(a => a.id === this.quiz.areaId);
                    }
                });
            },
        },

        created() {
            if (this.$route.params.id) {
                getQuiz({id: this.$route.params.id}).then((response) => {
                    this.quiz = response.data;
                    this.prepareAreaList();
                });
                this.headerTitle = this.$t("menu.quizzes.edit");
            } else this.prepareAreaList();
        },
    };
</script>

<style scoped>

</style>
