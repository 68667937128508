<template>
    <div class="p-grid">
        <div class="p-col-4">
            <CustomInputText name="firstName" :label="$t('label.firstName')" v-model="value.firstName"/>
            <CustomSelectOneSystemRole name="systemRole" :label="$t('label.systemRole')" v-model="value.systemRole"/>
        </div>
        <div class="p-col-4">
            <CustomInputText name="lastName" :label="$t('label.lastName')" v-model="value.lastName"/>
        </div>
        <div class="p-col-4">
            <CustomInputText name="email" :label="$t('label.email')" v-model="value.email"/>
        </div>
    </div>
</template>

<script>
    import CustomInputText from "../../../components/form/CustomInputText";
    import CustomSelectOneSystemRole from "../../../components/form/CustomSelectOneSystemRole";

    export default {
        name: "UserSearchCriteria",

        props: {
            value: null,
        },

        components: {CustomInputText, CustomSelectOneSystemRole},
    };
</script>

<style scoped>

</style>
