<template>
    <div>
        <h2 class="page-header"><i class="pi pi-image p-mr-3"></i>{{headerTitle}}</h2>

        <ValidationObserver ref="formObserver">
            <div class="p-grid p-ml-4 p-mr-4 p-mt-5">
                <div class="p-field p-col-12 p-md-4">
                    <CustomInputNumber name="yaw" v-model="map.yaw" :min="-180"
                                       :max="180" :minFractionDigits="1" :maxFractionDigits="7"
                                       :label="$t('label.yaw')" rules="required"/>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <CustomInputNumber name="pitch" v-model="map.pitch" :min="-90"
                                       :max="90" :minFractionDigits="1" :maxFractionDigits="7"
                                       :label="$t('label.pitch')" rules="required"/>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <CustomInputNumber name="elevation" v-model="map.elevation" :min="0"
                                       :max="9999.99" :minFractionDigits="0" :maxFractionDigits="2"
                                       :label="$t('label.elevation')" rules="required"/>
                </div>
            </div>
        </ValidationObserver>

        <Toolbar class="actions p-mt-5 p-ml-5">
            <template slot="left">
                <Button v-if="role.isAdmin()" @click="$refs.file.click()" icon="pi pi-image"
                        :label="map.filePath ? $t('button.changeMap') : $t('button.addMap')"></Button>
            </template>
        </Toolbar>

        <div class="image-preview p-mt-5 p-mb-5" v-if="map.encodedData">
            <img class="preview" :src="map.encodedData" alt="Mapa" style="width: 70%; height: auto">
        </div>
        <div class="image-preview p-mt-5 p-mb-5" v-else-if="map.filePath">
            <img class="preview" :src="staticFileUrl(map.filePath)"
                 alt="Panorama" style="width: 70%; height: auto">
        </div>

        <div class="p-grid">
            <div class="p-col-1"></div>
            <div class="p-col-10">
                <DataTable :value="map.controlPoints">
                    <Column field="lat" :header="$t('label.lat')"></Column>
                    <Column field="lng" :header="$t('label.lng')"></Column>
                    <Column field="x" header="x"></Column>
                    <Column field="y" header="y"></Column>
                    <Column :header="$t('label.action')" v-if="role.isAdmin()">
                        <template #body="slotProps">
                            <Button type="button" icon="pi pi-pencil" class="p-button-info p-mr-1"
                                    @click="editPoints(slotProps)" v-tooltip.top="$t('button.edit')"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="p-col-1"></div>
        </div>

        <Toolbar class="actions p-mt-5 p-ml-5">
            <template slot="left">
                <Button v-if="role.isAdmin()" :label="$t('button.saveMapData')" icon="pi pi-save"
                        @click="saveMap(true)"></Button>
            </template>
        </Toolbar>

        <input type="file" ref="file" accept="image/png, image/jpeg" v-show="false" v-on:change="handleUpload">

        <Dialog :header="$t('dialog.editCoordinates')" :visible="showModal" :style="{width: '50vw'}" :modal="true">
            <ValidationObserver ref="modalObserver">
                <div class="p-grid p-ml-2 p-mr-2 p-mt-2">
                    <div class="p-field p-col-6 p-md-6">
                        <CustomInputNumber name="lat" v-model="editedPoints.lat" :min="-90"
                                           :max="90" :minFractionDigits="1" :maxFractionDigits="7"
                                           :label="$t('label.lat')" rules="required"/>
                    </div>
                    <div class="p-field p-col-6 p-md-6">
                        <CustomInputNumber name="lng" v-model="editedPoints.lng" :min="-180"
                                           :max="180" :minFractionDigits="1" :maxFractionDigits="7"
                                           :label="$t('label.lng')" rules="required"/>
                    </div>
                </div>

                <div class="p-grid p-ml-2 p-mr-2">
                    <div class="p-field p-col-6 p-md-6">
                        <CustomInputNumber name="x" v-model="editedPoints.x" :min="0"
                                           label="x" rules="required"/>
                    </div>
                    <div class="p-field p-col-6 p-md-6">
                        <CustomInputNumber name="y" v-model="editedPoints.y" :min="0"
                                           label="y" rules="required"/>
                    </div>
                </div>
            </ValidationObserver>
            <template #footer>
                <Button :label="$t('button.cancel')" icon="pi pi-times" @click="closeModal"/>
                <Button :label="$t('button.save')" icon="pi pi-check" @click="savePoints"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
    import {
        createMapUsingPOST as createMap,
        editMapUsingPUT as saveMap,
        getMapUsingGET as getMap,
    } from "@/swagger/vue-api-client";
    import Button from "primevue/components/button/Button";
    import Toolbar from "primevue/components/toolbar/Toolbar";
    import Column from "primevue/components/column/Column";
    import Dialog from "primevue/dialog";
    import DataTable from "primevue/components/datatable/DataTable";
    import {ValidationObserver} from "vee-validate";
    import Tooltip from "primevue/tooltip";
    import {SystemRole} from "../../../utils/SystemRole";
    import {RouterUtils} from "../../../utils/RouterUtils";
    import {FileUtils} from "../../../utils/FileUtils";
    import CustomInputNumber from "../../../components/form/CustomInputNumber";

    export default {
        name: "MapFormView",

        components: {
            Button, Toolbar, CustomInputNumber, ValidationObserver, Column, DataTable, Dialog,
        },

        directives: {
            tooltip: Tooltip,
        },

        mixins: [RouterUtils, FileUtils],

        data() {
            return {
                role: SystemRole,
                headerTitle: this.$t("menu.maps.add"),
                map: {
                    id: null,
                    areaId: null,
                    yaw: 0,
                    pitch: 0,
                    elevation: 1.5,
                    controlPoints: [],
                    filePath: null,
                    fileName: null,
                    encodedData: null,
                },
                editedIndex: null,
                editedPoints: {},
                showModal: false,
            };
        },

        computed: {
            fileNotAttached() {
                return !this.map.encodedData && !this.map.filePath;
            },
        },

        methods: {
            saveMap(pageReturn) {
                this.$refs.formObserver.validate().then((valid) => {
                    if (!valid) {
                        return;
                    }
                    if (this.fileNotAttached) {
                        this.$eventBus.$emit("error", this.$t("snackbar.mapAddError"));
                        return;
                    }
                    if (this.map.id === null) this.createMap(pageReturn);
                    else this.editMap(pageReturn);
                });
            },

            async createMap(pageReturn) {
                createMap({updateAreaMapDto: this.map}).then((response) => {
                    this.$eventBus.$emit("success", this.$t("snackbar.mapAddSuccess"));
                    if (pageReturn) this.$router.go(-1);
                    else {
                        this.headerTitle = this.$t("menu.maps.edit");
                        getMap({id: response.data}).then((mapResponse) => {
                            this.map = mapResponse.data;
                        });
                        this.pushAsync("/map/edit/" + response.data);
                    }
                });
            },

            editMap(pageReturn) {
                saveMap({updateAreaMapDto: this.map}).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.mapAddSuccess"));
                    if (pageReturn) this.$router.go(-1);
                    else this.refresh();
                });
            },

            async handleUpload() {
                const file = this.$refs.file.files[0];
                this.map.fileName = file.name;
                this.map.encodedData = await this.fileToBase64(file);
                this.saveMap(false);
            },

            validateControlPoints() {
                while (this.map.controlPoints.length < 4) {
                    this.map.controlPoints.push({
                        lat: 0, lng: 0, x: 0, y: 0,
                    });
                }
            },

            refresh() {
                if (this.$route.params.id) {
                    this.headerTitle = this.$t("menu.maps.edit");
                    getMap({id: this.$route.params.id}).then((response) => {
                        this.map = response.data;
                        this.validateControlPoints();
                    });
                }
                if (this.$route.params.areaId) {
                    this.map.areaId = this.$route.params.areaId;
                    this.validateControlPoints();
                }
            },

            editPoints(props) {
                this.editedIndex = props.index;
                this.editedPoints = Object.assign({}, props.data);
                this.showModal = true;
            },

            savePoints() {
                this.$refs.modalObserver.validate().then((valid) => {
                    if (!valid) {
                        return;
                    }
                    this.map.controlPoints[this.editedIndex] = Object.assign({}, this.editedPoints);
                    this.editedIndex = null;
                    this.editedPoints = {};
                    this.showModal = false;
                });
            },

            closeModal() {
                this.showModal = false;
                this.editedIndex = null;
                this.editedPoints = {};
            },
        },

        created() {
            this.refresh();
        },
    };
</script>

<style scoped>

</style>
