<template>
    <div>
        <h2 class="page-header"><i class="pi pi-map-marker p-mr-3"></i>{{$t('menu.pois.title')}}</h2>

        <Toolbar class="actions">
            <template slot="right">
                <Dropdown v-model="selectedPOI" :options="POIOptions"
                          :optionLabel="appLang === 'pl' ? 'plName' : 'enName'" :filter="true"
                          :placeholder="$t('button.choosePOI')" :showClear="true" dataKey="id">
                    <template #value="slotProps">
                        <div v-if="slotProps.value">
                            <div>{{appLang === 'pl' ? slotProps.value.plName : slotProps.value.enName}}</div>
                        </div>
                        <span v-else>
                            {{slotProps.placeholder}}
                        </span>
                    </template>
                    <template #option="slotProps">
                        <div>
                            <div>{{appLang === 'pl' ? slotProps.option.plName : slotProps.option.enName}}</div>
                        </div>
                    </template>
                </Dropdown>
                <Button v-if="role.isAdmin()" :label="$t('button.addPOI')" icon="pi pi-plus" class="p-ml-3"
                        @click="addToList()"/>
            </template>
        </Toolbar>

        <DataTable :value="selectedPOIList" dataKey="index" v-if="selectedPOIList.length > 0">
            <Column field="plName" :header="$t('label.plName')"></Column>
            <Column field="enName" :header="$t('label.enName')"></Column>
            <Column :header="$t('label.action')" v-if="role.isAdmin()">
                <template #body="slotProps">
                    <Button type="button" icon="pi pi-chevron-up" class="p-button-info p-mr-1"
                            @click="increaseSortOrder(slotProps.data.id)"
                            v-tooltip.top="$t('button.changeOrder')"></Button>
                    <Button type="button" icon="pi pi-chevron-down" class="p-button-info p-mr-1"
                            @click="decreaseSortOrder(slotProps.data.id)"
                            v-tooltip.top="$t('button.changeOrder')"></Button>
                    <Button type="button" icon="pi pi-trash" class="p-button-danger"
                            @click="removeFromList(slotProps.data.id)"
                            v-tooltip.top="$t('button.remove')"></Button>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import {
        getPOIListUsingGET as getPOIList,
    } from "@/swagger/vue-api-client";
    import Dropdown from "primevue/components/dropdown/Dropdown";
    import Button from "primevue/components/button/Button";
    import Toolbar from "primevue/components/toolbar/Toolbar";
    import DataTable from "primevue/components/datatable/DataTable";
    import Column from "primevue/components/column/Column";
    import Tooltip from "primevue/tooltip";
    import {SystemRole} from "../../../utils/SystemRole";

    export default {
        name: "PathPOIListView",

        components: {
            Dropdown, Button, Toolbar, DataTable, Column,
        },

        directives: {
            tooltip: Tooltip,
        },

        data() {
            return {
                POIs: [],
                selectedPOIList: [],
                selectedPOI: null,
                role: SystemRole,
            };
        },

        methods: {
            addToList() {
                if (!this.selectedPOI) return;
                const listLength = this.selectedPOIList.length;
                this.selectedPOIList.push({
                    id: this.selectedPOI.id,
                    plName: this.selectedPOI.plName,
                    enName: this.selectedPOI.enName,
                    sortOrder: listLength + 1,
                });
                this.selectedPOI = null;
                this.sync();
            },

            removeFromList(poiId) {
                const poiToBeRemoved = this.selectedPOIList.find(p => p.id === poiId);
                let poiToBeRemovedOrder;
                if (poiToBeRemoved) poiToBeRemovedOrder = poiToBeRemoved.sortOrder;
                const index = this.selectedPOIList.indexOf(poiToBeRemoved);
                if (index > -1) {
                    this.selectedPOIList.splice(index, 1);
                }
                this.selectedPOIList.forEach((poi) => {
                    if (poi.sortOrder > poiToBeRemovedOrder) poi.sortOrder -= 1;
                });
                this.sync();
            },

            increaseSortOrder(poiId) {
                const poiToIncrease = this.selectedPOIList.find(p => p.id === poiId);
                const order = poiToIncrease.sortOrder;
                if (order <= 1) return;
                this.selectedPOIList = this.selectedPOIList.map(
                    (poi) => {
                        if (poi.sortOrder === order) return {...poi, sortOrder: poi.sortOrder - 1};
                        if (poi.sortOrder === order - 1) return {...poi, sortOrder: poi.sortOrder + 1};
                        return poi;
                    },
                );
                this.selectedPOIList = this.selectedPOIList.sort(
                    (item1, item2) => (item1.sortOrder > item2.sortOrder ? 1 : -1),
                );
                this.sync();
            },

            decreaseSortOrder(poiId) {
                const poiToIncrease = this.selectedPOIList.find(p => p.id === poiId);
                const order = poiToIncrease.sortOrder;
                if (order >= this.selectedPOIList.length) return;
                this.selectedPOIList = this.selectedPOIList.map(
                    (poi) => {
                        if (poi.sortOrder === order) return {...poi, sortOrder: poi.sortOrder + 1};
                        if (poi.sortOrder === order + 1) return {...poi, sortOrder: poi.sortOrder - 1};
                        return poi;
                    },
                );
                this.selectedPOIList = this.selectedPOIList.sort(
                    (item1, item2) => (item1.sortOrder > item2.sortOrder ? 1 : -1),
                );
                this.sync();
            },

            sync() {
                this.$emit("update:poi-list", this.selectedPOIList.map(p => ({
                    id: p.id,
                    sortOrder: p.sortOrder,
                })));
            },

            matchPOIs() {
                this.selectedPOIList = this.selectedPOIList.map(item => ({
                    id: item.id,
                    plName: this.POIs.find(p => p.id === item.id).plName,
                    enName: this.POIs.find(p => p.id === item.id).enName,
                    sortOrder: item.sortOrder,
                }));
            },
        },

        computed: {
            POIOptions() {
                return this.POIs.filter(poi => !this.selectedPOIList.map(sel => sel.id).includes(poi.id));
            },
        },

        created() {
            getPOIList().then((response) => {
                this.POIs = response.data.sort((item1, item2) => {
                    if (!item1.area) return 1;
                    if (item1.area > item2.area) {
                        return 1;
                    }
                    return -1;
                });
                this.matchPOIs();
            });
        },

        watch: {
            poiList: {
                handler(val) {
                    this.selectedPOIList = val;
                    if (this.POIs.length > 0) this.matchPOIs();
                },
                immediate: true,
            },
        },

        props: {
            poiList: Array,
        },
    };
</script>

<style scoped>

</style>
