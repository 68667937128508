<template>
    <div>
        <h2 class="page-header"><i class="pi pi-users p-mr-3"></i>{{ $t('menu.users.list') }}</h2>

        <div class="search-criteria" @keyup.enter="search">
            <UserSearchCriteria  ref="userSearchCriteria" v-model="searchCriteria"/>

            <Toolbar>
                <template slot="left">
                    <Button :label="$t('search.search')" icon="pi pi-search" @click="search"/>
                    <Button :label="$t('search.clear')" icon="pi pi-times" @click="clear"/>
                </template>
            </Toolbar>
        </div>

        <Toolbar class="actions">
            <template slot="left">
                <Button v-if="Role.isAdmin()" :label="$t('button.addUser')" icon="pi pi-plus" @click="createUser"/>
            </template>
        </Toolbar>

        <UserTable ref="userTable" :search-criteria="searchCriteria" />
    </div>
</template>

<script>
    import Button from "primevue/components/button/Button";
    import Toolbar from "primevue/components/toolbar/Toolbar";
    import {SystemRole} from "@/utils/SystemRole";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import UserSearchCriteria from "./components/UserSearchCriteria";
    import UserTable from "./components/UserTable";


    export default {
        name: "UserListView",
        components: {
            UserTable, UserSearchCriteria, Button, Toolbar,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
                Role: SystemRole,
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        watch: {
            // eslint-disable-next-line
            "$route.query": function () {
                SearchCriteria.loadCriteria(this);
                this.search();
            },
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$refs.userTable.search();
            },

            createUser() {
                this.$router.push({name: "userCreate"});
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
            },

            getClearSearchCriteria() {
                return {
                    firstName: "",
                    lastName: "",
                    email: "",
                    systemRole: null,
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
        },
    };
</script>

<style scoped>

</style>
