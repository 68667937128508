<template>
    <div>
        <DataTable :value="questions" :expandedRows="expandedRows" dataKey="id"
                   @row-expand="onRowExpand" @row-collapse="onRowCollapse">
            <template #header>
                <div class="table-header-container">
                    <Button icon="pi pi-plus" :label="$t('button.expandQuestions')" @click="expandAll" class="p-mr-2" />
                    <Button icon="pi pi-minus" :label="$t('button.collapseQuestions')" @click="collapseAll" />
                </div>
            </template>
            <Column :expander="true" headerStyle="width: 3rem" />
            <Column field="plContent" :header="$t('label.plContent')" sortable></Column>
            <Column field="enContent" :header="$t('label.enContent')" sortable></Column>
            <Column :header="$t('label.action')" v-if="role.isAdmin()" headerStyle="width: 240px">
                <template #body="slotProps">
                    <router-link :to="{name: 'quizQuestionEdit', params: {
                        'id': slotProps.data.id,
                        'quizId': quizId}}">
                        <Button type="button" icon="pi pi-pencil" class="p-button-info p-mr-1"
                                v-tooltip.top="$t('button.edit')"></Button>
                    </router-link>
                    <Button type="button" icon="pi pi-chevron-up" class="p-button-info p-mr-1"
                            @click="decreaseSortOrder(slotProps.data.id)"
                            v-tooltip.top="$t('button.changeOrder')"></Button>
                    <Button type="button" icon="pi pi-chevron-down" class="p-button-info p-mr-1"
                            @click="increaseSortOrder(slotProps.data.id)" v-tooltip.top="$t('button.changeOrder')">

                    </Button>
                    <Button type="button" icon="pi pi-trash" class="p-button-danger"
                            @click="openConfirmationDialog(slotProps.data.id)" v-tooltip.top="$t('button.remove')">

                    </Button>
                </template>
            </Column>
            <template #expansion="slotProps">
                <div class="answer-list">
                    <QuizAnswerList :question-id="slotProps.data.id" />
                </div>
            </template>
        </DataTable>
        <CustomDialog :show="displayConfirmation" :header="$t('dialog.quizQuestionRemoval')"
                      :message="$t('dialog.quizQuestionRemovalMessage') + ' ' + selectedQuestionName + '? '
                          + $t('dialog.quizQuestionRemovalWarning')"
                      v-on:action="removeQuizQuestion"
                      v-on:close="closeConfirmationDialog"></CustomDialog>
    </div>
</template>

<script>
    import {
        deleteQuizQuestionUsingDELETE as deleteQuestion,
        getQuizQuestionListUsingGET as getQuizQuestionList,
        decreaseQuizQuestionSortOrderUsingPUT as decreaseSortOrder,
        increaseQuizQuestionSortOrderUsingPUT as increaseSortOrder,
    } from "@/swagger/vue-api-client";
    import {SystemRole} from "@/utils/SystemRole";
    import Button from "primevue/components/button/Button";
    import Column from "primevue/components/column/Column";
    import DataTable from "primevue/components/datatable/DataTable";
    import Tooltip from "primevue/tooltip";
    import CustomDialog from "../../../components/form/CustomDialog";
    import QuizAnswerList from "./QuizAnswerList";

    export default {
        name: "QuizQuestionTable",

        props: {
            quizId: {
                type: Number,
            },
        },

        components: {
            QuizAnswerList, Button, Column, DataTable, CustomDialog,
        },

        directives: {
            tooltip: Tooltip,
        },

        data() {
            return {
                questions: [],
                selectedQuestion: null,
                role: SystemRole,
                displayConfirmation: false,
                expandedRows: [],
            };
        },

        computed: {
            selectedQuestionName() {
                let name = "";
                if (this.selectedQuestion) {
                    this.questions.forEach((question) => {
                        if (question.id === this.selectedQuestion) {
                            name = this.appLang === "pl" ? question.plContent : question.enContent;
                        }
                    });
                }
                return name ? "\"".concat(name).concat("\"") : name;
            },
        },

        methods: {
            removeQuizQuestion() {
                deleteQuestion({id: this.selectedQuestion}).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.questionRemoveSuccess"));
                    this.closeConfirmationDialog();
                    this.refresh();
                });
            },

            refresh() {
                getQuizQuestionList({quizId: this.quizId}).then((response) => {
                    this.questions = response.data.sort(((item1, item2) => {
                        if (item1.sortOrder > item2.sortOrder) {
                            return 1;
                        }
                        return -1;
                    }));
                    this.expandedRows = this.questions;
                });
            },

            openConfirmationDialog(questionId) {
                this.selectedQuestion = questionId;
                this.displayConfirmation = true;
            },

            closeConfirmationDialog() {
                this.selectedQuestion = null;
                this.displayConfirmation = false;
            },

            increaseSortOrder(questionId) {
                increaseSortOrder({
                    id: questionId,
                }).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.questionOrderChangeSuccess"));
                    this.refresh();
                });
            },

            decreaseSortOrder(questionId) {
                decreaseSortOrder({
                    id: questionId,
                }).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.questionOrderChangeSuccess"));
                    this.refresh();
                });
            },

            onRowExpand(event) {
                this.expandedRows.push(event.data);
            },

            onRowCollapse(event) {
                this.expandedRows = this.expandedRows.filter(row => row.id !== event.data.id);
            },

            expandAll() {
                this.expandedRows = this.questions;
            },
            collapseAll() {
                this.expandedRows = [];
            },
        },
        created() {
            this.refresh();
        },
    };
</script>

<style lang="less" scoped>
    @import "../../../assets/theme/variable.less";

    .answer-list {
        margin: 0 100px;
        border: 1px solid @primary-color;
        border-radius: 3px;
        padding: 25px 20px;
    }
</style>
