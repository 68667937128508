<template>
    <div>
        <Toolbar class="actions">
            <template slot="left">
                <Button v-if="role.isAdmin()" :label="$t('button.addQuizAnswer')"
                        icon="pi pi-plus" @click="createQuizAnswer"/>
            </template>
        </Toolbar>

        <QuizAnswerTable :question-id="questionId"/>
    </div>
</template>

<script>
    import Button from "primevue/components/button/Button";
    import Toolbar from "primevue/components/toolbar/Toolbar";
    import {SystemRole} from "@/utils/SystemRole";
    import QuizAnswerTable from "@/views/quiz/components/QuizAnswerTable";
    import {
        getQuestionNamesUsingGET as getQuestionNames,
    } from "@/swagger/vue-api-client";

    export default {
        name: "QuizAnswerList",
        components: {QuizAnswerTable, Button, Toolbar},

        props: {
            questionId: {
                type: Number,
            },
        },

        data() {
            return {
                questionNames: null,
                role: SystemRole,
            };
        },

        computed: {
            questionHeader() {
                if (this.questionNames) {
                    return "(Quiz: "
                        + (this.appLang === "pl" ? this.questionNames.plName : this.questionNames.enName) + ")";
                }
                return "";
            },
        },

        created() {
            getQuestionNames({questionId: this.questionId}).then((response) => {
                this.questionNames = response.data;
            });
        },

        methods: {
            createQuizAnswer() {
                this.$router.push({name: "quizAnswerCreate", params: {questionId: this.questionId.toString()}});
            },
        },
    };
</script>

<style scoped>

</style>
