/* eslint-disable */
export const RouterUtils = {

	methods: {
		pushAsync(route) {
			return new Promise((resolve, reject) => {
				this.$router.push(route, resolve, reject);
			});
		}
	}
};
