<template>
    <line
        :x1="from.x" :y1="from.y"
        :x2="to.x" :y2="to.y"
        :class="classes"
    />
</template>

<script>
    export default {
        name: "CustomLine",

        props: {
            from: Object,
            to: Object,
            classes: String,
        },
    };
</script>

<style scoped>

</style>
