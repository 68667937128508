<template>
    <div class="wrapper area-view" v-if="renderView">
        <div class="title-area">
            <div class="area-view-heading-outer">
                <div class="area-view-heading">
                    <router-link to="/" :title="titleBackToHome">
                        <i class="pi pi-home"></i>
                        <span class="visually-hidden">{{ $t('label.mainPage') }}</span>
                    </router-link>
                    <div class="heading-toolbar-mobile">
                        <AccessibilityToolbar/>
                        <CustomSimpleChangeLanguage/>
                    </div>
                </div>
                <h1>{{ areaData.langData[appLang].name }}</h1>
            </div>
            <div class="quiz-lang">
                <AccessibilityToolbar/>
                <CustomSimpleChangeLanguage/>
            </div>
        </div>
        <div class="area-view-columns">
            <div class="l-area">
                <SubareaTile v-for="area in subareasDivided.left" :key="area.id" :area="area"
                             side="l"/>
            </div>
            <div class="r-area">
                <SubareaTile v-for="area in subareasDivided.right" :key="area.id" :area="area"
                             side="r"/>
            </div>
        </div>
        <div class="quiz-btn-outer">
            <button type="button" class="quiz" @click="showModal" @keyup.enter="showModal">
                <lord-icon
                    palette="#eac587;#eac587"
                    animation="morph"
                    icon="question">
                </lord-icon>
            </button>
        </div>
        <modal
            name="quiz-modal"
            scrollabel="true"
            height="auto"
            :adaptive="true"
            @before-open="beforeOpen"
            @before-close="beforeClose"
            class="quiz-modal">
            <div class="quiz-modal-content">
                <Quiz :area-id="areaData.id"/>
            </div>
        </modal>
        <button
            type="button"
            @click="hideModal"
            class="close-modal">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M12 10.6L6.6 5.2 5.2 6.6l5.4 5.4-5.4 5.4
                        1.4 1.4 5.4-5.4 5.4 5.4 1.4-1.4-5.4-5.4 5.4-5.4-1.4-1.4-5.4 5.4z">
                </path>
            </svg>
            <div class="visually-hidden">zamknij quiz</div>
        </button>
        <button type="button" @click="nextQuest" class="button-next-quest">
            <span class="cool-lightbox-button__icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M15.4 12.97l-2.68 2.72 1.34 1.38L19 12l-4.94-5.07-1.34 1.38 2.68 2.72H5v1.94z">
                    </path>
                </svg>
            </span>
        </button>
    </div>
</template>

<script>
    import {getDetailedAreaListDataUsingGET as getDetailedAreaListData} from "@/swagger/vue-api-client";
    import {OfflineData} from "@/offline/OfflineData";
    import Quiz from "@/components/quizzes/Quiz";
    import CustomSimpleChangeLanguage from "@/components/CustomSimpleChangeLanguage";
    import AccessibilityToolbar from "@/components/AccessibilityToolbar";
    import SubareaTile from "@/views/public/components/SubareaTile";

    export default {
        name: "areaView",
        components: {
            SubareaTile, CustomSimpleChangeLanguage, Quiz, AccessibilityToolbar,
        },
        data() {
            return {
                indexedAreaListData: {},
                renderView: false,
                publicPath: process.env.BASE_URL,
            };
        },
        watch: {
            // eslint-disable-next-line func-names
            "$route.params.id": function () {
                this.reloadData();
            },
        },

        created() {
            getDetailedAreaListData().then((response) => {
                this.indexAreaListData(response.data);
            }).catch(() => {
                this.indexAreaListData(OfflineData.getDetailedAreaListData());
            }).then(() => {
                this.reloadData();
            });
        },

        mounted() {
            this.$modal.show("quiz-modal");
        },

        computed: {
            titleBackToHome() {
                return this.appLang === "pl" ? "Strona główna" : "Home page";
            },
        },

        methods: {
            nextQuest() {
                if (document.querySelector(".quiz-result-summary.shown") == null) {
                    document.querySelector(".question-outer.shown").nextElementSibling.classList.add("shown");
                    document.querySelectorAll(".question-outer.shown")[0].classList.remove("shown");
                    document.querySelector(".area-view").classList.remove("answered-outer");
                }
            },
            reloadData() {
                this.renderView = false;
                this.areaData = this.indexedAreaListData[this.$route.params.id];
                this.findSubareas();
                this.divideSubareas();
                this.renderView = true;
            },
            indexAreaListData(flatList) {
                this.areaData = {};
                for (let i = 0; i < flatList.length; i += 1) {
                    this.indexedAreaListData[flatList[i].id] = flatList[i];
                }
            },

            findSubareas() {
                this.subareas = [];
                for (let i = 0; i < this.areaData.childrenIds.length; i += 1) {
                    const subarea = this.indexedAreaListData[this.areaData.childrenIds[i]];
                    this.subareas.push(subarea);
                }
            },

            divideSubareas() {
                this.subareasDivided = {
                    left: [],
                    right: [],
                };
                for (let i = 0; i < this.subareas.length; i += 1) {
                    const side = i % 2 === 0 ? "left" : "right";
                    this.subareasDivided[side].push(this.subareas[i]);
                }
            },

            beforeOpen() {
                document.querySelector(".wrapper.area-view").classList.add("quiz-modal-shown");
            },

            beforeClose() {
                document.querySelector(".wrapper.area-view").classList.remove("quiz-modal-shown", "answered-outer");
            },

            showModal() {
                this.$modal.show("quiz-modal");
            },

            hideModal() {
                this.$modal.hide("quiz-modal");
            },
        },
    };
</script>

<style lang="less">
    @import "../assets/less/area-view";
</style>
