<template>
    <div>
        <DataTable :value="quizzes" dataKey="id">
            <Column field="plName" :header="$t('label.plName')" sortable></Column>
            <Column field="enName" :header="$t('label.enName')" sortable></Column>
            <Column :header="$t('label.action')" v-if="role.isAdmin()">
                <template #body="slotProps">
                    <router-link :to="{name: 'quizEdit', params: {'id': slotProps.data.id}}">
                        <Button type="button" icon="pi pi-pencil" class="p-button-info p-mr-1"
                                v-tooltip.top="$t('button.edit')"></Button>
                    </router-link>
                    <Button type="button" icon="pi pi-trash" class="p-button-danger" v-tooltip.top="$t('button.remove')"
                            @click="openConfirmationDialog(slotProps.data.id)"></Button>
                </template>
            </Column>
        </DataTable>
        <CustomDialog :show="displayConfirmation" :header="$t('dialog.quizRemoval')"
                      :message="$t('dialog.quizRemovalMessage') + ' ' + selectedQuizName + '? '
                          + $t('dialog.quizRemovalWarning')"
                      v-on:action="removeQuiz" v-on:close="closeConfirmationDialog"/>
    </div>
</template>

<script>
    import {deleteQuizUsingDELETE as deleteQuiz, getQuizListUsingGET as getQuizList} from "@/swagger/vue-api-client";
    import {SystemRole} from "@/utils/SystemRole";
    import Button from "primevue/components/button/Button";
    import Column from "primevue/components/column/Column";
    import DataTable from "primevue/components/datatable/DataTable";
    import Tooltip from "primevue/tooltip";
    import CustomDialog from "../../../components/form/CustomDialog";

    export default {
        name: "QuizTable",

        components: {
            Button, Column, DataTable, CustomDialog,
        },

        directives: {
            tooltip: Tooltip,
        },

        data() {
            return {
                quizzes: [],
                selectedQuiz: null,
                role: SystemRole,
                displayConfirmation: false,
            };
        },

        computed: {
            selectedQuizName() {
                let name = "";
                if (this.selectedQuiz) {
                    this.quizzes.forEach((quiz) => {
                        if (quiz.id === this.selectedQuiz) name = this.appLang === "pl" ? quiz.plName : quiz.enName;
                    });
                }
                return name;
            },
        },

        methods: {
            removeQuiz() {
                deleteQuiz({id: this.selectedQuiz}).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.quizRemoveSuccess"));
                    this.closeConfirmationDialog();
                    this.refresh();
                });
            },

            openConfirmationDialog(quizId) {
                this.selectedQuiz = quizId;
                this.displayConfirmation = true;
            },

            closeConfirmationDialog() {
                this.selectedQuiz = null;
                this.displayConfirmation = false;
            },

            refresh() {
                getQuizList().then((response) => {
                    this.quizzes = response.data;
                });
            },
        },

        created() {
            this.refresh();
        },
    };
</script>

<style scoped>

</style>
