export default class Path {
    poiIds;

    poiIdsIndexed = {};

    constructor(pathData) {
        this.poiIds = pathData.poiIds;
        this.indexPoiList();
    }

    indexPoiList() {
        for (let i = 0; i < this.poiIds.length; i += 1) {
            this.poiIdsIndexed[this.poiIds[i]] = i;
        }
    }

    contains(poiId) {
        return typeof this.poiIdsIndexed[poiId] !== "undefined";
    }

    getNeighbourPoiId(id, relativeIndex) {
        if (!this.contains(id)) {
            return null;
        }
        const index = this.poiIdsIndexed[id];
        if (typeof this.poiIds[index + relativeIndex] !== "undefined") {
            return this.poiIds[index + relativeIndex];
        }
        return null;
    }
}
