<template>
    <div>
        <h2 class="page-header"><i class="pi pi-map-marker p-mr-3"></i>{{ $t('menu.pois.list') }}</h2>

        <Toolbar class="actions">
            <template slot="left">
                <Button v-if="role.isAdmin()" :label="$t('button.addPOI')" icon="pi pi-plus" @click="createPOI"/>
            </template>
        </Toolbar>

        <POITable/>
    </div>
</template>

<script>
    import Button from "primevue/components/button/Button";
    import Toolbar from "primevue/components/toolbar/Toolbar";
    import {SystemRole} from "../../utils/SystemRole";
    import {RouterUtils} from "../../utils/RouterUtils";
    import POITable from "./components/POITable";

    export default {
        name: "POIListView",

        components: {
            POITable, Button, Toolbar,
        },

        mixins: [RouterUtils],

        data() {
            return {
                role: SystemRole,
            };
        },

        methods: {
            createPOI() {
                this.pushAsync({name: "poiCreate"});
            },
        },
    };
</script>

<style scoped>

</style>
