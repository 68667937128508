<template>
    <div class="layout-wrapper">
        <div class="sidebar">
            <div class="logo">
                <router-link :to="{name: 'home'}" tag="div">
                    <img src="@/assets/logo.svg" alt="logo" />
                </router-link>
            </div>
            <div class="menu">
                <AppMenu />
            </div>
        </div>

        <div class="main">
            <header>
                <div class="environment-description">
                    {{ envDesc }}
                </div>
                <div class="user-info" v-if="fullName">
                    <CustomSelectLanguage />

                    <div>
                        <i class="pi pi-user" />
                        {{ fullName }}
                    </div>
                    <div class="logout" @click="logout"><i class="pi pi-sign-out" /> {{$t('menu.logout')}}</div>
                </div>
            </header>

            <div class="content">
                <router-view/>
            </div>

            <footer>

            </footer>
        </div>
    </div>
</template>

<script>
    import AppMenu from "../../components/AppMenu";
    import CustomSelectLanguage from "../../components/form/CustomSelectLanguage";

    export default {
        name: "LayoutDashboard",
        components: {CustomSelectLanguage, AppMenu},

        data() {
            return {
                envDesc: process.env.VUE_APP_ENV_DESC,
                fullName: this.$cookie.get("fullName"),
            };
        },

        methods: {
            logout() {
                this.$cookie.delete("fullName");
                this.$cookie.delete("role");
                this.$cookie.delete("token");
                this.$router.push({name: "login"});
            },
        },
    };
</script>

<style lang="less">
    @import "../assets/less/layout-dasboard";
</style>
