<template>
    <div>
        <div class="login-panel-header">
            <img src="@/assets/logo.svg" alt="logo">
        </div>
        <div class="login-panel-content">
            <div class="p-grid">
                <div class="p-col-12">
                    <h2>{{ $t('login.title') }}</h2>
                </div>
                <div class="p-col-12" @keyup.enter="login">
                    <CustomInputText name="login" v-model="loginForm.login" :label="$t('login.login')"/>
                    <CustomPassword name="password" v-model="loginForm.password" :label="$t('login.password')"/>
                </div>
                <div class="p-col-12">
                    <Button :label="$t('login.action.login')" @click="login"/>
                </div>
                <div class="p-col-12">
                    <router-link :to="{name: 'remindPassword'}">
                        {{ $t('login.action.remindPassword') }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/components/button/Button";
    import {loginUsingPOST as loginRequest} from "@/swagger/vue-api-client";
    import CustomPassword from "../components/form/CustomPassword";
    import CustomInputText from "../components/form/CustomInputText";

    export default {
        name: "LoginView",
        components: {CustomInputText, CustomPassword, Button},

        data() {
            return {
                loginForm: {
                    login: "",
                    password: "",
                },
            };
        },

        methods: {
            login() {
                loginRequest({authenticationRequest: this.loginForm})
                    .then((response) => {
                        this.$cookie.set("fullName", response.data.fullName, 10);
                        this.$cookie.set("role", response.data.role, 10);
                        this.$cookie.set("token", response.data.token, 10);
                        this.$router.push({name: "home"});
                    })
                    .catch(() => {
                        // console.log(error);
                    });
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "assets/less/login.less";
</style>
