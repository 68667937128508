<template>
    <div>
        <div class="container-acc">
            <div class="acc">
                <div class="acc-group acc-contrast">
                    <p>{{appLang === "pl" ? "kontrast" : "contrast"}}:</p>
                    <a
                        href="/#"
                        color="#0052A5"
                        class="contrast-normal-button"
                        aria-hidden="true"
                        :title="defaultContrast"
                        @click="$messagesBus.$emit('setContrast', 'normal')"
                    >A</a>
                    <a
                        href="/#"
                        color="#1B1B1B"
                        class="contrast-high-button"
                        aria-hidden="true"
                        :title="blackAndWhiteContrast"
                        @click="$messagesBus.$emit('setContrast', 'highBlack')"
                    >A</a>
                    <a
                        href="/#"
                        color="#1B1B1B"
                        class="contrast-high-yellow-button"
                        aria-hidden="true"
                        :title="yellowAndBlackContrast"
                        @click="$messagesBus.$emit('setContrast', 'highYellow')"
                    >A</a>
                </div>
                <div class="acc-group">
                    <p>{{appLang === "pl" ? "czcionka" : "font"}}:</p>
                    <a
                        href="javascript: void(0)"
                        color="primary"
                        class="normal-font-button"
                        aria-hidden="true"
                        :title="defaultFont"
                        @click="$messagesBus.$emit('setFontSizeAndAdjustBottomBarPosition', 'normal');
                                setActiveBtn('normal')"
                    >A</a>
                    <a
                        href="javascript: void(0)"
                        color="#primary"
                        class="medium-font-button"
                        aria-hidden="true"
                        :title="mediumFont"
                        v-bind:class="{active: activeMediumBtn}"
                        @click="$messagesBus.$emit('setFontSizeAndAdjustBottomBarPosition', 'medium');
                                setActiveBtn('medium')"
                    >A</a>
                    <a
                        href="javascript: void(0)"
                        color="#primary"
                        class="large-font-button"
                        aria-hidden="true"
                        :title="largeFont"
                        v-bind:class="{active: activeLargeBtn}"
                        @click="$messagesBus.$emit('setFontSizeAndAdjustBottomBarPosition', 'large');
                                setActiveBtn('large')"
                    >A</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AccesibilityToolbar",

        computed: {
            defaultContrast() {
                return this.appLang === "pl" ? "kontrast domyślny" : "default contrast";
            },

            blackAndWhiteContrast() {
                return this.appLang === "pl" ? "kontrast czarno-biały" : "black and white contrast";
            },
            yellowAndBlackContrast() {
                return this.appLang === "pl" ? "kontrast żółto-czarny" : "yellow and black contrast";
            },

            defaultFont() {
                return this.appLang === "pl" ? "czcionka domyślna" : "default font";
            },

            mediumFont() {
                return this.appLang === "pl" ? "czcionka średnia" : "medium font";
            },

            largeFont() {
                return this.appLang === "pl" ? "czcionka duża" : "large font";
            },
        },

        data() {
            return {
                activeNormalBtn: false,
                activeMediumBtn: false,
                activeLargeBtn: false,
            };
        },

        methods: {
            setActiveBtn(size) {
                if (size === "normal") {
                    this.activeNormalBtn = true;
                    this.activeMediumBtn = false;
                    this.activeLargeBtn = false;
                }
                if (size === "medium") {
                    this.activeNormalBtn = false;
                    this.activeMediumBtn = true;
                    this.activeLargeBtn = false;
                }
                if (size === "large") {
                    this.activeNormalBtn = false;
                    this.activeMediumBtn = false;
                    this.activeLargeBtn = true;
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "../views/assets/less/accessibility-toolbar";
</style>
