<template>
    <div>
        <div class="top-panel">
            <div class="code">
                404
            </div>
        </div>
        <div class="bottom-panel">
            <div class="icon">
                <i class="pi pi-exclamation-triangle"/>
            </div>
            <div class="content">
                <h3>Nie znalezionio strony</h3>
                <span>Prosimy skontaktować się z administratorem systemu</span>
                <router-link class="redirect-button" tag="div" :to="{name: 'home'}">
                    <Button icon="pi pi-undo" label="Powrót na stronę główną" />
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/components/button/Button";

    export default {
        name: "NotFoundView",
        components: {Button},
    };
</script>

<style lang="less" scoped>
    @import url("@/views/assets/less/status-page.less");

    .top-panel {
        background-color: deeppink;
        .code {
            color: #ff86d6;
        }
    }

    .bottom-panel {
        .icon {
            background-color: deeppink;
        }
    }
</style>
