<template>
    <div class="panorama-legend" :class="{ mapLegenglangEn: appLang !== 'pl'}">
        <div class="map-legend-title">{{legendTitle}}</div>
        <div class="map-legend-list">
            <ul>
                <li><span class="panorama-legend-item legendGoForward"></span><span>{{legendGoForward}}</span></li>
                <li><span class="panorama-legend-item legendNewPlace"></span><span>{{legendNewPlace}}</span></li>
                <li><span class="panorama-legend-item legendAerialView"></span><span>{{legendAerialView}}</span></li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PanoramaLegend",

        computed: {
            legendGoForward() {
                return this.appLang === "pl" ? "Idź dalej" : "Go forward";
            },
            legendNewPlace() {
                return this.appLang === "pl" ? "Idź w nowe miejsce" : "Go to a new place";
            },
            legendAerialView() {
                return this.appLang === "pl" ? "Widok z lotu ptaka" : "Aerial view";
            },
            legendTitle() {
                return this.appLang === "pl" ? "Legenda" : "Legend";
            },
        },

        data() {
            return {
            };
        },

        methods: {
        },
    };
</script>

<style lang="less" scoped>
    @import "../views/assets/less/panorama-legend";
</style>
