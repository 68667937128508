<template>
    <button type="button" class="lang" @click="langSwitch(otherLang)">
        {{ otherLang.toUpperCase() }}
    </button>
</template>

<script>
    export default {
        name: "CustomSimpleChangeLanguage",

        methods: {
            langSwitch(lang) {
                this.$root.$i18n.locale = lang;
                this.$cookie.set("lang", lang);
                document.getElementById("app").setAttribute("data-reclick-bottom-bar", "true");
                if (window.ReactNativeWebView) {
                    const message = {
                        type: "lang",
                        lang,
                    };
                    window.ReactNativeWebView.postMessage(JSON.stringify(message));
                }
            },
        },
        computed: {
            otherLang() {
                const lang = this.appLang;
                if (lang === "en") {
                    document.querySelector("html").setAttribute("lang", "en");
                    return "pl";
                }
                document.querySelector("html").setAttribute("lang", "pl");
                return "en";
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "../assets/theme/variable.less";

    .lang {
        color: @rose;
        padding: 0 0 0 10px;
        z-index: 2;
        position: relative;
        cursor: pointer;
        background-color: transparent;
        border: none;
        &:before {
            content: '';
            display: block;
            width: 1px;
            position: absolute;
            top: -8%;
            background: @brown-light;
            left: 0;
            bottom: -8%;
        }
    }
    .area-view.wrapper {
        .lang {
            color: @black;
        }
    }
</style>
