<template>
    <span class="custom-badge" :style="'background-color:' + internalColor">{{ label }}</span>
</template>

<script>

    export default {
        name: "CustomBadge",
        props: {
            color: {
                type: String,
            },
            label: {
                type: String,
            },
        },

        data() {
            return {
                internalColor: "primary",
            };
        },

        mounted() {
            switch (this.color) {
                case "primary":
                    this.internalColor = "#294a70";
                    break;
                case "secondary":
                    this.internalColor = "#49688e";
                    break;
                case "tertiary":
                    this.internalColor = "#116fbf";
                    break;
                default:
                    this.internalColor = this.color;
            }
        },
    };
</script>

<style scoped>
    .custom-badge {
        border-radius: 4px;
        color: #ffffff;
        padding: 4px 8px;
        font-size: 12px;
    }
</style>
