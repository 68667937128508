import {extend, configure, setInteractionMode} from "vee-validate";
import {required, email} from "vee-validate/dist/rules";
import {i18n} from "./i18n";

setInteractionMode("passive");

configure({
    defaultMessage: (field, values) => {
        // override the field name.
        // eslint-disable-next-line no-underscore-dangle
        values._field_ = i18n.t(`fields.${field}`);

        // eslint-disable-next-line no-underscore-dangle
        return i18n.t(`validation.${values._rule_}`, values);
    },
});

// tu dodajemy nowe reguły,których chcemy użyć lub definiujemy własne:
// https://logaretm.github.io/vee-validate/guide/rules.html#rules
// Install required rule and
extend("required", required);
extend("email", email);
