import pl from "vee-validate/dist/locale/pl.json";

/* eslint-disable */
export const messagesPL = {
    fields: {
        //tu dodajemy nazwy pól, które się wyświetlają przy błędach
        email: "E-mail",
        password: "Hasło",
        firstName: "Imię",
        lastName: "Nazwisko",
        plName: "Polska nazwa",
        enName: "Angielska nazwa",
        poiNumber: "Numer przystanku",
        poiLatitude: "Szerokość geograficzna",
        poiLongitude: "Długość geograficzna",
        plDescription: "Polski opis",
        enDescription: "Angielski opis",
        plContent: "Polska treść",
        enContent: "Angielska treść",
        panoramaLat: "Szerokość geograficzna",
        panoramaLng: "Długość geograficzna",
        panoramaElevation: "Wysokość względem gruntu",
        panoramaNorthOffset: "Odchylenie od północy",
        panoramaDefaultPitch: "Domyślne nachylenie",
        panoramaDefaultYaw: "Domyślny kąt widzenia",
        lat: "Szerokość geograficzna",
        lng: "Długość geograficzna",
        x: "x",
        y: "y",
        yaw: "Kąt widzenia",
        pitch: "Nachylenie",
        elevation: "Wysokość względem gruntu",
    },
    label: {
        map: "Mapa",
        mainPage: "Strona główna",
        description: "Opis",
        gallery: "Galeria",
        video: "Wideo",
        audio: "Audio",
        share: "Udostępnij",
        scrollToLeft: "Przewinięcie galerii w lewo",
        scrollToRight: "Przewinięcie galerii w prawo",
        shareTwitter: "Udostępnij przez Twittera",
        shareFacebook: "Udostępnij przez Facebooka",
        action: "Akcja",
        firstName: "Imię",
        lastName: "Nazwisko",
        email: "E-mail",
        systemRole: "Rola",
        password: "Hasło",
        name: "Nazwa",
        plName: "Polska nazwa",
        enName: "Angielska nazwa",
        plDescription: "Polski opis",
        enDescription: "Angielski opis",
        plContent: "Polska treść",
        enContent: "Angielska treść",
        sharedAttachmentUrl: "URL pliku dla obu wersji",
        plAttachmentUrl: "URL pliku dla wersji polskiej",
        enAttachmentUrl: "URL pliku dla wersji angielskiej",
        yaw: "Kąt widzenia",
        pitch: "Nachylenie",
        arrowAngle: "Kąt strzałki",
        elevation: "Wysokość względem gruntu",
        northOffset: "Odchylenie od północy",
        defaultPitch: "Domyślne nachylenie",
        defaultYaw: "Domyślny kąt widzenia",
        hfov: "Pole widzenia",
        number: "Numer przystanku",
        lat: "Szerokość geograficzna",
        lng: "Długość geograficzna",
        identifier: "Identyfikator",
        correctAnswer: "Poprawna odpowiedź",
        poiArea: "Obszar przystanku",
        area: "Obszar",
        chooseArea: "Wybierz obszar",
        chooseSubArea: "Wybierz podobszar",
        optionalPOI: "Przystanek opcjonalny",
        slug: "Nazwa maszynowa",
        answerOk: "Poprawna odpowiedź",
        panorams: "Panoramy",
        path: "Ścieżka tematyczna",
        POI: "przystanek",
        previous: "poprzedni",
        next: "następny",
        defaultViewerConfig: "Ustawienie domyślne widoku",
        poiPluralAbbr: {
            I: "przystanek",
            II: "przystanki",
            III: "przystanków",
        },
    },
    button: {
        yes: "Tak",
        no: "Nie",
        cancel: "Anuluj",
        save: "Zapisz",
        edit: "Edytuj",
        remove: "Usuń",
        changeOrder: "Zmień kolejność",
        addSubArea: "Dodaj podobszar",
        addUser: "Dodaj użytkownika",
        saveUserData: "Zapisz dane użytkownika",
        addArea: "Dodaj obszar",
        saveAreaData: "Zapisz dane obszaru",
        chooseArea: "Wybierz obszar",
        addMap: "Dodaj mapę",
        changeMap: "Podmień mapę",
        saveMapData: "Zapisz dane mapy",
        expandAreas: "Rozwiń obszary",
        collapseAreas: "Zwiń obszary",
        addPath: "Dodaj ścieżkę",
        savePathData: "Zapisz dane ścieżki",
        addPOI: "Dodaj przystanek",
        savePOIData: "Zapisz dane przystanku",
        choosePOI: "Wybierz przystanek",
        addQuiz: "Dodaj quiz",
        saveQuizData: "Zapisz dane quizu",
        addQuizQuestion: "Dodaj pytanie",
        saveQuizQuestionData: "Zapisz dane pytania",
        addQuizAnswer: "Dodaj odpowiedź",
        saveQuizAnswerData: "Zapisz dane odpowiedzi",
        showQuestions: "Przejdź do listy pytań",
        showAnswers: "Przejdź do listy odpowiedzi",
        addPanorama: "Dodaj panoramę",
        changePanorama: "Podmień panoramę",
        addThumbnail: "Dodaj miniaturkę",
        changeThumbnail: "Podmień miniaturkę",
        addImage: "Dodaj zdjęcie",
        changeImage: "Podmień zdjęcie",
        savePanoramaData: "Zapisz dane panoramy",
        addAttachment: "Dodaj załącznik",
        saveAttachmentData: "Zapisz dane załącznika",
        addSharedAttachment: "Dodaj plik dla obu wersji",
        editSharedAttachment: "Podmień plik dla obu wersji",
        addPolishAttachment: "Dodaj plik dla wersji polskiej",
        editPolishAttachment: "Podmień plik dla wersji polskiej",
        addEnglishAttachment: "Dodaj plik dla wersji angielskiej",
        editEnglishAttachment: "Podmień plik dla wersji angielskiej",
        expandQuestions: "Rozwiń pytania",
        collapseQuestions: "Zwiń pytania",
        rewriteViewerData: "Przepisz dane widoku",
        scrollLeft: "przewiń w lewo",
        scrollRight: "przewiń w prawo",
    },
    login: {
        title: "Logowanie",
        login: "Login",
        password: "Hasło",
        action: {
            login: "Zaloguj się",
            remindPassword: "Przypomnij hasło",
        },
    },
    menu: {
        users: {
            title: "Użytkownicy",
            list: "Lista użytkowników",
            add: "Dodawanie użytkownika",
            edit: "Edycja użytkownika",
        },
        areas: {
            title: "Obszary",
            list: "Lista obszarów",
            add: "Dodawanie obszaru",
            edit: "Edycja obszaru",
        },
        pois: {
            title: "Przystanki",
            adjacent: "Przystanki sąsiadujące",
            list: "Lista przystanków",
            add: "Dodawanie przystanku",
            edit: "Edycja przystanku",
        },
        paths: {
            title: "Ścieżki",
            list: "Lista ścieżek",
            add: "Dodawanie ścieżki",
            edit: "Edycja ścieżki",
        },
        quizzes: {
            title: "Quizy",
            list: "Lista quizów",
            add: "Dodawanie quizu",
            edit: "Edycja quizu",
            questions: {
                list: "Lista pytań",
                add: "Dodawanie pytania",
                edit: "Edycja pytania",
            },
            answers: {
                list: "Lista odpowiedzi",
                add: "Dodawanie odpowiedzi",
                edit: "Edycja odpowiedzi",
            },
        },
        maps: {
            title: "Mapy",
            add: "Dodawanie mapy",
            edit: "Edycja mapy",
        },

        images: {
            title: "Zdjęcie",
            add: "Dodaj zdjęcie",
            edit: "Podmień zdjęcie",
        },
        panoramas: {
            title: "Panorama",
            add: "Dodawanie panoramy",
            edit: "Edycja panoramy",
        },
        attachments: {
            add: "Dodawanie załącznika",
            edit: "Edycja załącznika",
            audios: "Nagrania audio",
            documents: "Zdjęcia, skany i dokumenty",
            videos: "Pliki video",
            audioSuffix: "audio",
            documentSuffix: "zdjęcia, skany i dokumenty",
            videoSuffix: "video",
            sharedAttachmentInfo: "Dotyczy sytuacji, w których ten sam plik odpowiada wersji polskiej i angielskiej, np. zdjęcie czy skan starego dokumentu",
        },
        logout: "Wyloguj",
    },
    dialog: {
        editCoordinates: "Edycja współrzędnych",
        userRemoval: "Usunięcie użytkownika",
        userRemovalMessage: "Czy na pewno chcesz usunąć użytkownika",
        areaRemoval: "Usunięcie obszaru",
        areaRemovalMessage: "Czy na pewno chcesz usunąć obszar",
        areaRemovalWarning: "W przypadku gdy do obszaru są przypisane przystanki, przypisanie zostanie usunięte. Usunięte zostaną mapy obszaru oraz quizy.",
        poiEdition: "Edycja przystanku",
        poiRemoval: "Usunięcie przystanku",
        poiRemovalMessage: "Czy na pewno chcesz usunąć przystanek",
        poiRemovalWarning: "Wszystkie panoramy oraz załączniki związane z przystankiem zostaną również usunięte.",
        pathRemoval: "Usunięcie ścieżki",
        pathRemovalMessage: "Czy na pewno chcesz usunąć ścieżkę",
        quizRemoval: "Usunięcie quizu",
        quizRemovalMessage: "Czy na pewno chcesz usunąć quiz",
        quizRemovalWarning: "Pytania i odpowiedzi do quizu zostaną również usunięte.",
        quizQuestionRemoval: "Usunięcie pytania",
        quizQuestionRemovalMessage: "Czy na pewno chcesz usunąć pytanie",
        quizQuestionRemovalWarning: "Spowoduje to również usunięcie odpowiedzi na to pytanie.",
        quizAnswerRemoval: "Usunięcie odpowiedzi",
        quizAnswerRemovalMessage: "Czy na pewno chcesz usunąć odpowiedź",
        mapRemoval: "Usunięcie mapy",
        mapRemovalMessage: "Czy na pewno chcesz usunąć mapę",
        mapRemovalWarning: "Plik zostanie również usunięty.",
        panoramaRemoval: "Usunięcie panoramy",
        panoramaRemovalMessage: "Czy na pewno chcesz usunąć panoramę",
        panoramaRemovalWarning: "Plik panoramy zostanie również usunięty.",
        attachmentRemoval: "Usunięcie załącznika",
        attachmentRemovalMessage: "Czy na pewno chcesz usunąć załącznik",
        attachmentRemovalWarning: "Plik załącznika zostanie również usunięty.",
    },
    snackbar: {
        userAddSuccess: "Pomyślnie zapisano dane użytkownika",
        mapAddError: "Aby zapisać mapę, należy dodać plik",
        mapAddSuccess: "Pomyślnie zapisano dane mapy",
        userRemoveSuccess: "Pomyślnie usunięto użytkownika",
        userRemoveError: "Nie można usunąć danego użytkownika",
        mapRemoveSuccess: "Pomyślnie usunięto mapę",
        questionAddSuccess: "Pomyślnie zapisano dane pytania",
        answerAddSuccess: "Pomyślnie zapisano dane odpowiedzi",
        poiRemoveSuccess: "Pomyślnie usunięto przystanek",
        pathAddSuccess: "Pomyślnie zapisano dane ścieżki",
        areaOrderChangeSuccess: "Pomyślnie zmieniona kolejność obszarów",
        poiOrderChangeSuccess: "Pomyślnie zmieniona kolejność przystanków",
        pathwayOrderChangeSuccess: "Pomyślnie zmieniona kolejność ścieżek",
        poiAddSuccess: "Pomyślnie zapisano dane przystanku",
        areaRemoveSuccess: "Pomyślnie usunięto obszar",
        attachmentRemoveSuccess: "Pomyślnie usunięto załącznik",
        pathRemoveSuccess: "Pomyślnie usunięto ścieżkę",
        quizRemoveSuccess: "Pomyślnie usunięto quiz",
        questionRemoveSuccess: "Pomyślnie usunięto pytanie",
        questionOrderChangeSuccess: "Pomyślnie zmieniona kolejność pytań",
        areaAddSuccess: "Pomyślnie zapisano dane obszaru",
        panoramaAddBackendError: "Błąd podczas wysyłania lub konwersji",
        panoramaAddError: "Aby zapisać panoramę, należy dodać pliki",
        panoramaAddSuccess: "Pomyślnie zapisano dane panoramy",
        panoramaRemoveSuccess: "Pomyślnie usunięto panoramę",
        answerRemoveSuccess: "Pomyślnie usunięto odpowiedź",
        answerOrderChangeSuccess: "Pomyślnie zmieniona kolejność odpowiedzi",
        attachmentAddSuccess: "Pomyślnie zapisano dane załącznika",
        attachmentOrderChangeSuccess: "Pomyślnie zmieniona kolejność załączników",
        quizAddError: "Aby zapisać quiz, należy wybrać obszar",
        quizAddSuccess: "Pomyślnie zapisano dane quizu",
        poiAddError: "Sprawdź unikalność numeru",
        attachmentSaveError: "Aby zapisać załącznik, należy dodać plik lub URL oraz zdefiniować nazwy załącznika dla dodanych wersji językowych",
    },
    search: {
        search: "Szukaj",
        clear: "Wyczyść",
    },
    validation: pl.messages,
};
