<template>
    <div>
        <Toast/>
        <h2 class="page-header"><i class="pi pi-list p-mr-3"></i>{{headerTitle}} {{questionHeader}}</h2>

        <ValidationObserver ref="formObserver">
            <div class="p-col-4">
                <CustomInputText name="plContent" :label="$t('label.plContent')"
                                 v-model="quizAnswer.plContent" rules="required"/>
                <CustomInputText name="enContent" :label="$t('label.enContent')"
                                 v-model="quizAnswer.enContent" rules="required"/>
                <input type="checkbox" name="isCorrect" id="isCorrect" v-model="quizAnswer.isCorrect">
                <label for="isCorrect">{{$t('label.correctAnswer')}}</label>
            </div>
        </ValidationObserver>

        <Toolbar class="actions p-ml-2 p-mt-3">
            <template slot="left">
                <Button :label="$t('button.saveQuizAnswerData')" icon="pi pi-plus" @click="saveQuizAnswer"/>
            </template>
        </Toolbar>
    </div>
</template>


<script>
    import Button from "primevue/components/button/Button";
    import Toolbar from "primevue/components/toolbar/Toolbar";
    import CustomInputText from "@/components/form/CustomInputText";
    import Toast from "primevue/toast";
    import {ValidationObserver} from "vee-validate";
    import {
        createOrUpdateQuizAnswerUsingPOST as createOrUpdateQuizAnswer,
        getQuizAnswerUsingGET as getQuizAnswer,
        getQuestionNamesUsingGET as getQuestionNames,
    } from "@/swagger/vue-api-client";

    export default {
        name: "QuizAnswerFormView",
        components: {
            Button, Toolbar, CustomInputText, Toast, ValidationObserver,
        },

        data() {
            return {
                questionNames: null,
                headerTitle: this.$t("menu.quizzes.answers.add"),
                quizAnswer: {
                    id: null,
                    questionId: null,
                    isCorrect: false,
                    plContent: null,
                    enContent: null,
                },
            };
        },

        methods: {
            saveQuizAnswer() {
                this.$refs.formObserver.validate().then((valid) => {
                    if (!valid) {
                        return;
                    }
                    createOrUpdateQuizAnswer({quizAnswerDto: this.quizAnswer}).then(() => {
                        this.$router.go(-1);
                        this.$eventBus.$emit("success", this.$t("snackbar.answerAddSuccess"));
                    });
                });
            },
        },

        computed: {
            questionHeader() {
                if (this.questionNames) {
                    return "(Quiz: "
                        + (this.appLang === "pl" ? this.questionNames.plName : this.questionNames.enName) + ")";
                }
                return "";
            },
        },

        created() {
            if (this.$route.params.id) {
                getQuizAnswer({id: this.$route.params.id}).then((response) => {
                    this.quizAnswer = response.data;
                });
                this.headerTitle = this.$t("menu.quizzes.answers.edit");
            }
            this.quizAnswer.questionId = this.$route.params.questionId;
            getQuestionNames({questionId: this.quizAnswer.questionId}).then((response) => {
                this.questionNames = response.data;
            });
        },
    };
</script>

<style scoped>

</style>
