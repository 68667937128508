<template>
    <ValidationProvider tag="div" :rules="rules" :name="name" v-slot="{ errors, required }">
        <div class="p-fluid custom-input-password">
            <span class="p-float-label">
                <Password :class="{'p-invalid': errors.length > 0}"
                          v-model="internalValue" @input="inputListener" :feedback="false" />
                <label :for="name">
                    {{ label }}<span style="color: red">{{ required ? " *" : ""}}</span>
                </label>
            </span>
        </div>
        <span v-if="errors.length > 0" class="p-error">{{ errors[0] }}</span>
    </ValidationProvider>
</template>

<script>
    import Password from "primevue/components/password/Password";
    import {ValidationProvider} from "vee-validate";

    export default {
        name: "CustomPassword",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            value: null,
            rules: {
                type: [String, Object],
                default: "",

            },
        },

        watch: {
            value: {
                immediate: true,
                handler(val) {
                    this.internalValue = val;
                },
            },
        },

        data() {
            return {internalValue: this.value};
        },

        methods: {
            inputListener(value) {
                this.$emit("input", value);
            },
        },

        components: {Password, ValidationProvider},
    };
</script>

<style scoped>
    .custom-input-password {
        margin-bottom: 10px;
        padding-top: 10px;
    }
</style>
