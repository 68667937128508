import {defineLordIconElement, LordIconElement} from "lord-icon-element";
import {loadAnimation} from "lottie-web";
import * as camera from "../public/icons-cmjw/camera.json";
import * as documents from "../public/icons-cmjw/documents.json";
import * as gallery from "../public/icons-cmjw/gallery.json";
import * as film from "../public/icons-cmjw/film.json";
import * as headphones from "../public/icons-cmjw/headphones.json";
import * as landscape from "../public/icons-cmjw/landscape.json";
import * as map from "../public/icons-cmjw/map.json";
import * as pinOnMap from "../public/icons-cmjw/pin-on-map.json";
import * as pinOnSquareMap from "../public/icons-cmjw/pin-on-square-map.json";
import * as question from "../public/icons-cmjw/question.json";
import * as share from "../public/icons-cmjw/share.json";

defineLordIconElement(loadAnimation);

function registerLordIcon(name, data) {
    LordIconElement.registerIcon(name, data.default);
}

function registerLordIcons() {
    registerLordIcon("camera", camera);
    registerLordIcon("documents", documents);
    registerLordIcon("gallery", gallery);
    registerLordIcon("film", film);
    registerLordIcon("headphones", headphones);
    registerLordIcon("landscape", landscape);
    registerLordIcon("map", map);
    registerLordIcon("pinOnMap", pinOnMap);
    registerLordIcon("pinOnSquareMap", pinOnSquareMap);
    registerLordIcon("question", question);
    registerLordIcon("share", share);
}

registerLordIcons();
