<template>
    <div>
        <Menu :model="items"/>
    </div>
</template>

<script>
    import Menu from "primevue/menu";

    export default {
        name: "AppMenu",

        components: {Menu},

        data() {
            return {
                items: [
                    {
                        label: this.$t("menu.users.title"),
                        icon: "pi pi-users",
                        to: "/user/list",
                    },
                    {
                        label: this.$t("menu.areas.title"),
                        icon: "pi pi-microsoft",
                        to: "/area/list",
                    },
                    {
                        label: this.$t("menu.pois.title"),
                        icon: "pi pi-map-marker",
                        to: "/poi/list",
                    },
                    {
                        label: this.$t("menu.paths.title"),
                        icon: "pi pi-directions",
                        to: "/path/list",
                    },
                    {
                        label: this.$t("menu.quizzes.title"),
                        icon: "pi pi-list",
                        to: "/quiz/list",
                    },

                ],
            };
        },
    };
</script>

<style scoped>

</style>
