<template>
    <div id="app" :key="appKey" v-bind:class="{mediumFont: mediumSize,
                                               largeFont: largeSize,
                                               highBlackContrast: highBlackContrast,
                                               highYellowContrast: highYellowContrast}">
        <custom-snackbar/>
        <router-view/>
    </div>
</template>

<style lang="less">
    @font-face {
        font-family: "Chivo";
        src: url("./assets/fonts/chivo/Chivo-Light.ttf");
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: "Chivo";
        src: url("./assets/fonts/chivo/Chivo-Regular.ttf");
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: "Chivo";
        src: url("./assets/fonts/chivo/Chivo-Bold.ttf");
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-weight: bold;
        font-style: normal;
    }
    @font-face {
        font-family: "Warnock";
        src: url("./assets/fonts/warnock/WarnockPro-Semibold.otf");
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-weight: bold;
        font-style: normal;
    }
    @font-face {
        font-family: "Warnock";
        src: url("./assets/fonts/warnock/WarnockPro-Regular.otf");
        font-weight: normal;
        font-style: normal;
    }
    @import "./assets/theme/variable.less";
    #panorama, html, body {
        width: 100%;
        height: 100%;
        margin: 0;
        background-color: @rose;
    }
    #app {
        font-family: "Chivo", "Helvetica Neue", Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        height: 100%;
        .public-layout-wrapper {
            height: 100%;
            h1, h2 {
                font-family: "Warnock";
                font-weight: bold;
            }
        }
        &.mediumFont {
            font-size: 130%;
            h1, h2,
            .lang,
            .acc-group p {
                font-size: 130%;
            }
            .normal-font-button {
                font-size: 18px;
            }
            .medium-font-button {
                font-size: 22px;
            }
            .large-font-button {
                font-size: 28px;
            }
            .center-con .arrow {
                top: -5px;
            }
            .path-header-prev,
            .path-header-next {
                display: flex;
                align-items: center;
            }
            .thematic-path-button {
                font-size: 17px;
            }
            .wrapper.area-view .quiz-btn-outer .quiz {
                width: 100px;
                &:after {
                    font-size: 20px;
                }
            }
            .area-view .quiz-btn-outer lord-icon {
                width: 32px;
                height: 32px;
            }
            .map-legend {
                right: -157px;
                &:hover {
                    right: 0;
                }
            }
            .panorama-legend {
                right: -249px;
                width: 288px;
                &:hover {
                    right: 0;
                }
            }
            .container-acc .acc .acc-group.acc-contrast a {
                font-size: 19px;
            }
            .info-outher {
                bottom: 41px;
            }
        }
        &.largeFont {
            font-size: 160%;
            h1, h2,
            .lang,
            .acc-group p {
                font-size: 160%;
            }
            .normal-font-button {
                font-size: 20px;
            }
            .medium-font-button {
                font-size: 24px;
            }
            .large-font-button {
                font-size: 30px;
            }
            .center-con .arrow {
                top: -8px;
            }
            .path-header-prev,
            .path-header-next {
                display: flex;
                align-items: center;
            }
            .thematic-path-button {
                font-size: 20px;
            }
            .thematic-path-button-outer {
                right: -109px;
                width: 240px;
                padding: 10px;
                &:hover {
                    right: 0;
                }
                lord-icon {
                    width: 35px;
                    height: 35px;
                }
            }
            .wrapper.area-view .quiz-btn-outer .quiz {
                width: 110px;
                &:after {
                    font-size: 24px;
                }
            }
            .area-view .quiz-btn-outer lord-icon {
                width: 36px;
                height: 36px;
            }
            .map-legend {
                right: -183px;
                &:hover {
                    right: 0;
                }
            }
            .panorama-legend {
                right: -292px;
                width: 335px;
                &:hover {
                    right: 0;
                }
            }
            .container-acc .acc .acc-group.acc-contrast a {
                font-size: 22px;
            }
            .info-outher {
                bottom: 51px;
            }
        }
        &.highBlackContrast {
            .main-map-outer .main-map-heading,
            .thematic-path-button-outer,
            .thematic-path-modal .vm--modal {
                background-color: #000;
            }
            h2 {
                background-color: #000;
            }
            #tooltip {
                background: #000;
                color: #fff;
            }
            .info-text-modal .vm--modal {
                background-color: #000;
                .info-text {
                    color: #fff;
                }
            }
            .key.legend,
            .info-outher,
            .author {
                padding: 4px;
                background-color: #000;
            }
            .wrapper.area-view {
                background-color: #000;
                h1,
                .container-acc,
                .container-acc a {
                    color: #fff;
                    &.contrast-normal-button,
                    &.contrast-high-yellow-button {
                        color: #000;
                    }
                }
                .lang,
                .pi-arrow-left,
                .pi-home {
                    color: #fff;
                }
                .quiz-btn-outer .quiz {
                    background-color: #000;
                }
                .quiz-modal {
                    color: #fff;
                    .radiocontainer {
                        background-color: #000;
                        color: #fff;
                    }
                    .vm--modal {
                        background-color: #000;
                    }
                    button.button-next-quest {
                        background-color: #000;
                    }
                    .p-progressbar {
                        border: 1px solid #fff;
                        background: #000;
                        .p-progressbar-value {
                            background-color: #fff;
                        }
                    }
                }
            }
            #map-view {
                .map-container {
                    .map-heading {
                        background-color: #000;
                    }
                    .main-container-heading .pi-table,
                    .main-container-heading .pi-home,
                    .main-container-heading .pi-arrow-left {
                        color: #fff;
                    }
                    .center-con svg {
                        fill: #fff;
                    }
                    .hotspot.panorama {
                        outline: 10px solid black;
                    }
                }
                .map-legend {
                    background-color: #000;
                }
                #tooltip {
                    background: #000;
                    color: white;
                    border-radius: 5px;
                    border: none;
                    padding: 5px;
                    opacity: 0.8;
                    z-index: 100;
                }
            }
            #panorama {
                .panorama-heading,
                .bottom-bar {
                    background-color: #000;
                }
                .main-container-heading .pi-table,
                .main-container-heading .pi-home,
                .main-container-heading .pi-arrow-left {
                    color: #fff;
                }
                .center-con svg {
                    fill: #fff;
                }
                .bottom-bar {
                    .cool-lightbox .cool-lightbox-thumbs {
                        background-color: #000;
                        width: fit-content;
                        margin: 0 auto;
                        padding: 5px;
                    }
                }
                .cool-lightbox-caption h6 {
                    background-color: #000;
                    padding: 5px;
                    width: fit-content;
                    margin: 0 auto;
                }
                .pnlm-pointer {
                    background-color: #000;
                }
                .panorama-legend {
                    background-color: #000;
                }
            }
            div.pnlm-load-box {
                background-color: #000;
            }
            .pnlm-lbar {
                border-color: #fff;
            }
            .pnlm-lbar-fill {
                background: #fff;
            }
        }
        &.highYellowContrast {
            h2 {
                background-color: yellow;
                border: 1px solid #000;
            }
            .public-layout-wrapper {
                background-color: #000;
            }
            .info-text-modal .vm--modal {
                background-color: yellow;
                .info-text {
                    color: #000;
                }
            }
            #tooltip {
                background: yellow;
                color: #000;
                border: 1px solid #000;
            }
            .container-acc,
            .container-acc a {
                &.contrast-normal-button,
                &.contrast-high-yellow-button {
                    color: #000;
                    border: 1px solid #000;
                }
                &.contrast-normal-button {
                    background-color: @white-bg;
                }
                &.contrast-high-button {
                    background-color: #000;
                    color: #fff;
                }
            }
            .main-map-outer .main-map-heading,
            .thematic-path-button-outer,
            .thematic-path-modal .vm--modal {
                background-color: yellow;
                color: #000;
                border: 1px solid #000;
            }
            * {
                color: #000;
            }
            .key.legend,
            .info-outher,
            .author {
                padding: 4px;
                background-color: yellow;
            }
            .wrapper.area-view {
                background-color: yellow;
                h1,
                .lang,
                .pi-arrow-left,
                .pi-home {
                    color: #000;
                }
                h2,
                .quiz-btn-outer .quiz {
                    background-color: yellow;
                }
                .quiz-btn-outer .quiz:after {
                    color: #000;
                }
                .quiz-modal {
                    .radiocontainer {
                        background-color: yellow;
                        color: #000;
                    }
                    .vm--modal {
                        background-color: yellow;
                    }
                    .checkmark {
                        border: 1px solid #000;
                    }
                    button.button-next-quest {
                        background-color: yellow;
                    }
                    button.button-next-quest path {
                        fill: #000;
                    }
                    .p-progressbar {
                        border: 1px solid #000;
                        background: yellow;
                        .p-progressbar-value {
                            background-color: #000;
                        }
                    }
                }
            }
            #map-view {
                .map-container {
                    .map-heading {
                        background-color: yellow;
                    }
                    .main-container-heading .pi-table,
                    .main-container-heading .pi-home,
                    .main-container-heading .pi-arrow-left {
                        color: #000;
                    }
                    .center-con svg {
                        fill: #000;
                    }
                    .hotspot.panorama {
                        outline: 10px solid black;
                    }
                }
                .map-legend {
                    background-color: #000;
                    .map-legend-list .circle-you-are-here {
                        background-color: #ff9100;
                    }
                }
                #tooltip {
                    background: #000;
                    color: white;
                    border-radius: 5px;
                    border: none;
                    padding: 5px;
                    opacity: 0.8;
                    z-index: 100;
                }
            }
            .pnlm-container {
                background-color: transparent;
            }
            #panorama {
                .panorama-heading,
                .bottom-bar {
                    background-color: yellow;
                }
                .main-container-heading .pi-table,
                .main-container-heading .pi-home,
                .main-container-heading .pi-arrow-left {
                    color: #000;
                }
                .center-con svg {
                    fill: #000;
                }
                .bottom-bar {
                    .cool-lightbox .cool-lightbox-thumbs {
                        background-color: yellow;
                        width: fit-content;
                        margin: 0 auto;
                        padding: 5px;
                        .cool-lightbox-thumbs__list .cool-lightbox__thumb {
                            background-color: #000;
                        }
                    }
                    .cool-lightbox .cool-lightbox-button>.cool-lightbox-button__icon {
                        background-color: yellow;
                    }
                }
                .cool-lightbox-caption h6 {
                    background-color: yellow;
                    padding: 5px;
                    width: fit-content;
                    margin: 0 auto;
                }
                .pnlm-pointer {
                    background-color: yellow;
                }
                .panorama-legend {
                    border: 1px solid #000;
                    background-color: yellow;
                    color: #000;
                }
                .splide__arrow {
                    background-color: yellow;
                    border: 1px solid #000;
                }
            }
            div.pnlm-load-box {
                background-color: yellow;
            }
            .pnlm-lbar {
                border-color: #000;
            }
            .pnlm-lbar-fill {
                background: #000;
            }
            .cool-lightbox .cool-lightbox-zoom {
                background-color: yellow;
            }
        }
    }
    *:focus {
        outline-offset: 3px;
    }

    body {
        margin: 0;
    }

    .center-con {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(-180deg);
        margin-right: 20px;
        position: relative;
        &:after {
            content: '';
            display: block;
            width: 1px;
            position: absolute;
            top: 5%;
            left: -10px;
            bottom: -5%;
            background-color: @brown-light-2;
        }
        svg {
            fill: @brown-light-2;
            height: 13px;
            width: 13px;
        }
        &.arrows {
            #cta {
                .arrow {
                    left: 13%;
                }
            }
        }
    }
    .round {
        width: 22px;
        height: 22px;
    }

    #cta{
        width:100%;
        width: 100%;
        cursor: pointer;
    }

    #cta .arrow {
        left: 25%;
    }
    .arrow {
        position: absolute;
        bottom: 0;
        margin-left:0px;
        width: 12px;
        height: 12px;
        background-size: contain;
        top: 2px;
    }
    .segunda{margin-left: 8px;}

    @keyframes bounceAlpha {
    0% {opacity: 1; transform: translateX(0px) scale(1);}
    25%{opacity: 0; transform:translateX(10px) scale(0.9);}
    26%{opacity: 0; transform:translateX(-10px) scale(0.9);}
    55% {opacity: 1; transform: translateX(0px) scale(1);}
    }

    .bounceAlpha {
        animation-name: bounceAlpha;
        animation-duration:1.4s;
        animation-iteration-count:infinite;
        animation-timing-function:linear;
    }

    .arrow.primera.bounceAlpha {
        animation-name: bounceAlpha;
        animation-duration:1.4s;
        animation-delay:0.2s;
        animation-iteration-count:infinite;
        animation-timing-function:linear;
    }

    .round:hover .arrow{
        animation-name: bounceAlpha;
        animation-duration:1.4s;
        animation-iteration-count:infinite;
        animation-timing-function:linear;
    }
    .round:hover .arrow.primera{
        animation-name: bounceAlpha;
        animation-duration:1.4s;
        animation-delay:0.2s;
        animation-iteration-count:infinite;
        animation-timing-function:linear;
    }
    .public-layout-wrapper {
        .home.router-link-active {
            text-decoration: none;
            display: flex;
            .pi-home {
                font-size: 16px;
            }
        }
    }

</style>
<script>
    import CustomSnackbar from "./components/CustomSnackbar";

    export default {
        components: {CustomSnackbar},

        data() {
            return {
                appKey: true,
                normalSize: false,
                mediumSize: false,
                largeSize: false,
                mediaHeight: 0,
                normalContrast: false,
                highBlackContrast: false,
                highYellowContrast: false,
            };
        },
        created() {
            this.$root.$i18n.locale = this.$cookie.get("lang") ? this.$cookie.get("lang") : "pl";
            if (window.ReactNativeWebView) {
                const message = {
                    type: "lang",
                    lang: this.$root.$i18n.locale,
                };
                window.ReactNativeWebView.postMessage(JSON.stringify(message));
            }
            this.$messagesBus.$on("setContrast", this.setContrast);
            this.$messagesBus.$on("setFontSize", this.setFontSize);
            this.$messagesBus.$on("setBottomBarPosition", this.setBottomBarPosition);
            this.$messagesBus.$on("setFontSizeAndAdjustBottomBarPosition",
                                  this.setFontSizeAndAdjustBottomBarPosition);
        },
        mounted() {
            this.setContrastAndFontSize();
        },
        methods: {
            setCookie(key, value) {
                const expires = new Date();
                expires.setTime(expires.getTime() + 31536000000);
                document.cookie = key + "=" + value + ";expires=" + expires.toUTCString() + ";path=/";
            },
            setFontSize(size) {
                this.setCookie("fontSize", size);
                if (size === "normal") {
                    this.normalSize = false;
                    this.mediumSize = false;
                    this.largeSize = false;
                }
                if (size === "medium") {
                    this.normalSize = false;
                    this.mediumSize = true;
                    this.largeSize = false;
                }
                if (size === "large") {
                    this.normalSize = false;
                    this.mediumSize = false;
                    this.largeSize = true;
                }
            },
            setContrast(contrast) {
                this.setCookie("contrast", contrast);
                if (contrast === "normal") {
                    this.normalContrast = false;
                    this.highBlackContrast = false;
                    this.highYellowContrast = false;
                }
                if (contrast === "highBlack") {
                    this.normalContrast = false;
                    this.highBlackContrast = true;
                    this.highYellowContrast = false;
                }
                if (contrast === "highYellow") {
                    this.normalContrast = false;
                    this.highBlackContrast = false;
                    this.highYellowContrast = true;
                }
            },
            setContrastAndFontSize() {
                const contrast = this.getCookie("contrast");
                this.setContrast(contrast);
                const size = this.getCookie("fontSize");
                this.setFontSize(size);
            },
            getCookie(key) {
                const keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
                return keyValue ? keyValue[2] : null;
            },
            setBottomBarPosition() {
                this.mediaHeight = document.querySelector(".bottom-bar-media").clientHeight;
                const bottomBar = document.querySelector(".bottom-bar");

                bottomBar.style.bottom = "-" + this.mediaHeight + "px";
            },
            setFontSizeAndAdjustBottomBarPosition(size) {
                if (document.querySelector(".bottom-bar") !== null) {
                    document.querySelector(".bottom-bar")
                        .style.setProperty("transition", "none");
                }
                this.setFontSize(size);
                if (document.querySelector(".bottom-bar") !== null) {
                    // eslint-disable-next-line
                    this.$nextTick(function () {
                        this.setBottomBarPosition();
                    });
                }
            },
        },
    };
</script>
