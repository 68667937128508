<template>
    <div>
        <DataTable :value="pathList">
            <Column field="plName" :header="$t('label.plName')" sortable></Column>
            <Column field="enName" :header="$t('label.enName')" sortable></Column>
            <Column :header="$t('label.action')" v-if="role.isAdmin()">
                <template #body="slotProps">
                    <router-link :to="{name: 'pathEdit', params: {'id': slotProps.data.id}}">
                        <Button type="button" icon="pi pi-pencil" class="p-button-info p-mr-1"
                                v-tooltip.top="$t('button.edit')"></Button>
                    </router-link>
                    <Button type="button" icon="pi pi-chevron-up" class="p-button-info p-mr-1"
                            @click="increaseSortOrder(slotProps.data.id)"
                            v-tooltip.top="$t('button.changeOrder')"></Button>
                    <Button type="button" icon="pi pi-chevron-down" class="p-button-info p-mr-1"
                            @click="decreaseSortOrder(slotProps.data.id)"
                            v-tooltip.top="$t('button.changeOrder')"></Button>
                    <Button type="button" icon="pi pi-trash" class="p-button-danger"
                            @click="openConfirmationDialog(slotProps.data.id)"
                            v-tooltip.top="$t('button.remove')"></Button>
                </template>
            </Column>
        </DataTable>
        <CustomDialog :show="displayConfirmation" :header="$t('dialog.pathRemoval')"
                      :message="$t('dialog.pathRemovalMessage') + ' ' + selectedPathName + '?'"
                      v-on:action="removePath" v-on:close="closeConfirmationDialog"/>
    </div>
</template>

<script>
    import {
        increasePathwaySortOrderUsingPUT as increaseSortOrder,
        decreasePathwaySortOrderUsingPUT as decreaseSortOrder,
        deletePathwayUsingDELETE as deletePathway,
    } from "@/swagger/vue-api-client";
    import Button from "primevue/components/button/Button";
    import DataTable from "primevue/components/datatable/DataTable";
    import Column from "primevue/components/column/Column";
    import {SystemRole} from "@/utils/SystemRole";
    import Tooltip from "primevue/tooltip";
    import CustomDialog from "../../../components/form/CustomDialog";

    export default {
        name: "PathTable",

        components: {
            CustomDialog, Column, DataTable, Button,
        },

        directives: {
            tooltip: Tooltip,
        },

        data() {
            return {
                role: SystemRole,
                selectedPath: null,
                displayConfirmation: false,
            };
        },

        computed: {
            selectedPathName() {
                let name = "";
                if (this.selectedPath) {
                    this.pathList.forEach((p) => {
                        if (p.id === this.selectedPath) name = this.appLang === "pl" ? p.plName : p.enName;
                    });
                }
                return name;
            },
        },

        methods: {
            removePath() {
                deletePathway({id: this.selectedPath}).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.pathRemoveSuccess"));
                    this.closeConfirmationDialog();
                    this.$emit("refreshPathList");
                });
            },

            increaseSortOrder(pathwayId) {
                increaseSortOrder({id: pathwayId}).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.pathwayOrderChangeSuccess"));
                    this.$emit("refreshPathList");
                });
            },

            decreaseSortOrder(pathwayId) {
                decreaseSortOrder({id: pathwayId}).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.pathwayOrderChangeSuccess"));
                    this.$emit("refreshPathList");
                });
            },

            openConfirmationDialog(pathId) {
                this.selectedPath = pathId;
                this.displayConfirmation = true;
            },

            closeConfirmationDialog() {
                this.selectedPath = null;
                this.displayConfirmation = false;
            },
        },

        props: {
            pathList: Array,
        },
    };
</script>

<style scoped>

</style>
