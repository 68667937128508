/* eslint-disable */
export const GeometryUtils = {

    somethingLikeInfinity() {
        return 99999;
    },

    checkLineIntersection(line1Start, line1End, line2Start, line2End) {
        let a; let b;
        const result = {
            x: null,
            y: null,
            onLine1: false,
            onLine2: false,
        };

        const denominator = ((line2End.y - line2Start.y) * (line1End.x - line1Start.x))
            - ((line2End.x - line2Start.x) * (line1End.y - line1Start.y));
        if (denominator === 0) {
            return result;
        }
        a = line1Start.y - line2Start.y;
        b = line1Start.x - line2Start.x;
        const numerator1 = ((line2End.x - line2Start.x) * a) - ((line2End.y - line2Start.y) * b);
        const numerator2 = ((line1End.x - line1Start.x) * a) - ((line1End.y - line1Start.y) * b);
        a = numerator1 / denominator;
        b = numerator2 / denominator;

        result.x = line1Start.x + (a * (line1End.x - line1Start.x));
        result.y = line1Start.y + (a * (line1End.y - line1Start.y));

        if (a > 0 && a < 1) {
            result.onLine1 = true;
        }

        if (b > 0 && b < 1) {
            result.onLine2 = true;
        }

        return result;
    },

    distance(pointA, pointB) {
        return Math.sqrt(
            (pointB.x - pointA.x)**2
            +
            (pointB.y - pointA.y)**2
        );
    },

    crossRatio(A, B, C, D) {
        return (this.distance(A, C) / this.distance(B, C))
            / (this.distance(A, D) / this.distance(B, D));
    },

    pointBetween(A, B, ratioAXdivBX, locationBetween) {
        if (typeof locationBetween === "undefined") {
            locationBetween = 0;
        }
        let point = {};
        if (locationBetween === 0) {
            point = {
                x: (ratioAXdivBX * B.x + A.x) / (1 + ratioAXdivBX),
                y: (ratioAXdivBX * B.y + A.y) / (1 + ratioAXdivBX)
            }
        } else if (locationBetween > 0) {
            point = {
                x: (ratioAXdivBX * B.x - A.x) / (ratioAXdivBX - 1),
                y: (ratioAXdivBX * B.y - A.y) / (ratioAXdivBX - 1)
            }
        } else {
            point = {
                x: (A.x * (ratioAXdivBX + 1) - B.x * ratioAXdivBX),
                y: (A.y * (ratioAXdivBX + 1) - B.y * ratioAXdivBX)
            }
        }
        return point;
    },

    ratioFromCrossRatio(A, B, C, cr) {
        return this.distance(A, C) / this.distance(B, C) / cr;
    },

    // Returns:
    // - 0 if order is AXB
    // - 1 if order is ABX
    // - -1 if order is XAB
    pointLocationBetween(X, A, B) {
        if (this.distance(A, X) + this.distance(B, X) > 1.001 * this.distance(A, B)) {
            if (this.distance(A, X) > this.distance(B, X)) {
                return 1;
            }
            return -1
        }
        return 0;
    },

    linearFunctionBetweenTwoPoints(A, B) {
        if (A.x === B.x) {
            return function (_) {
                return null;
            }
        }
        return function (x) {
            return (x - A.x) * (B.y - A.y) / (B.x - A.x) + A.y;
        }
    },

    pitch(horizontalDistance, elevationDifference) {
        return Math.atan(elevationDifference/horizontalDistance)*180/Math.PI;
    },

    correctedDistance(horizontalDistance, elevationDifference) {
        return Math.sqrt(
            Math.pow(horizontalDistance, 2) + Math.pow(elevationDifference, 2)
        );
    },

    directionToPoint(startPoint, endPoint) {
        const angleInRadians = Math.atan2(
            -(endPoint.y - startPoint.y),
            endPoint.x - startPoint.x
        );
        return angleInRadians * 180 / Math.PI;
    },

    pointInInfinityWithDirection(direction) {
        const directionInRadians = direction / 180 * Math.PI;
        const r = this.somethingLikeInfinity();
        return new Point(
            r * Math.cos(directionInRadians),
            -r * Math.sin(directionInRadians)
        );
    },

    angleBetweenTwoPointsOnSphere(p1, p2) {
        // TODO Przerobić to kiedyś z płaszczyzny na sferę
        return this.directionToPoint(p1, p2);
    },

    cartesianProduct(arr) {
        return arr.reduce(function (a, b) {
            return a.map(function (x) {
                return b.map(function (y) {
                    return x.concat([y]);
                })
            }).reduce(function (a, b) {
                return a.concat(b)
            }, [])
        }, [[]])
    },
};

export default class Point {
    constructor(x, y) {
        this.x = typeof x === "string" ? parseFloat(x) : x;
        this.y = typeof y === "string" ? parseFloat(y) : y;
    }

    plus(point2) {
        return new Point (
            this.x + point2.x,
            this.y + point2.y,
        );
    }
}
