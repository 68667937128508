<template>
    <div>
        <CustomSelectOne :name="name" :items="items" :empty-value="emptyValue" v-model="internalValue"
                         item-label="label" item-value="value" :label="label" v-if="loading" @input="inputListener"/>
    </div>
</template>

<script>
    import {getUserRolesUsingGET as getUserRoles} from "../../swagger/vue-api-client";
    import CustomSelectOne from "./inner/CustomSelectOne";

    export default {
        name: "CustomSelectOneSystemRole",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            value: null,
        },

        watch: {
            value: {
                immediate: true,
                handler(val) {
                    this.internalValue = val;
                },
            },
        },

        data() {
            return {
                internalValue: this.value,
                items: [],
                loading: false,
            };
        },

        beforeMount() {
            getUserRoles().then((response) => {
                this.items = response.data;
                this.loading = true;
            });
        },

        methods: {
            inputListener(value) {
                this.$emit("input", value);
            },
        },

        components: {CustomSelectOne},
    };
</script>

<style scoped>

</style>
