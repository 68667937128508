<template>
    <div>
        <h2 class="page-header"><i class="pi pi-microsoft p-mr-3"></i>{{ $t('menu.areas.list') }}</h2>

        <Toolbar class="actions">
            <template slot="left">
                <Button v-if="role.isAdmin()" :label="$t('button.addArea')" icon="pi pi-plus" @click="createArea"/>
            </template>
        </Toolbar>

        <AreaTable/>
    </div>
</template>

<script>
    import Button from "primevue/components/button/Button";
    import Toolbar from "primevue/components/toolbar/Toolbar";
    import AreaTable from "./components/AreaTable";
    import {SystemRole} from "../../utils/SystemRole";

    export default {
        name: "AreaListView",
        components: {AreaTable, Button, Toolbar},

        data() {
            return {
                role: SystemRole,
            };
        },

        methods: {
            createArea() {
                this.$router.push({name: "areaCreate"});
            },
        },
    };
</script>

<style scoped>

</style>
