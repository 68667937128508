<template>
    <div>
        <h2 class="page-header">
            {{$t('menu.quizzes.questions.list')}}
        </h2>
        <Toolbar class="actions">
            <template slot="left">
                <Button v-if="role.isAdmin()" :label="$t('button.addQuizQuestion')"
                        icon="pi pi-plus" @click="createQuizQuestion"/>
            </template>
        </Toolbar>

        <QuizQuestionTable :quiz-id="quizId" />
    </div>
</template>

<script>
    import Button from "primevue/components/button/Button";
    import Toolbar from "primevue/components/toolbar/Toolbar";
    import {SystemRole} from "@/utils/SystemRole";
    import QuizQuestionTable from "@/views/quiz/components/QuizQuestionTable";
    import {
        getQuizNamesUsingGET as getQuizNames,
    } from "@/swagger/vue-api-client";

    export default {
        name: "QuizQuestionList",
        components: {QuizQuestionTable, Button, Toolbar},

        props: {
            quizId: {
                type: Number,
            },
        },

        data() {
            return {
                quizNames: null,
                role: SystemRole,
            };
        },

        computed: {
            quizHeader() {
                if (this.quizNames) {
                    return "(Quiz: " + (this.appLang === "pl" ? this.quizNames.plName : this.quizNames.enName) + ")";
                }
                return "";
            },
        },

        created() {
            getQuizNames({quizId: this.quizId}).then((response) => {
                this.quizNames = response.data;
            });
        },

        methods: {
            createQuizQuestion() {
                this.$router.push({name: "quizQuestionCreate", params: {quizId: this.quizId.toString()}});
            },
        },
    };
</script>

<style scoped>

</style>
