<template>
    <div id="panorama"></div>
</template>

<script>
    import {
        getDetailedPOIDataUsingGET as getDetailedPOIData,
    } from "@/swagger/vue-api-client";
    import PanoramaHotspot from "@/utils/PanoramaHotspot";

    export default {
        name: "POIEditNeighbourView",

        data() {
            return {
                poi: null,
                panorama: null,
                neighbour: null,
                viewer: null,
                state: null,
                currentCoordinates: null,
                drag: false,
            };
        },

        created() {
            getDetailedPOIData({id: this.$route.params.id}).then((response) => {
                this.poi = response.data;
                this.panorama = this.poi.panoramas[0];
                this.neighbour = this.poi.neighbours.find(poi => poi.id === this.neighbourId);
                this.neighbour.pitch = this.pitch === null ? null : parseFloat(this.pitch);
                this.neighbour.yaw = this.yaw === null ? null : parseFloat(this.yaw);
                this.hotspot = new PanoramaHotspot(this.poi, this.neighbour, this.neighbour, this);
                this.prepareViewer();
                if (this.hotspot.isManuallyCorrected()) {
                    this.loadManuallySetData();
                }
            });
            // eslint-disable-next-line no-return-assign
            document.addEventListener("mousedown", () => this.drag = false);
            // eslint-disable-next-line no-return-assign
            document.addEventListener("mousemove", () => this.drag = true);
            document.addEventListener("mouseup", this.mouseUp);
        },

        methods: {
            prepareViewer() {
                this.currentCoordinates = {
                    yaw: this.yaw,
                    pitch: this.pitch,
                };
                this.viewer = this.$pannellum.viewer("panorama", {
                    strings: {
                        loadingLabel: "Ładowanie...",
                    },
                    autoLoad: true,
                    ignoreGPanoXMP: true,
                    pitch: this.hotspot.pitch,
                    yaw: this.hotspot.yaw,
                    northOffset: this.panorama.northOffset,
                    hfov: this.panorama.hfov ? this.panorama.hfov : 120,
                    type: "equirectangular",
                    panorama: this.staticFileUrl(this.panorama.filePath),
                    hotSpots: [
                        {
                            pitch: this.hotspot.computedPitch,
                            yaw: this.hotspot.computedYaw,
                            cssClass: "hotspot-edited computed-hotspot",
                            clickHandlerFunc: this.hotspotClick,
                            clickHandlerArgs: {
                                poiId: this.poi.id,
                            },
                            text: "Resetuj do automatycznie wyliczonego położenia",
                            id: "original-hotspot",
                        },
                    ],
                });
            },

            loadManuallySetData() {
                this.currentCoordinates = {
                    pitch: this.hotspot.pitch,
                    yaw: this.hotspot.yaw,
                };
                this.addHotspotByHand();
            },

            hotspotClick() {
                this.currentCoordinates = null;
                this.removePreviousNewHotspot();
            },

            click(event) {
                if (!this.viewer.getContainer().contains(event.target)) {
                    return;
                }
                this.setCoordinates(event);
                this.removePreviousNewHotspot();
                this.addHotspotByHand();
                // this.viewer.removeHotSpot("original-hotspot");
            },

            setCoordinates(event) {
                const coordinates = this.viewer.mouseEventToCoords(event);
                this.currentCoordinates = {
                    pitch: coordinates[0],
                    yaw: coordinates[1],
                };
            },

            removePreviousNewHotspot() {
                this.viewer.removeHotSpot("new-hotspot");
            },

            addHotspotByHand() {
                this.viewer.addHotSpot({
                    pitch: this.currentCoordinates.pitch,
                    yaw: this.currentCoordinates.yaw,
                    cssClass: "hotspot-edited manually-corrected-hotspot",
                    clickHandlerFunc: this.hotspotClick,
                    clickHandlerArgs: {
                        poiId: this.poi.id,
                    },
                    id: "new-hotspot",
                });
            },

            mouseUp(event) {
                if (!this.drag) {
                    this.click(event);
                }
            },

        },

        props: {
            neighbourId: null,
            pitch: null,
            yaw: null,
        },
    };
</script>

<style scoped>
    @import "../assets/less/maps-and-panoramas.less";

    #panorama {
        width: 100%;
        height: 70vh;
    }

</style>
