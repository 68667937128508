<template>
    <div>
        <div style="display: flex; margin-left: 14px; margin-bottom: 4px;">
            <label style="font-size: 12px; color: dimgrey;">{{ label }}</label>
        </div>
        <editor-menu-bubble class="menububble" :editor="editor" @hide="hideLinkMenu"
                            style="border: 1px solid #997f5c; border-radius: 3px"
                            v-slot="{ commands, isActive, getMarkAttrs, menu }">

            <div
                class="menububble"
                :class="{ 'is-active': menu.isActive }"
                :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
            >
                <Button
                    class="menubar__button p-button-rounded p-button-text"
                    style="color: #534b41;"
                    :class="{ 'is-active': isActive.bold() }"
                    icon="pi pi-heart"
                    @click="commands.bold">
                    <b>B</b>
                </Button>

                <Button
                    class="menubar__button p-button-rounded p-button-text"
                    style="color: #534b41;"
                    :class="{ 'is-active': isActive.italic() }"
                    icon="pi pi-heart"
                    @click="commands.italic">
                    <i>I</i>
                </Button>

                <Button
                    class="menubar__button p-button-rounded p-button-text"
                    style="color: #534b41;"
                    :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                    icon="pi pi-heart"
                    @click="commands.heading({ level: 1 })">
                    <strong>H1</strong>
                </Button>

                <Button
                    class="menubar__button p-button-rounded p-button-text"
                    style="color: #534b41;"
                    :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                    icon="pi pi-heart"
                    @click="commands.heading({ level: 2 })">
                    <strong>H2</strong>
                </Button>

                <Button
                    class="menubar__button p-button-rounded p-button-text"
                    style="color: #534b41;"
                    :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                    icon="pi pi-heart"
                    @click="commands.heading({ level: 3 })">
                    <strong>H3</strong>
                </Button>

                <form class="menububble__form" v-if="linkMenuIsActive"
                      @submit.prevent="setLinkUrl(commands.link, linkUrl)">
                    <input class="menububble__input" type="text" v-model="linkUrl"
                           placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
                    <button class="menububble__button" @click="setLinkUrl(commands.link, null)" type="button">
                        X
                    </button>
                </form>

                <template v-else>
                    <Button
                        class="menubar__button p-button-rounded p-button-text"
                        style="color: #534b41;"
                        icon="pi pi-paperclip"
                        @click="showLinkMenu(getMarkAttrs('link'))"
                        :class="{ 'is-active': isActive.link() }"
                    >
                    </Button>
                </template>

            </div>
        </editor-menu-bubble>

        <editor-content class="editor__content" :editor="editor"/>
    </div>
</template>

<script>
    import Button from "primevue/components/button/Button";
    import {Editor, EditorContent} from "tiptap";
    import {
        Heading,
        Bold,
        Italic,
        Link,
    } from "tiptap-extensions";
    import EditorMenuBubble from "tiptap/src/Components/EditorMenuBubble";

    export default {
        name: "CustomEditor",

        components: {
            Button, EditorMenuBubble, EditorContent,
        },

        watch: {
            value: {
                handler(val) {
                    this.internalValue = val;
                    this.editor.setContent(this.internalValue);
                },
            },
        },

        data() {
            return {
                editor: null,
                linkUrl: null,
                linkMenuIsActive: false,
                internalValue: null,
            };
        },

        methods: {
            showLinkMenu(attrs) {
                this.linkUrl = attrs.href;
                this.linkMenuIsActive = true;
                this.$nextTick(() => {
                    this.$refs.linkInput.focus();
                });
            },

            hideLinkMenu() {
                this.linkUrl = null;
                this.linkMenuIsActive = false;
            },

            setLinkUrl(command, url) {
                command({href: url});
                this.hideLinkMenu();
            },

            getHTMLContent() {
                return this.internalValue;
            },
        },

        mounted() {
            this.editor = new Editor({
                extensions: [
                    new Heading({levels: [1, 2, 3]}),
                    new Link(),
                    new Bold(),
                    new Italic(),
                ],
                content: this.value,
                onUpdate: ({getHTML}) => {
                    this.internalValue = getHTML();
                },
            });
        },

        props: {
            value: String,
            label: String,
        },
    };
</script>

<style scoped>
    >>> .ProseMirror {
        margin: 10px;
    }

    .editor__content {
        border: 1px solid #997f5c;
        border-radius: 3px;
        text-align: left;
    }
</style>
