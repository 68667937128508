<template>
    <div>
        <div class="neighbour-map-button-outer">
            <Button type="button" icon="pi pi-pencil" class="p-button p-mr-1">
                <span class="pi pi-arrow-right p-button-icon p-button-icon-left"></span>
                <router-link :to="{name: 'walk', params: {id: this.currentPoiId}}"
                             style="color:white; text-decoration: none">
                    Idź do panoramy
                </router-link>
            </Button>
        </div>
        <div class="neighbour-map-container">
            <div style="width: 50vw; height: 50vh" class="neighbour-map-inner">
                <l-map
                    ref="map"
                    :zoom="zoom"
                    :center="center"
                    :max-zoom="maxZoom"
                    @update:zoom="zoomUpdated"
                    @update:center="centerUpdated"
                    @update:bounds="boundsUpdated"
                >
                    <l-tile-layer :url="url"></l-tile-layer>
                    <l-circle-marker v-for="poi in pois" :key="poi.id"
                                     :lat-lng="[poi.lat, poi.lng]" :radius="10" :color="getPoiColor(poi.id)"
                                     @click="handleMarkerClick(poi)">
                        <l-tooltip>
                            {{ poi.langData[appLang].name }}
                        </l-tooltip>
                    </l-circle-marker>
                </l-map>
            </div>
            <div>
                <table>
                    <tr v-for="(name, type) in this.markerNames" :key="type">
                        <td :style="'background: ' + markerColors[type] + '; width: 20px'">&nbsp;</td>
                        <td>{{ name }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getDetailedPOIListDataUsingGET as getDetailedPOIListData,
    } from "@/swagger/vue-api-client";
    import {CRS, Icon} from "leaflet";
    import {
        LMap, LTileLayer, LTooltip, LCircleMarker,
    } from "vue2-leaflet";
    import {HTMLUtils} from "@/utils/HTMLUtils";

    export default {
        name: "NeighbourMap",

        mixins: [HTMLUtils],

        components: {
            LMap,
            LTileLayer,
            LTooltip,
            LCircleMarker,
        },

        data() {
            return {
                url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                // eslint-disable-next-line max-len
                // url: "https://api.tiles.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw",
                zoom: 17,
                maxZoom: 22,
                center: [0, 0],
                bounds: null,
                id: "mapbox.satellite",
                crs: CRS.Earth,
                pois: [],
                currentPoi: {},
                currentPoiId: parseInt(this.$route.params.id, 10),
                markerNames: {
                    current: "Bieżący przystanek",
                    neighbour: "Sąsiedzi widoczni w panoramie",
                    otherArea: "Przystanki z innych obszarów",
                    other: "Pozostałe przystanki z bieżącego obszaru",
                },
                markerColors: {
                    current: "red",
                    neighbour: "orange",
                    otherArea: "yellow",
                    other: "lightblue",
                },
            };
        },

        methods: {
            zoomUpdated(zoom) {
                this.zoom = zoom;
            },
            centerUpdated(center) {
                this.center = center;
            },
            boundsUpdated(bounds) {
                this.bounds = bounds;
            },
            findCurrentPoi(poiId) {
                for (let i = 0; i < this.pois.length; i += 1) {
                    const poi = this.pois[i];
                    if (poi.id === poiId) {
                        this.currentPoi = poi;
                        return;
                    }
                }
            },
            setData() {
                this.center = [this.currentPoi.lat, this.currentPoi.lng];
            },
            getPoiColor(poiId) {
                let key = "other";
                if (poiId === this.currentPoi.id) {
                    key = "current";
                } else if (this.currentPoi.neighbours.find(neighbour => neighbour.id === poiId)) {
                    key = "neighbour";
                } else if (this.currentPoi.areaId !== this.pois.find(poi => poi.id === poiId).areaId) {
                    key = "otherArea";
                }
                return this.markerColors[key];
            },
            handleMarkerClick(poi) {
                const color = this.getPoiColor(poi.id);
                this.$emit("reassign-poi", poi, color);
                if (color === "red") {
                    return;
                }
                if (color === "orange") {
                    this.currentPoi.neighbours = this.currentPoi.neighbours.filter(n => n.id !== poi.id);
                } else {
                    this.currentPoi.neighbours.push({id: poi.id});
                }
            },
            checkNewNeighbour(poiId) {
                this.currentPoi.neighbours.push({id: poiId});
            },
            uncheckNeighbour(poiId) {
                this.currentPoi.neighbours = this.currentPoi.neighbours.filter(n => n.id !== poiId);
            },
            refresh() {
                getDetailedPOIListData().then((response) => {
                    this.pois = response.data;
                    this.findCurrentPoi(this.currentPoiId);
                    this.setData();
                });
            },
        },

        created() {
            // eslint-disable-next-line no-underscore-dangle
            delete Icon.Default.prototype._getIconUrl;
            Icon.Default.mergeOptions({
                // eslint-disable-next-line global-require
                iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
                // eslint-disable-next-line global-require
                iconUrl: require("leaflet/dist/images/marker-icon.png"),
                // eslint-disable-next-line global-require
                shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
            });
            this.refresh();
        },

        mounted() {
            this.map = this.$refs.map.mapObject;
            this.map.on("click", (e) => {
                this.copyToClipboard(e.latlng.lat + "," + e.latlng.lng);
            });
        },
    };
</script>

<style scoped>
    .current-poi {
        stroke: orange;
    }
    td {
        text-align: left;
    }
    .neighbour-map-button-outer {
        text-align: left;
        margin-bottom: 14px;
    }
    .neighbour-map-container {
        display: flex;
        flex-wrap: wrap;
    }
    .neighbour-map-container .neighbour-map-inner {
        margin-right: 14px;
    }
</style>
