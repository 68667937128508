import en from "vee-validate/dist/locale/en.json";

/* eslint-disable */
export const messagesEN = {
    fields: {
        //tu dodajemy nazwy pól, które się wyświetlają przy błędach
        email: "E-mail",
        password: "Password",
        firstName: "First name",
        lastName: "Last name",
        plName: "Polish name",
        enName: "English name",
        poiNumber: "Number",
        poiLatitude: "Latitude",
        poiLongitude: "Longitude",
        plDescription: "Polish description",
        enDescription: "English description",
        plContent: "Polish content",
        enContent: "English content",
        panoramaLat: "Latitude",
        panoramaLng: "Longitude",
        panoramaElevation: "Elevation",
        panoramaNorthOffset: "North offset",
        panoramaDefaultPitch: "Default pitch",
        panoramaDefaultYaw: "Default yaw",
        lat: "Latitude",
        lng: "Longitude",
        x: "x",
        y: "y",
        yaw: "Yaw",
        pitch: "Pitch",
        elevation: "Elevation",
    },
    label: {
        map: "Map",
        mainPage: "Main Page",
        description: "Description",
        gallery: "Gallery",
        video: "Video",
        audio: "Audio",
        share: "Share",
        scrollToLeft: "Scroll gallery to the left",
        scrollToRight: "Scroll gallery to the right",
        shareTwitter: "Share via Twitter",
        shareFacebook: "Share via Facebook",
        action: "Action",
        firstName: "First name",
        lastName: "Last name",
        email: "E-mail",
        systemRole: "Role",
        password: "Password",
        name: "Name",
        plName: "Polish name",
        enName: "English name",
        plDescription: "Polish description",
        enDescription: "English description",
        plContent: "Polish content",
        enContent: "English content",
        sharedAttachmentUrl: "Shared attachment URL",
        plAttachmentUrl: "Polish attachment URL",
        enAttachmentUrl: "English attachment URL",
        yaw: "Yaw",
        pitch: "Pitch",
        arrowAngle: "Arrow angle",
        elevation: "Elevation",
        northOffset: "North offset",
        defaultPitch: "Default pitch",
        defaultYaw: "Default yaw",
        hfov: "Horizontal field of view",
        number: "Number",
        lat: "Latitude",
        lng: "Longitude",
        identifier: "Identifier",
        correctAnswer: "Correct answer",
        poiArea: "POI area",
        area: "Area",
        chooseArea: "Choose area",
        chooseSubArea: "Choose subarea",
        optionalPOI: "Optional POI",
        slug: "Slug",
        answerOk: "Correct answer",
        panorams: "Panorams",
        path: "Thematic path",
        POI: "POI",
        previous: "previous",
        next: "next",
        defaultViewerConfig: "Default viewer config",
        poiPluralAbbr: {
            I: "POI",
            II: "POIs",
        },
    },
    button: {
        yes: "Yes",
        no: "No",
        cancel: "Cancel",
        save: "Save",
        edit: "Edit",
        remove: "Remove",
        changeOrder: "Change order",
        addSubArea: "Add sub area",
        addUser: "Add user",
        saveUserData: "Save user data",
        addArea: "Add area",
        saveAreaData: "Save area data",
        chooseArea: "Choose area",
        addMap: "Add map",
        changeMap: "Change map",
        saveMapData: "Save map data",
        expandAreas: "Expand areas",
        collapseAreas: "Collapse areas",
        addPath: "Add path",
        savePathData: "Save path data",
        addPOI: "Add point",
        savePOIData: "Save point data",
        choosePOI: "Choose point",
        addQuiz: "Add quiz",
        saveQuizData: "Save quiz data",
        addQuizQuestion: "Add question",
        saveQuizQuestionData: "Save question data",
        addQuizAnswer: "Add answer",
        saveQuizAnswerData: "Save answer data",
        showQuestions: "Show questions",
        showAnswers: "Show answers",
        addPanorama: "Add panorama",
        changePanorama: "Change panorama",
        addThumbnail: "Add thumbnail",
        changeThumbnail: "Change thumbnail",
        addImage: "Add image",
        changeImage: "Change image",
        savePanoramaData: "Save panorama data",
        addAttachment: "Add attachment",
        saveAttachmentData: "Save attachment data",
        addSharedAttachment: "Add shared attachment",
        editSharedAttachment: "Edit shared attachment",
        addPolishAttachment: "Add polish attachment",
        editPolishAttachment: "Edit polish attachment",
        addEnglishAttachment: "Add english attachment",
        editEnglishAttachment: "Edit english attachment",
        expandQuestions: "Expand questions",
        collapseQuestions: "Collapse questions",
        rewriteViewerData: "Rewrite viewer data",
        scrollLeft: "scroll left",
        scrollRight: "scroll right",
    },
    login: {
        title: "Sign in",
        login: "Login",
        password: "Password",
        action: {
            login: "Sign in",
            remindPassword: "Remind password",
        },
    },
    menu: {
        users: {
            title: "Users",
            list: "User list",
            add: "Add user",
            edit: "Edit user",
        },
        areas: {
            title: "Areas",
            list: "Area list",
            add: "Add area",
            edit: "Edit area",
        },
        pois: {
            title: "Points of interest",
            adjacent: "Adjacent points",
            list: "POI list",
            add: "Add POI",
            edit: "Edit POI",
        },
        paths: {
            title: "Paths",
            list: "Path list",
            add: "Add path",
            edit: "Edit path",
        },
        quizzes: {
            title: "Quizzes",
            list: "Quiz list",
            add: "Add quiz",
            edit: "Edit quiz",
            questions: {
                list: "Question list",
                add: "Add question",
                edit: "Edit question",
            },
            answers: {
                list: "Answer list",
                add: "Add answer",
                edit: "Edit answer",
            },
        },
        maps: {
            title: "Maps",
            add: "Add map",
            edit: "Edit map",
        },
        images: {
            title: "Images",
            add: "Add image",
            edit: "Edit image",
        },
        panoramas: {
            title: "Panorama",
            add: "Add panorama",
            edit: "Edit panorama",
        },
        attachments: {
            add: "Add attachment",
            edit: "Edit attachment",
            audios: "Audio recordings",
            documents: "Photos, copies and documents",
            videos: "Video files",
            audioSuffix: "audio",
            documentSuffix: "photos, copies and documents",
            videoSuffix: "video",
            sharedAttachmentInfo: "Concerns attachments that are shared by both polish and english versions e.g. photos or documents",
        },
        logout: "Log out",
    },
    dialog: {
        editCoordinates: "Edit coordinates",
        userRemoval: "Remove user",
        userRemovalMessage: "Are you sure you want to remove user",
        areaRemoval: "Remove area",
        areaRemovalMessage: "Are you sure you want to remove area",
        areaRemovalWarning: "POI assignments will be removed in case they are present. Area maps and quizzes will be also removed.",
        poiEdition: "Edit POI",
        poiRemoval: "Remove POI",
        poiRemovalMessage: "Are you sure you want to remove POI",
        poiRemovalWarning: "Panoramas and attachments related to the POI will be also removed.",
        pathRemoval: "Remove path",
        pathRemovalMessage: "Are you sure you want to remove path",
        quizRemoval: "Remove quiz",
        quizRemovalMessage: "Are you sure you want to remove quiz",
        quizRemovalWarning: "Questions and answers will be also removed.",
        quizQuestionRemoval: "Remove question",
        quizQuestionRemovalMessage: "Are you sure you want to remove question",
        quizQuestionRemovalWarning: "Questions will also be removed.",
        quizAnswerRemoval: "Remove answer",
        quizAnswerRemovalMessage: "Are you sure you want to remove answer",
        mapRemoval: "Remove map",
        mapRemovalMessage: "Are you sure you want to remove map",
        mapRemovalWarning: "The file will be also deleted.",
        panoramaRemoval: "Remove panorama",
        panoramaRemovalMessage: "Are you sure you want to remove panorama",
        panoramaRemovalWarning: "The file will be also deleted.",
        attachmentRemoval: "Remove attachment",
        attachmentRemovalMessage: "Are you sure you want to remove attachment",
        attachmentRemovalWarning: "Attachment file will be also deleted.",
    },
    snackbar: {
        userAddSuccess: "User successfully saved",
        mapAddError: "Add file to save the map",
        mapAddSuccess: "Map successfully saved",
        userRemoveSuccess: "User successfully removed",
        userRemoveError: "This user can not be removed",
        mapRemoveSuccess: "Map successfully removed",
        questionAddSuccess: "Question successfully saved",
        answerAddSuccess: "Answer successfully saved",
        poiRemoveSuccess: "POI successfully removed",
        pathAddSuccess: "Path successfully saved",
        areaOrderChangeSuccess: "Order successfully changed",
        poiOrderChangeSuccess: "Order successfully changed",
        pathwayOrderChangeSuccess: "Order successfully changed",
        poiAddSuccess: "POI successfully saved",
        areaRemoveSuccess: "Area successfully removed",
        attachmentRemoveSuccess: "Attachment successfully removed",
        pathRemoveSuccess: "Path successfully removed",
        quizRemoveSuccess: "Quiz successfully removed",
        questionRemoveSuccess: "Question successfully removed",
        questionOrderChangeSuccess: "Order successfully changed",
        areaAddSuccess: "Area successfully saved",
        panoramaAddBackendError: "Error during upload or conversion",
        panoramaAddError: "Add files to save the panorama",
        panoramaAddSuccess: "Panorama successfully saved",
        panoramaRemoveSuccess: "Panorama successfully removed",
        answerRemoveSuccess: "Answer successfully removed",
        answerOrderChangeSuccess: "Order successfully changed",
        attachmentAddSuccess: "Attachment successfully saved",
        attachmentOrderChangeSuccess: "Order successfully changed",
        quizAddError: "Choose area to save the quiz",
        quizAddSuccess: "Quiz successfully saved",
        poiAddError: "Check number uniqueness",
        attachmentSaveError: "Attachment name must be defined for the corresponding language versions",
    },
    search: {
        search: "Search",
        clear: "Clear",
    },
    validation: en.messages,
};
