// eslint-disable-next-line import/prefer-default-export
export const HTMLUtils = {
    methods: {
        showTooltip(event) {
            const tooltip = document.getElementById("tooltip");
            if (typeof event.target.dataset.tooltip !== "undefined") {
                tooltip.innerHTML = event.target.dataset.tooltip;
            } else if (event.target.querySelector(".tooltip-content") !== null) {
                tooltip.innerHTML = event.target.querySelector(".tooltip-content").innerHTML;
            } else {
                return;
            }
            tooltip.style.display = "block";
            tooltip.style.left = event.pageX + 10 + "px";
            tooltip.style.top = event.pageY - 10 + "px";
        },

        hideTooltip() {
            const tooltip = document.getElementById("tooltip");
            tooltip.innerHTML = "";
            tooltip.style.display = "none";
        },

        resizeAndCenterImages() {
            const containers = document.querySelectorAll(".resizable-container");
            for (let i = 0; i < containers.length; i += 1) {
                this.resizeAndCenterImagesInContainer(containers[i]);
            }
        },

        resizeAndCenterImagesInContainer(container) {
            const images = container.querySelectorAll(".resizable");
            for (let i = 0; i < images.length; i += 1) {
                this.resizeAndCenterImage(images[i], container);
            }
        },

        resizeAndCenterImage(imageElement, container) {
            const context = this;
            const imageObject = new Image();
            imageObject.src = imageElement.getAttribute("src");
            // eslint-disable-next-line func-names
            imageObject.onload = function () {
                const ratio = context.checkRatio(container, imageObject);
                context.resizeImage(imageElement, container, ratio);
                context.centerImage(imageElement, container, ratio);
            };
        },

        resizeImage(imageElement, container, ratio) {
            const svgs = container.querySelectorAll("svg");
            if (typeof imageElement.ratio === "undefined") {
                imageElement.ratio = imageElement.height / imageElement.width;
            }
            if (ratio > 1) {
                imageElement.height = container.clientHeight;
                imageElement.width = imageElement.height / imageElement.ratio;
            } else {
                imageElement.width = container.clientWidth;
                imageElement.height = imageElement.width * imageElement.ratio;
            }
            for (let i = 0; i < svgs.length; i += 1) {
                this.resizeSvg(svgs[i], imageElement.width, imageElement.height);
            }
        },

        resizeSvg(svgElement, width, height) {
            if (width) {
                svgElement.setAttribute("width", width);
            }
            if (height) {
                svgElement.setAttribute("height", height);
            }
        },

        centerImage(imageElement, container, ratio) {
            if (ratio > 1) {
                const containerWidth = container.clientWidth;
                const imageWidth = imageElement.width;
                // howMuchRight should be between 0 and 1
                let howMuchRight = container.getAttribute("data-how-much-right");
                if (howMuchRight === null) {
                    howMuchRight = 0.5;
                }
                container.scrollLeft = (imageWidth - containerWidth) * howMuchRight;
            } else {
                const containerHeight = container.clientHeight;
                const imageHeight = imageElement.height;
                // howMuchBottom should be between 0 and 1
                let howMuchBottom = container.getAttribute("data-how-much-bottom");
                if (howMuchBottom === null) {
                    howMuchBottom = 0.5;
                }
                container.scrollTop = (imageHeight - containerHeight) * howMuchBottom;
            }
        },

        checkRatio(container, image) {
            const containerRatio = container.clientWidth / container.clientHeight;
            const imageRatio = image.width / image.height;
            return imageRatio / containerRatio;
        },

        copyToClipboard(text) {
            const input = document.createElement("textarea");
            input.setAttribute("id", "clipboard-helper");
            document.body.append(input);
            input.value = text;
            input.select();
            input.setSelectionRange(0, 99999);
            document.execCommand("copy");
            input.remove();
        },
    },
};
