<template>
    <div>
        <h2 class="page-header"><i class="pi pi-users p-mr-3"></i>{{headerTitle}}</h2>
        <ValidationObserver ref="formObserver">
            <div class="p-col-4">
                <CustomInputText name="firstName" :label="$t('label.firstName')"
                                 v-model="user.firstName" rules="required"/>
                <CustomInputText name="lastName" :label="$t('label.lastName')"
                                 v-model="user.lastName" rules="required"/>
                <CustomInputText name="email" :label="$t('label.email')"
                                 v-model="user.email" rules="required|email"/>
                <CustomPassword name="password" :label="$t('label.password')"
                                v-model="user.password"/>
            </div>

            <Toolbar class="actions p-ml-2 p-mt-3">
                <template slot="left">
                    <Button :label="$t('button.saveUserData')" icon="pi pi-save" @click="saveUser"/>
                </template>
            </Toolbar>
        </ValidationObserver>
    </div>
</template>

<script>
    import {
        createOrUpdateUserUsingPOST as createOrUpdateUser,
        getUserUsingGET as getUser,
    } from "@/swagger/vue-api-client";
    import Button from "primevue/components/button/Button";
    import Toolbar from "primevue/components/toolbar/Toolbar";
    import {ValidationObserver} from "vee-validate";
    import {SystemRole} from "@/utils/SystemRole";
    import CustomInputText from "../../components/form/CustomInputText";
    import CustomPassword from "../../components/form/CustomPassword";

    export default {
        name: "UserFormView",

        components: {
            Button, Toolbar, CustomInputText, CustomPassword, ValidationObserver,
        },

        props: {
            value: {},
        },

        data() {
            return {
                user: {
                    id: null,
                    firstName: null,
                    lastName: null,
                    email: null,
                    password: null,
                    systemRole: SystemRole.ADMIN,
                },
                headerTitle: this.$t("menu.users.add"),
            };
        },

        created() {
            if (this.$route.params.id) {
                this.headerTitle = this.$t("menu.users.edit");
                getUser({id: this.$route.params.id}).then((response) => {
                    this.user = response.data;
                });
            }
        },

        methods: {
            saveUser() {
                this.$refs.formObserver.validate().then((valid) => {
                    if (!valid) {
                        return;
                    }
                    createOrUpdateUser({userDto: this.user}).then(() => {
                        this.$router.go(-1);
                        this.$eventBus.$emit("success", this.$t("snackbar.userAddSuccess"));
                    });
                });
            },
        },
    };
</script>

<style scoped>

</style>
