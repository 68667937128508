<template>
    <div>
        <h2 class="page-header"><i class="pi pi-image p-mr-3"></i>{{$t('menu.panoramas.title')}}</h2>

        <Toolbar class="actions">
            <template slot="left">
                <Button v-if="role.isAdmin()" :label="$t('button.addPanorama')" icon="pi pi-plus"
                        @click="createPanorama()"/>
            </template>
        </Toolbar>

        <DataTable :value="panoramas" dataKey="index" v-if="panoramas.length > 0">
            <Column field="name" :header="$t('label.identifier')"></Column>
            <Column :header="$t('label.action')" v-if="role.isAdmin()">
                <template #body="slotProps">
                    <router-link :to="{name: 'panoramaEdit', params: {'id': slotProps.data.id}}">
                        <Button type="button" icon="pi pi-pencil" class="p-button-info p-mr-1"
                                v-tooltip.top="$t('button.edit')"></Button>
                    </router-link>
                    <Button type="button" icon="pi pi-trash" class="p-button-danger"
                            @click="openConfirmationDialog(slotProps.data.id)" v-tooltip.top="$t('button.remove')">
                    </Button>
                </template>
            </Column>
        </DataTable>

        <CustomDialog :show="displayConfirmation" :header="$t('dialog.panoramaRemoval')"
                      :message="$t('dialog.panoramaRemovalMessage') + ' ' + selectedPanorama +
                          '? ' + $t('dialog.panoramaRemovalWarning')"
                      v-on:action="removePanorama" v-on:close="closeConfirmationDialog"/>
    </div>
</template>

<script>
    import {
        deletePanoramaUsingDELETE as deletePanorama,
        getPanoramaIdListUsingGET as getPanoramaIdList,
    } from "@/swagger/vue-api-client";
    import Button from "primevue/components/button/Button";
    import Toolbar from "primevue/components/toolbar/Toolbar";
    import DataTable from "primevue/components/datatable/DataTable";
    import Column from "primevue/components/column/Column";
    import Tooltip from "primevue/tooltip";
    import {SystemRole} from "../../../utils/SystemRole";
    import {RouterUtils} from "../../../utils/RouterUtils";
    import CustomDialog from "../../../components/form/CustomDialog";

    export default {
        name: "PanoramaListView",

        components: {
            CustomDialog, Button, Toolbar, DataTable, Column,
        },

        directives: {
            tooltip: Tooltip,
        },

        mixins: [RouterUtils],

        data() {
            return {
                role: SystemRole,
                panoramas: [],
                selectedPanorama: null,
                displayConfirmation: false,
            };
        },

        methods: {
            createPanorama() {
                this.pushAsync({name: "panoramaCreate", params: {poiId: this.$route.params.id}});
            },

            removePanorama() {
                deletePanorama({id: this.selectedPanorama}).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.panoramaRemoveSuccess"));
                    this.panoramas = this.panoramas.filter(p => p.id !== this.selectedPanorama);
                    this.closeConfirmationDialog();
                });
            },

            openConfirmationDialog(panoramaId) {
                this.selectedPanorama = panoramaId;
                this.displayConfirmation = true;
            },

            closeConfirmationDialog() {
                this.selectedPanorama = null;
                this.displayConfirmation = false;
            },
        },

        created() {
            getPanoramaIdList({poiId: this.$route.params.id}).then((response) => {
                this.panoramas = response.data.map(panoramaId => ({id: panoramaId, name: "Panorama " + panoramaId}));
            });
        },
    };
</script>

<style scoped>

</style>
