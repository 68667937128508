<template>
    <div class="quiz" v-if="renderView">
        <h1>{{ this.quiz.langData[this.appLang].name }}</h1>
        <Question v-for="question in questions" v-bind:key="question.id"
                  :question="question" @collect-answer="updateCounter"/>
        <div v-if="result !== null" class="quiz-result-summary">
            <ProgressBar :value="result" class="p-mt-3">Percent Complete: {{result}} %</ProgressBar>
            <h3 style="display: flex; justify-content: center; align-items: center;">
                Liczba poprawnych odpowiedzi: {{counter.correct}} / {{counter.all}}
            </h3>
            <h3 style="display: flex; justify-content: center; align-items: center;">{{result}} %</h3>
        </div>
        <div v-if="result == null" class="quiz-result-summary">
            <h3 style="display: flex; justify-content: center; align-items: center;">
                {{noResultsTitle}}
            </h3>
        </div>
    </div>
</template>

<script>
    import {
        getDetailedQuizListDataUsingGET as getDetailedQuizListData,
    } from "@/swagger/vue-api-client";
    import {OfflineData} from "@/offline/OfflineData";
    import Question from "@/components/quizzes/Question";
    import ProgressBar from "primevue/components/progressbar/ProgressBar";

    export default {
        name: "Quiz",
        components: {Question, ProgressBar},

        data() {
            return {
                quiz: {},
                questions: {},
                renderView: false,
                counter: {
                    correct: 0,
                    all: 0,
                },
                result: null,
                interval: null,
            };
        },

        watch: {
            counter: {
                handler(val) {
                    if (val.all === this.questions.length) {
                        this.result = 0;
                        this.count = Math.round(this.counter.correct * 100 / this.counter.all);
                        this.interval = setInterval(() => {
                            if (this.result !== this.count) this.result += 1;
                        }, 15);
                    }
                },
                deep: true,
            },
        },

        computed: {
            noResultsTitle() {
                return this.appLang === "pl"
                    ? "Aby poznać wynik, uruchom ponownie quiz i odpowiedz nawszystkie pytania."
                    : "To find out the result, restart the quiz and answer all of them.";
            },
        },

        created() {
            getDetailedQuizListData().then((response) => {
                this.findQuizByArea(this.areaId, response.data);
            }).catch(() => {
                this.findQuizByArea(this.areaId, OfflineData.getDetailedQuizListData());
            }).then(() => {
                this.drawQuestions();
                this.renderView = true;
            });
        },

        methods: {
            findQuizByArea(areaId, quizzes) {
                for (let i = 0; i < quizzes.length; i += 1) {
                    if (quizzes[i].areaId.toString() === areaId.toString()) {
                        this.quiz = quizzes[i];
                        return;
                    }
                }
            },

            updateCounter(answer) {
                if (answer.correct) {
                    this.counter.correct += 1;
                }
                this.counter.all += 1;
            },

            drawQuestions() {
                this.resetCounter();
                this.questions = this.quiz.questions;
                // FIXME Dopisać prawdziwe losowanie
            },

            resetCounter() {
                this.counter = {
                    correct: 0,
                    all: 0,
                };
            },
        },

        props: {
            areaId: Number,
        },
    };
</script>

<style scoped>
    * {
        text-align: left;
    }
</style>
