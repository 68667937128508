<template>
    <div class="custom-select-one">
        <label :for="name" v-if="internalValue">{{ label }}</label>
        <Dropdown :id="name" :options="internalItems" :optionLabel="itemLabel" :optionValue="itemValue"
                  v-model="internalValue" @input="inputListener" :placeholder="internalValue ? '' : label" />
    </div>
</template>

<script>
    import Dropdown from "primevue/dropdown";

    export default {
        name: "CustomSelectOne",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            itemLabel: {
                type: String,
            },
            itemValue: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            items: {
                type: Array,
            },
            value: null,
        },

        watch: {
            value: {
                immediate: true,
                handler(val) {
                    this.internalValue = val;
                },
            },
        },

        data() {
            return {
                internalValue: this.value,
                internalItems: this.items,
            };
        },

        mounted() {
            if (this.emptyValue) {
                this.internalItems = [{value: null, label: ""}, ...this.items];
            }
        },

        methods: {
            inputListener(value) {
                this.$emit("input", value);
            },
        },
        components: {Dropdown},
    };
</script>

<style lang="less" scoped>
    .custom-select-one {
        margin-bottom: 20px;
        position: relative;

        label {
            font-size: 12px;
            color: #898989;
            top: -10px;
            background-color: #ffffff;
            padding: 2px 4px;
            margin-left: -4px;
            margin-top: 0;
            position: absolute;
            left: 1rem;
            z-index: 2;
        }

        .p-dropdown {
            width: 100%;
        }
    }
</style>
