/* eslint-disable */
import store from "../store";
import Vue from "vue";

export const SystemRole = {
    ADMIN: "ADMIN",
    USER: "USER",

    isAdmin() {
        return Vue.cookie.get("role") === this.ADMIN;
    },

    isUser() {
        return Vue.cookie.get("role") === this.USER;
    },

    getUserRole() {
        return Vue.cookie.get("role");
    },

};
