<template>
    <div class="question-outer">
        <div class="question">
            <div class="title">
                <h2 v-html="question.langData[appLang].content"></h2>
                <ul>
                    <li :class="{'answered': answered}">
                        <label v-for="answer in question.answers" v-bind:key="answer.id"
                               tabindex="0"
                               :class="'radiocontainer answer'
                                   + (answer.correct ? ' correct' : ' incorrect')
                                   + (chosenAnswerId === answer.id ? ' chosen' : '')"
                               @mousedown="checkAnswer($event, answer)"
                               @keyup.enter="checkAnswer($event, answer)"
                        >
                            <span v-html="answer.langData[appLang].content"></span>
                            <input
                                tabindex="-1"
                                :disabled="answered"
                                type="radio"
                                :id="'inputRadio' + answer.id" :name="question.id">
                            <span class="checkmark"></span>
                        </label>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Question",

        data() {
            return {
                answered: false,
                chosenAnswerId: null,
            };
        },

        mounted() {
            if (document.querySelectorAll(".question-outer.shown").length > 0) {
                document.querySelector(".question-outer.shown").classList.remove("shown");
            }
            document.querySelectorAll(".question-outer")[0].classList.add("shown");
        },

        methods: {
            checkAnswer(event, answer) {
                if (this.answered) {
                    return;
                }
                this.chosenAnswerId = answer.id;
                event.target.classList.add("chosen");
                this.answered = true;
                this.$emit("collect-answer", answer);
                document.querySelector(".area-view").classList.add("answered-outer");
            },
        },

        props: {
            question: Object,
        },
    };
</script>

<style scoped>
    .answer {
        cursor: pointer;
        transition: .3s all ease;
    }

    .answer.correct.chosen, .question .answered .correct {
        background: #689f38ba;
    }

    .question .answered .answer.incorrect.chosen {
        background: #b000205c;
    }
</style>
