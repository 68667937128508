import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
      fullName: "",
      role: "",
  },
  mutations: {
      setFullName(state, fullName) {
        state.fullName = fullName;
      },

      setRole(state, role) {
          state.role = role;
      },
  },
  actions: {

  },
});
