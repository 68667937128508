<template>
    <div>
        <h2 class="page-header"><i class="pi pi-file p-mr-3"></i>{{headerTitle}}</h2>

        <Button v-if="role.isAdmin()" :disabled="mutualAttachmentDisabled"
                :label="attachment.mutualAttachment.fileName ? $t('button.editSharedAttachment') :
                    $t('button.addSharedAttachment')" icon="pi pi-plus" @click="$refs.mutualFile.click()">
        </Button>
        <Button icon="pi pi-info" class="p-button-rounded p-button-info p-button-text"
                v-tooltip.bottom="$t('menu.attachments.sharedAttachmentInfo')"/>
        <div v-if="attachment.mutualAttachment.fileName" class="p-mt-2">{{attachment.mutualAttachment.fileName}}</div>

        <div class="p-grid p-ml-4 p-mr-4 p-mt-3">
            <div class="p-col-3"></div>
            <div class="p-col">
                <CustomInputText name="mutualAttachment" :label="$t('label.sharedAttachmentUrl')"
                                 v-model="attachment.mutualAttachment.url" :disabled="mutualUrlDisabled"/>
            </div>
            <div class="p-col-3"></div>
        </div>

        <div class="p-grid p-ml-4 p-mr-4 p-mt-5">
            <div class="p-col">
                <CustomInputText name="plName" :label="$t('label.plName')" v-model="attachment.plName"/>
            </div>
            <div class="p-col">
                <CustomInputText name="enName" :label="$t('label.enName')" v-model="attachment.enName"/>
            </div>
        </div>

        <div class="p-grid p-ml-4 p-mr-4 p-mt-5">
            <div class="p-col">
                <CustomTextarea name="plDescription" v-model="attachment.plDescription"
                                :label="$t('label.plDescription')"/>
            </div>
            <div class="p-col">
                <CustomTextarea name="enDescription" v-model="attachment.enDescription"
                                :label="$t('label.enDescription')"/>
            </div>
        </div>

        <div class="p-grid p-mt-5">
            <div class="p-col">
                <Button v-if="role.isAdmin()" :disabled="plAttachmentDisabled"
                        :label="attachment.plAttachment.fileName ? $t('button.editPolishAttachment') :
                            $t('button.addPolishAttachment')"
                        icon="pi pi-plus" @click="$refs.plFile.click()">
                </Button>
                <div v-if="attachment.plAttachment.fileName" class="p-mt-2">{{attachment.plAttachment.fileName}}</div>
            </div>
            <div class="p-col">
                <Button v-if="role.isAdmin()" :disabled="enAttachmentDisabled"
                        :label="attachment.enAttachment.fileName ? $t('button.editEnglishAttachment') :
                            $t('button.addEnglishAttachment')"
                        icon="pi pi-plus" @click="$refs.enFile.click()">
                </Button>
                <div v-if="attachment.enAttachment.fileName" class="p-mt-2">{{attachment.enAttachment.fileName}}</div>
            </div>
        </div>

        <div class="p-grid p-ml-4 p-mr-4 p-mt-3">
            <div class="p-col">
                <CustomInputText name="plAttachment" :label="$t('label.plAttachmentUrl')"
                                 v-model="attachment.plAttachment.url" :disabled="plUrlDisabled"/>
            </div>
            <div class="p-col">
                <CustomInputText name="plAttachment" :label="$t('label.enAttachmentUrl')"
                                 v-model="attachment.enAttachment.url" :disabled="enUrlDisabled"/>
            </div>
        </div>

        <Toolbar class="actions p-mt-5 p-ml-5">
            <template slot="left">
                <Button v-if="role.isAdmin()" :label="$t('button.saveAttachmentData')" icon="pi pi-save"
                        @click="saveAttachment" :disabled="noFilesNorURLAdded">
                </Button>
            </template>
        </Toolbar>

        <input type="file" ref="mutualFile" :accept="allowedMIMEList" v-show="false"
               v-on:change="handleMutualUpload">
        <input type="file" ref="plFile" :accept="allowedMIMEList" v-show="false"
               v-on:change="handlePlUpload">
        <input type="file" ref="enFile" :accept="allowedMIMEList" v-show="false"
               v-on:change="handleEnUpload">
    </div>
</template>

<script>
    import {
        createAttachmentUsingPOST as createAttachment,
        editAttachmentUsingPUT as editAttachment,
        getAttachmentUsingGET as getAttachment,
    } from "@/swagger/vue-api-client";
    import Button from "primevue/components/button/Button";
    import Toolbar from "primevue/components/toolbar/Toolbar";
    import Tooltip from "primevue/tooltip";
    import CustomInputText from "../../../components/form/CustomInputText";
    import {SystemRole} from "../../../utils/SystemRole";
    import {FileUtils} from "../../../utils/FileUtils";
    import {AttachmentType} from "../../../utils/AttachmentType";
    import CustomTextarea from "../../../components/form/CustomTextarea";

    export default {
        name: "AttachmentFormView",

        components: {
            Button, Toolbar, CustomInputText, CustomTextarea,
        },

        mixins: [FileUtils],

        directives: {
            tooltip: Tooltip,
        },

        data() {
            return {
                role: SystemRole,
                attachment: {
                    id: null,
                    poiId: null,
                    plName: null,
                    enName: null,
                    plDescription: null,
                    enDescription: null,
                    attachmentType: this.$route.params.type.toUpperCase(),
                    mutualAttachment: {
                        fileType: null,
                        fileName: null,
                        encodedData: null,
                        url: null,
                    },
                    plAttachment: {
                        fileType: null,
                        fileName: null,
                        encodedData: null,
                        url: null,
                    },
                    enAttachment: {
                        fileType: null,
                        fileName: null,
                        encodedData: null,
                        url: null,
                    },
                },
            };
        },

        computed: {
            headerTitle() {
                let header = this.$route.params.id ? this.$t("menu.attachments.edit") : this.$t("menu.attachments.add");
                if (this.attachment.attachmentType === AttachmentType.AUDIO) {
                    header += " (" + this.$t("menu.attachments.audioSuffix") + ")";
                }
                if (this.attachment.attachmentType === AttachmentType.VIDEO) {
                    header += " (" + this.$t("menu.attachments.videoSuffix") + ")";
                }
                if (this.attachment.attachmentType === AttachmentType.DOCUMENT) {
                    header += " (" + this.$t("menu.attachments.documentSuffix") + ")";
                }
                return header;
            },

            mutualAttachmentDisabled() {
                return this.attachment.plAttachment.fileName || this.attachment.enAttachment.fileName
                    || !!this.attachment.plAttachment.url
                    || !!this.attachment.enAttachment.url
                    || !!this.attachment.mutualAttachment.url;
            },

            plAttachmentDisabled() {
                return this.attachment.mutualAttachment.fileName
                    || !!this.attachment.mutualAttachment.url
                    || !!this.attachment.plAttachment.url;
            },

            enAttachmentDisabled() {
                return this.attachment.mutualAttachment.fileName
                    || !!this.attachment.mutualAttachment.url
                    || !!this.attachment.enAttachment.url;
            },

            mutualUrlDisabled() {
                return this.attachment.plAttachment.fileName || this.attachment.enAttachment.fileName
                    || !!this.attachment.plAttachment.url
                    || !!this.attachment.enAttachment.url
                    || this.attachment.mutualAttachment.fileName;
            },

            plUrlDisabled() {
                return this.attachment.mutualAttachment.fileName
                    || !!this.attachment.mutualAttachment.url
                    || this.attachment.plAttachment.fileName;
            },

            enUrlDisabled() {
                return this.attachment.mutualAttachment.fileName
                    || !!this.attachment.mutualAttachment.url
                    || this.attachment.enAttachment.fileName;
            },

            noFilesNorURLAdded() {
                return !this.attachment.mutualAttachment.fileName && !this.attachment.mutualAttachment.url
                    && !this.attachment.enAttachment.fileName && !this.attachment.enAttachment.url
                    && !this.attachment.plAttachment.fileName && !this.attachment.plAttachment.url;
            },

            allowedMIMEList() {
                if (this.attachment.attachmentType === AttachmentType.AUDIO) {
                    return "audio/mpeg, audio/mp3, audio/aac";
                }
                if (this.attachment.attachmentType === AttachmentType.VIDEO) {
                    return "video/mp4, video/x-flv, video/avi";
                }
                if (this.attachment.attachmentType === AttachmentType.DOCUMENT) {
                    return "image/jpeg, image/png, application/msword, application/pdf, "
                        + "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                }
                return "";
            },
        },

        methods: {
            async handleMutualUpload() {
                const file = this.$refs.mutualFile.files[0];
                this.attachment.mutualAttachment.fileType = this.getFileType(file);
                this.attachment.mutualAttachment.fileName = file.name;
                this.attachment.mutualAttachment.encodedData = await this.fileToBase64(file);
            },

            async handlePlUpload() {
                const file = this.$refs.plFile.files[0];
                this.attachment.plAttachment.fileType = this.getFileType(file);
                this.attachment.plAttachment.fileName = file.name;
                this.attachment.plAttachment.encodedData = await this.fileToBase64(file);
            },

            async handleEnUpload() {
                const file = this.$refs.enFile.files[0];
                this.attachment.enAttachment.fileType = this.getFileType(file);
                this.attachment.enAttachment.fileName = file.name;
                this.attachment.enAttachment.encodedData = await this.fileToBase64(file);
            },

            getFileType(file) {
                let fileType = null;
                if (file.type === "audio/mpeg" || file.type === "audio/mp3" || file.type === "audio/aac") {
                    fileType = "AUDIO";
                } else if (file.type === "video/mp4" || file.type === "video/x-flv" || file.type === "video/avi") {
                    fileType = "VIDEO";
                } else if (file.type === "image/jpeg" || file.type === "image/png") {
                    fileType = "IMAGE";
                } else fileType = "DOCUMENT";
                return fileType;
            },

            saveAttachment() {
                if (!this.validationPassed()) {
                    this.$eventBus.$emit("error", this.$t("snackbar.attachmentSaveError"));
                    return;
                }
                if (this.attachment.id === null) {
                    this.createAttachment();
                } else {
                    this.editAttachment();
                }
            },

            validationPassed() {
                if (this.attachment.mutualAttachment.fileName || this.attachment.mutualAttachment.url) {
                    return this.attachment.plName && this.attachment.enName;
                }
                let valid = true;
                if (this.attachment.plAttachment.fileName || this.attachment.plAttachment.url) {
                    if (!this.attachment.plName) valid = false;
                }
                if (this.attachment.enAttachment.fileName || this.attachment.enAttachment.url) {
                    if (!this.attachment.enName) valid = false;
                }
                return valid;
            },

            createAttachment() {
                createAttachment({updateAttachmentDto: this.attachment}).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.attachmentAddSuccess"));
                    this.$router.go(-1);
                });
            },

            editAttachment() {
                editAttachment({updateAttachmentDto: this.attachment}).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.attachmentAddSuccess"));
                    this.$router.go(-1);
                });
            },
        },

        created() {
            if (this.$route.params.id) {
                getAttachment({attachmentId: this.$route.params.id}).then((response) => {
                    this.attachment = response.data;
                });
            }
            if (this.$route.params.poiId) {
                this.attachment.poiId = this.$route.params.poiId;
            }
        },
    };
</script>

<style scoped>

</style>
