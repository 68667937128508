<template>
    <div>
        <Toast/>
        <h2 class="page-header"><i class="pi pi-directions p-mr-3"></i>{{headerTitle}}</h2>

        <ValidationObserver ref="formObserver">
            <div class="p-grid p-ml-4 p-mr-4 p-mt-3">
                <div class="p-col">
                    <CustomInputText name="plName" :label="$t('label.plName')"
                                     v-model="path.plName" rules="required"/>
                </div>
                <div class="p-col">
                    <CustomInputText name="enName" :label="$t('label.enName')"
                                     v-model="path.enName" rules="required"/>
                </div>
            </div>

            <div class="p-grid p-ml-4 p-mr-4 p-mt-3">
                <div class="p-col">
                    <CustomTextarea name="plDescription" v-model="path.plDescription"
                                    :label="$t('label.plDescription')"/>
                </div>
                <div class="p-col">
                    <CustomTextarea name="enDescription" v-model="path.enDescription"
                                    :label="$t('label.enDescription')"/>
                </div>
            </div>
        </ValidationObserver>

        <PathPOIListView :poi-list.sync="path.poiList"/>

        <div class="p-grid p-ml-4 p-mr-4 p-mt-5">
            <Toolbar class="actions p-ml-5">
                <template slot="left">
                    <Button :label="$t('button.savePathData')" icon="pi pi-save" @click="savePath"/>
                </template>
            </Toolbar>
        </div>
    </div>
</template>

<script>
    import {
        getPathwayUsingGET as getPathway,
        createOrUpdatePathwayUsingPOST as createOrUpdatePathway,
    } from "@/swagger/vue-api-client";
    import Button from "primevue/components/button/Button";
    import Toolbar from "primevue/components/toolbar/Toolbar";
    import Toast from "primevue/toast";
    import {ValidationObserver} from "vee-validate";
    import CustomInputText from "../../components/form/CustomInputText";
    import CustomTextarea from "../../components/form/CustomTextarea";
    import PathPOIListView from "./components/PathPOIListView";

    export default {
        name: "PathFormView",

        components: {
            PathPOIListView,
            Button,
            Toolbar,
            Toast,
            CustomInputText,
            CustomTextarea,
            ValidationObserver,
        },

        data() {
            return {
                path: {
                    id: null,
                    plName: null,
                    enName: null,
                    plDescription: null,
                    enDescription: null,
                    poiList: [],
                },
                headerTitle: this.$t("menu.paths.add"),
            };
        },

        methods: {
            savePath() {
                this.$refs.formObserver.validate().then((valid) => {
                    if (!valid) {
                        return;
                    }
                    createOrUpdatePathway({pathwayDto: this.path}).then(() => {
                        this.$router.go(-1);
                        this.$eventBus.$emit("success", this.$t("snackbar.pathAddSuccess"));
                    });
                });
            },

            getPathData() {
                getPathway({id: this.$route.params.id}).then((response) => {
                    this.path = response.data;
                });
            },
        },

        created() {
            if (this.$route.params.id) {
                this.headerTitle = this.$t("menu.paths.edit");
                this.getPathData();
            }
        },
    };
</script>

<style scoped>

</style>
