<template>
    <div>
        <DataTable :value="areas" :expandedRows="expandedRows" dataKey="id"
                   @row-expand="onRowExpand" @row-collapse="onRowCollapse">
            <template #header>
                <div class="table-header-container">
                    <Button icon="pi pi-plus" :label="$t('button.expandAreas')" @click="expandAll" class="p-mr-2" />
                    <Button icon="pi pi-minus" :label="$t('button.collapseAreas')" @click="collapseAll" />
                </div>
            </template>
            <Column :expander="true" headerStyle="width: 7%"/>
            <Column field="plName" :header="$t('label.plName')" headerStyle="width: 30%"></Column>
            <Column field="enName" :header="$t('label.enName')" headerStyle="width: 30%"></Column>
            <Column :header="$t('label.action')" v-if="role.isAdmin()" headerStyle="width: 33%; text-align: center">
                <template #body="slotProps">
                    <div style="text-align: right">
                        <router-link :to="{name: 'areaCreate', params: {'parentId': slotProps.data.id}}">
                            <Button type="button" icon="pi pi-plus" class="p-button-success p-mr-1"
                                    v-tooltip.top="$t('button.addSubArea')"></Button>
                        </router-link>
                        <router-link :to="{name: 'areaEdit', params: {'id': slotProps.data.id}}">
                            <Button type="button" icon="pi pi-pencil" class="p-button-info p-mr-1"
                                    v-tooltip.top="$t('button.edit')"></Button>
                        </router-link>
                        <Button type="button" icon="pi pi-chevron-up" class="p-button-info p-mr-1"
                                @click="increaseSortOrder(slotProps.data.id)"
                                v-tooltip.top="$t('button.changeOrder')"></Button>
                        <Button type="button" icon="pi pi-chevron-down" class="p-button-info p-mr-1"
                                @click="decreaseSortOrder(slotProps.data.id)"
                                v-tooltip.top="$t('button.changeOrder')"></Button>
                        <Button type="button" icon="pi pi-trash" class="p-button-danger"
                                @click="openConfirmationDialog(slotProps.data.id)"
                                :disabled="slotProps.data.areas.length > 0" v-tooltip.top="$t('button.remove')">
                        </Button>
                    </div>
                </template>
            </Column>
            <template #expansion="slotProps">
                <DataTable :value="slotProps.data.areas" style="padding-left: 7%">
                    <Column field="plName" headerStyle="width: 30%"></Column>
                    <Column field="enName" headerStyle="width: 30%"></Column>
                    <Column v-if="role.isAdmin()" headerStyle="width: 33%;">
                        <template #body="slotProps">
                            <div style="text-align: right">
                                <router-link :to="{name: 'areaEdit', params: {'id': slotProps.data.id}}">
                                    <Button type="button" icon="pi pi-pencil" class="p-button-info p-mr-1"
                                            v-tooltip.top="$t('button.edit')"></Button>
                                </router-link>
                                <Button type="button" icon="pi pi-chevron-up" class="p-button-info p-mr-1"
                                        @click="increaseSortOrder(slotProps.data.id)"
                                        v-tooltip.top="$t('button.changeOrder')">

                                </Button>
                                <Button type="button" icon="pi pi-chevron-down" class="p-button-info p-mr-1"
                                        @click="decreaseSortOrder(slotProps.data.id)"
                                        v-tooltip.top="$t('button.changeOrder')">

                                </Button>
                                <Button type="button" icon="pi pi-trash" class="p-button-danger"
                                        @click="openConfirmationDialog(slotProps.data.id)"
                                        v-tooltip.top="$t('button.remove')"></Button>
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </template>
        </DataTable>
        <CustomDialog :show="displayConfirmation" :header="$t('dialog.areaRemoval')"
                      :message="$t('dialog.areaRemovalMessage') + ' ' + selectedAreaName + '? ' +
                          $t('dialog.areaRemovalWarning')" v-on:action="removeArea"
                      v-on:close="closeConfirmationDialog"/>
    </div>
</template>

<script>
    import {
        getAreaListUsingGET as getAreaList,
        deleteAreaUsingDELETE as deleteArea,
        increaseAreaSortOrderUsingPUT as increaseSortOrder,
        decreaseAreaSortOrderUsingPUT as decreaseSortOrder,
    } from "@/swagger/vue-api-client";
    import Button from "primevue/components/button/Button";
    import DataTable from "primevue/components/datatable/DataTable";
    import Column from "primevue/components/column/Column";
    import Tooltip from "primevue/tooltip";
    import {SystemRole} from "@/utils/SystemRole";
    import CustomDialog from "../../../components/form/CustomDialog";

    export default {
        name: "AreaTable",

        components: {
            CustomDialog, Button, DataTable, Column,
        },

        directives: {
            tooltip: Tooltip,
        },

        data() {
            return {
                areas: [],
                expandedRows: [],
                role: SystemRole,
                selectedArea: null,
                displayConfirmation: false,
            };
        },

        computed: {
            selectedAreaName() {
                let name = "";
                if (this.selectedArea) {
                    this.areas.forEach((area) => {
                        if (area.id === this.selectedArea) name = this.appLang === "pl" ? area.plName : area.enName;
                        else {
                            const found = area.areas.find(subarea => subarea.id === this.selectedArea);
                            if (found) name = this.appLang === "pl" ? found.plName : found.enName;
                        }
                    });
                }
                return name;
            },
        },

        methods: {
            expandAll() {
                this.expandedRows = this.areas.filter(a => a.id && a.areas.length);
            },
            collapseAll() {
                this.expandedRows = [];
            },

            onRowExpand(event) {
                if (event.data.areas.length) this.expandedRows.push(event.data);
            },

            onRowCollapse(event) {
                this.expandedRows = this.expandedRows.filter(row => row.id !== event.data.id);
            },

            increaseSortOrder(areaId) {
                increaseSortOrder({id: areaId}).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.areaOrderChangeSuccess"));
                    this.refresh();
                });
            },

            decreaseSortOrder(areaId) {
                decreaseSortOrder({id: areaId}).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.areaOrderChangeSuccess"));
                    this.refresh();
                });
            },

            openConfirmationDialog(areaId) {
                this.selectedArea = areaId;
                this.displayConfirmation = true;
            },

            closeConfirmationDialog() {
                this.selectedArea = null;
                this.displayConfirmation = false;
            },

            removeArea() {
                deleteArea({id: this.selectedArea}).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.areaRemoveSuccess"));
                    this.closeConfirmationDialog();
                    this.refresh();
                });
            },

            refresh() {
                getAreaList().then((response) => {
                    this.areas = response.data.sort((item1, item2) => (item1.sortOrder > item2.sortOrder ? 1 : -1));
                    this.areas.forEach((area) => {
                        area.areas.sort((item1, item2) => (item1.sortOrder > item2.sortOrder ? 1 : -1));
                    });
                    this.expandAll();
                });
            },
        },

        created() {
            this.refresh();
        },
    };
</script>

<style scoped>
    >>> .p-datatable .p-datatable-tbody > tr > td {
        padding-right: 4px;
    }
    >>> tr.p-datatable-row-expansion td {
        background-color: rgb(232, 232, 232) !important;
    }
    >>> tr.p-datatable-row-expansion tr {
        background-color: rgb(232, 232, 232) !important;
    }
    >>> tr.p-datatable-row-expansion .p-datatable-thead {
        display: none;
    }
</style>
