<template>
    <div class="bottom-bar">
        <div class="bottom-bar-buttons">
            <router-link
                :to="{name: 'map', params: {id: poiData.areaId, mode: 'przystanek', pointNumber: poiData.id}}"
                v-if="currentAreaHasMap"
                class="map">
                <lord-icon
                    palette="#eac587;#eac587"
                    animation="morph"
                    icon="pinOnSquareMap">
                </lord-icon>
                <span>{{ $t('label.map') }}</span>
            </router-link>
            <div href="#"
                 v-if="description"
                 tabindex="0"
                 id="text"
                 class="text"
                 @click="toggleMedia($event)"
                 @keyup.enter="toggleMedia($event)">
                <lord-icon
                    palette="#eac587;#eac587"
                    animation="hover"
                    icon="documents">
                </lord-icon>
                <span>{{ $t('label.description') }}</span>
            </div>
            <div class="gallery-bottom-lightbox-outer" v-if="media.length > 0">
                <!-- Contrary to the documentation, loop only works when the effect is fade -->
                <CoolLightBox
                    :items="media"
                    :index="indexG"
                    :effect="'fade'"
                    :useZoomBar="true"
                    :closeOnClickOutsideMobile="true"
                    @close="indexG = null">
                </CoolLightBox>
                <div class="images-wrapper">
                    <div
                        href="#"
                        class="gallery"
                        @keyup.enter="openLightBoxGallery()"
                        @click="indexG = 0"
                        tabindex="0">
                        <lord-icon
                            palette="#eac587;#eac587"
                            animation="morph"
                            icon="gallery">
                        </lord-icon>
                        <span>{{ $t('label.gallery') }}</span>
                    </div>
                </div>
            </div>
            <div class="video-bottom-lightbox-outer" tabindex="0" v-if="ytbUrl.length > 0 && !isOffline()">
                <CoolLightBox
                    :items="ytbUrl"
                    :index="indexY"
                    :closeOnClickOutsideMobile="true"
                    @close="indexY = null"
                    @on-change="stopPreviousVideo($event)"
                >
                </CoolLightBox>
                <div class="images-wrapper">
                    <div href="#" class="video" @click="indexY = 0">
                        <lord-icon
                            palette="#eac587;#eac587"
                            animation="hover"
                            icon="film">
                        </lord-icon>
                        <span>{{ $t('label.video') }}</span>
                    </div>
                </div>
            </div>
            <div href="#" id="audio" class="audio"
                 @click="toggleMedia($event)"
                 @keyup.enter="toggleMedia($event)"
                 v-if="audio.length > 0">
                <lord-icon
                    palette="#eac587;#eac587"
                    animation="hover"
                    icon="headphones">
                </lord-icon>
                <span>{{ $t('label.audio') }}</span>
            </div>
            <div
                href="#"
                id="panorams"
                class="panorams"
                tabindex="0"
                @click="toggleMedia($event); toggleAccesKeyArrows()"
                @keyup.enter="toggleMedia($event); toggleAccesKeyArrows()" >
                <lord-icon
                    palette="#eac587;#eac587"
                    animation="hover"
                    icon="map">
                </lord-icon>
                <span>{{ $t('label.panorams') }}</span>
            </div>
            <div href="#" id="share" class="share" tabindex="0" @click="toggleMedia($event)">
                <lord-icon
                    palette="#eac587;#eac587"
                    animation="hover"
                    icon="share">
                </lord-icon>
                <span>{{ $t('label.share') }}</span>
            </div>
        </div>
        <div class="bottom-bar-media">
            <div v-html="this.description" class="text-description text bottom-media-scrolled-el">
                {{ this.description }}
            </div>
            <div class="audio bottom-media-scrolled-el" v-if="audio.length > 0 && audio[0].langData[appLang] !== null">
                <vue-plyr>
                    <audio controls crossorigin playsinline>
                        <source
                            :src="staticFileUrl(audio[0].langData[appLang].filePath)"
                            type="audio/mp4"
                        />
                    </audio>
                </vue-plyr>
            </div>
            <div class="panorams bottom-media-scrolled-el" v-if="thumbs.length > 0">
                <ThumbnailBar :thumbs="thumbs" :called-function="goToPanoramaAfterClick"></ThumbnailBar>
            </div>
            <div class="share bottom-media-scrolled-el">
                <a class="share-tw"><div class="visually-hidden">{{ $t('label.shareTwitter') }}</div></a>
                <div class="share-fb">
                    <div class="visually-hidden">{{ $t('label.shareFacebook') }}</div>
                </div>
            </div>
        </div>
        <PanoramaLegend/>
    </div>
</template>

<script>
    import PanoramaLegend from "@/components/PanoramaLegend";
    import CoolLightBox from "vue-cool-lightbox";
    import {dragscroll} from "vue-dragscroll";
    import {HTMLUtils} from "@/utils/HTMLUtils";
    import VuePlyr from "vue-plyr";
    import "vue-plyr/dist/vue-plyr.css";
    import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
    import "@splidejs/splide/dist/css/themes/splide-default.min.css";
    import ThumbnailBar from "@/components/ThumbnailBar";

    export default {
        name: "PanoramaBottomBar",

        components: {
            ThumbnailBar,
            CoolLightBox,
            VuePlyr,
            PanoramaLegend,
        },
        directives: {
            dragscroll,
        },

        props: {
            poiData: Object,
            poiList: Array,
            currentAreaHasMap: Boolean,
        },

        data() {
            return {
                publicPath: process.env.BASE_URL,
                options: {
                    startIndex: 0,
                    draggable: true,
                    multipleDrag: true,
                    threshold: 20,
                    loop: false,
                    rtl: false,
                    perPage: {
                        768: 2,
                        1024: 3,
                    },
                },
                panoramaOptionsSlider: {
                    autoWidth: true,
                    perMove: 1,
                    pagination: false,
                    focus: "center",
                },
                hooperSettings: {
                    itemsToShow: 6,
                    centerMode: false,
                    breakpoints: {
                        0: {
                            itemsToShow: 2,
                        },
                        500: {
                            itemsToShow: 3,
                        },
                        800: {
                            itemsToShow: 4,
                        },
                        1000: {
                            itemsToShow: 6,
                        },
                    },
                },
                media: [],
                description: "",
                audio: [],
                ytbUrl: [],
                mapData: {},
                thumbs: [],
                indexG: null,
                indexY: null,
                previousYtbIndex: 0,
            };
        },

        mixins: [HTMLUtils],

        created() {
            this.prepareData();
            this.prepareDesc();
            document.addEventListener("click", (e) => {
                // Po kliknięciu w dowolne miejsce chowamy
                // opis, audio i share
                let clickedElement;
                if (e == null) {
                    clickedElement = null;
                } else {
                    clickedElement = e.target;
                }
                try {
                    if ((clickedElement.classList.contains("pnlm-dragfix")
                        || clickedElement.classList.contains("pnlm-hotspot-base")
                        || clickedElement.classList.contains("panorama-heading")
                        || clickedElement.classList.contains("panorama-title")
                        || clickedElement.classList.contains("panorama-main-container-heading-inner")
                        || clickedElement.id === "path-header"
                        || clickedElement.parentElement.id === "path-header"
                        || clickedElement.classList.contains("clicking-hides-bottom-bar"))
                        && this.isBottomBarActive() && clickedElement.tagName !== "LORD-ICON") {
                        const dataBottomBarActive = document.getElementById("app")
                            .getAttribute("data-bottom-bar-active");
                        if (dataBottomBarActive !== null
                            && dataBottomBarActive !== ""
                            && document.getElementById(dataBottomBarActive) !== null) {
                            this.toggleMedia({target: document.getElementById(dataBottomBarActive)});
                        }
                    }
                } catch (error) {
                    // The error happens when user drags outside panorama. It is harmless.
                }
            });
        },

        mounted() {
            this.panoramasBlockingAccessKeyboard();
            const dataBottomBarActive = document.getElementById("app")
                .getAttribute("data-bottom-bar-active");
            if (document.getElementById("app").getAttribute("data-reclick-bottom-bar") !== null) {
                document.getElementById("app").removeAttribute("data-reclick-bottom-bar");
                if (dataBottomBarActive !== null
                    && dataBottomBarActive !== ""
                    && document.getElementById(dataBottomBarActive) !== null) {
                    this.toggleMedia({target: document.getElementById(dataBottomBarActive)});
                }
            }
            this.setBottomBarPosition();
            window.addEventListener("resize", this.setBottomBarPosition);
            // Sometimes the initial bottom bar position is incorrect
            // due to some transitions happening on page load.
            // They are hard to turn off and the problem is rare,
            // so we just recompute the position after 500 ms
            // in case it happens.
            setTimeout(() => this.setBottomBarPosition(), 500);
        },

        destroyed() {
            this.$off();
        },

        methods: {

            prepareData() {
                this.prepareMedia();
                this.prepareYtbUrl();
                this.prepareThumbs();
            },

            goToPanoramaAfterClick(event, thumb) {
                if (this.$route.params.id.toString() === thumb.id.toString()) {
                    event.stopPropagation();
                    return;
                }
                this.$router.push({
                    name: "walk",
                    params: {
                        id: thumb.id,
                    },
                });
            },

            prepareMedia() {
                this.media = [];
                for (let i = 0; i < this.poiData.attachments.length; i += 1) {
                    const attachment = this.poiData.attachments[i];
                    switch (attachment.attachmentType) {
                        case "DOCUMENT":
                            this.media.push(this.createGalleryItem(attachment));
                            break;
                        case "AUDIO":
                            this.audio.push(attachment);
                            break;
                        default:
                    }
                }
            },
            prepareYtbUrl() {
                this.ytbUrl = [];
                for (let i = 0; i < this.poiData.attachments.length; i += 1) {
                    const attachment = this.poiData.attachments[i];
                    if ((typeof attachment.langData[this.appLang] !== "undefined")
                        && (attachment.langData[this.appLang].fileUrl !== null)) {
                        this.ytbUrl.push({
                            src: attachment.langData[this.appLang].fileUrl,
                        });
                    }
                }
            },
            prepareThumbs() {
                this.thumbs = [];
                const poisWithTheSameArea = this.getPoisWithTheSameArea(this.poiData.areaId);
                for (let i = 0; i < poisWithTheSameArea.length; i += 1) {
                    const poi = poisWithTheSameArea[i];
                    const panorama = poi.panoramas[0];
                    if ((typeof poi.langData[this.appLang] !== "undefined")
                        && (panorama.thumbnailFilePath !== null)) {
                        this.thumbs.push(this.createThumbItem(panorama, poi, this.poiData));
                    }
                    if (poi.id === this.poiData.id) {
                        this.setCurrentThumbIndex(i);
                    }
                }
            },
            createThumbItem(panorama, poi, currentPoi) {
                return {
                    src: this.staticFileUrl(panorama.thumbnailFilePath),
                    title: poi.langData[this.appLang].name,
                    url: "/spacer/" + poi.id,
                    id: poi.id,
                    isCurrent: currentPoi.id === poi.id,
                };
            },
            setCurrentThumbIndex(loopIndex) {
                this.panoramaOptionsSlider.start = loopIndex;
            },

            createGalleryItem(attachment) {
                return {
                    src: this.staticFileUrl(attachment.filePath),
                    thumb: this.staticFileUrl(attachment.filePath),
                    title: attachment.langData[this.appLang].name
                        + ("<div class='desc'>"
                        + (attachment.langData[this.appLang].description
                            ? attachment.langData[this.appLang].description : "")
                        + "</div>"),
                    alt: attachment.langData[this.appLang].name,
                };
            },

            prepareDesc() {
                this.description = this.poiData.langData[this.appLang].description;
            },

            getPoisWithTheSameArea(areaId) {
                return this.poiList.filter(poi => poi.areaId === areaId);
            },

            openGallery() {
                this.$refs.lightbox.showImage(0);
            },

            hideAllMedia() {
                const bottomMedia = document.querySelectorAll(".bottom-bar-media > div.bottom-media-scrolled-el");
                for (let i = 0; i < bottomMedia.length; i += 1) {
                    bottomMedia[i].style.display = "none";
                }
            },

            toggleMedia(event) {
                if (document.querySelector(".bottom-bar") !== null) {
                    // Turning transition back on
                    document.querySelector(".bottom-bar")
                        .style.setProperty("transition", "");
                }
                const bottomBar = document.querySelector(".bottom-bar");
                const bottomBarMedia = document.querySelector(".bottom-bar-media");
                let targetElement = event.target;

                if (targetElement.tagName === "LORD-ICON") {
                    targetElement = targetElement.parentElement;
                }
                const targetClass = targetElement.classList;

                if (!this.isBottomBarActive()) {
                    bottomBar.classList.toggle("shown");
                    this.hideAllMedia();
                    if (targetClass.length > 0) {
                        const something = bottomBarMedia.querySelector("." + targetClass);
                        if (something) {
                            something.style.display = "block";
                        }
                    }
                    targetElement.classList.add("active");
                    document.getElementById("app")
                        .setAttribute("data-bottom-bar-active", targetElement.id);

                    return;
                }

                if (targetElement.classList.contains("active")) {
                    bottomBar.classList.toggle("shown");
                    this.setBottomBarPosition();
                    targetElement.classList.remove("active");
                    document.getElementById("app")
                        .removeAttribute("data-bottom-bar-active");

                    return;
                }

                if (this.isBottomBarActive() && !targetElement.classList.contains("active")) {
                    this.hideAllMedia();
                    if (targetClass.length > 0) {
                        const something = bottomBarMedia.querySelector("." + targetClass);
                        if (something) {
                            something.style.display = "block";
                        }
                    }
                    this.deactiveAllBtns();
                    targetElement.classList.add("active");
                    document.getElementById("app")
                        .setAttribute("data-bottom-bar-active", targetElement.id);
                }
            },

            deactiveAllBtns() {
                const buttons = document.querySelectorAll(".bottom-bar-buttons > div");

                for (let i = 0; i < buttons.length; i += 1) {
                    buttons[i].classList.remove("active");
                }
            },

            isBottomBarActive() {
                const buttons = document.querySelectorAll(".bottom-bar-buttons > div");

                for (let i = 0; i < buttons.length; i += 1) {
                    if (buttons[i].classList.contains("active")) {
                        return true;
                    }
                }
                return false;
            },

            setBottomBarPosition() {
                if (document.querySelector(".bottom-bar-media") !== null
                    && document.querySelector(".bottom-bar") !== null) {
                    const mediaHeight = document.querySelector(".bottom-bar-media").clientHeight;
                    const bottomBar = document.querySelector(".bottom-bar");

                    bottomBar.style.bottom = "-" + mediaHeight + "px";
                }
            },

            showModalInfo() {
                this.$modal.show("info-modal");
            },

            closeModalInfo() {
                this.$modal.hide("info-modal");
            },

            stopPreviousVideo(indexOfNewVideo) {
                if (this.previousYtbIndex === indexOfNewVideo) {
                    return;
                }
                const iframes = document.getElementsByClassName("cool-lightbox-video");
                // stopping the video by resetting its src attribute to the same value
                iframes[this.previousYtbIndex].setAttribute(
                    "src",
                    iframes[this.previousYtbIndex].getAttribute("src"),
                );
                this.previousYtbIndex = indexOfNewVideo;
            },
            toggleAccesKeyArrows() {
                if (document.querySelector("#panorams").classList.contains("active")) {
                    this.panoramasAccessKeyboard();
                } else {
                    this.panoramasBlockingAccessKeyboard();
                }
            },
            panoramasBlockingAccessKeyboard() {
                this.panoramasArrowsBlockingAccessKeyboard();
                this.panoramasLinkBlockingAccessKeyboard();
            },
            panoramasAccessKeyboard() {
                this.panoramasArrowsAccessKeyboard();
                this.panoramasLinkAccessKeyboard();
            },
            panoramasArrowsBlockingAccessKeyboard() {
                for (let i = 0; i < document.querySelectorAll(".thumbnail-bar button").length; i += 1) {
                    document.querySelectorAll(".thumbnail-bar button")[i].setAttribute("tabindex", "-1");
                }
            },
            panoramasArrowsAccessKeyboard() {
                for (let i = 0; i < document.querySelectorAll(".thumbnail-bar button").length; i += 1) {
                    document.querySelectorAll(".thumbnail-bar button")[i].setAttribute("tabindex", "0");
                }
            },
            panoramasLinkBlockingAccessKeyboard() {
                for (let i = 0; i < document.querySelectorAll(".thumbnail-list a").length; i += 1) {
                    document.querySelectorAll(".thumbnail-list a")[i].setAttribute("tabindex", "-1");
                }
            },
            panoramasLinkAccessKeyboard() {
                for (let i = 0; i < document.querySelectorAll(".thumbnail-list a").length; i += 1) {
                    document.querySelectorAll(".thumbnail-list a")[i].setAttribute("tabindex", "0");
                }
            },
            openLightBoxGallery() {
                const evt = new MouseEvent("click", {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    clientX: 20,
                });
                const ele = document.querySelector(".gallery");
                const nextButtonArrow = document.querySelector(".cool-lightbox-button--next");
                ele.dispatchEvent(evt);
                if (nextButtonArrow) {
                    nextButtonArrow.focus();
                }
            },
        },
    };
</script>

<style lang="less">
    @import "../views/assets/less/bottom-bar";

    .panorama-thumbnail {
        cursor: pointer;
    }
    .panorama-thumbnail.current-panorama {
        cursor: auto;
        border: 3px solid white;
    }

</style>
