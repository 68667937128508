<template>
    <div>
        <h2 class="page-header"><i class="pi pi-image p-mr-3"></i>{{$t('menu.maps.title')}}</h2>

        <Toolbar class="actions">
            <template slot="left">
                <Button v-if="role.isAdmin()" :label="$t('button.addMap')" icon="pi pi-plus" @click="createMap()"/>
            </template>
        </Toolbar>

        <DataTable :value="maps" dataKey="index" v-if="maps.length > 0">
            <Column field="name" :header="$t('label.identifier')"></Column>
            <Column :header="$t('label.action')" v-if="role.isAdmin()">
                <template #body="slotProps">
                    <router-link :to="{name: 'mapEdit', params: {'id': slotProps.data.id}}">
                        <Button type="button" icon="pi pi-pencil" class="p-button-info p-mr-1"></Button>
                    </router-link>
                    <Button type="button" icon="pi pi-trash" class="p-button-danger"
                            @click="openConfirmationDialog(slotProps.data.id)"></Button>
                </template>
            </Column>
        </DataTable>

        <CustomDialog :show="displayConfirmation" :header="$t('dialog.mapRemoval')"
                      :message="$t('dialog.mapRemovalMessage') + ' ' + selectedMap + '? ' +
                          $t('dialog.mapRemovalWarning')"
                      v-on:action="removeMap" v-on:close="closeConfirmationDialog"/>
    </div>
</template>

<script>
    import {
        deleteMapUsingDELETE as deleteMap,
        getMapIdListUsingGET as getMapIdList,
    } from "@/swagger/vue-api-client";
    import Button from "primevue/components/button/Button";
    import Toolbar from "primevue/components/toolbar/Toolbar";
    import DataTable from "primevue/components/datatable/DataTable";
    import Column from "primevue/components/column/Column";
    import {SystemRole} from "../../../utils/SystemRole";
    import {RouterUtils} from "../../../utils/RouterUtils";
    import CustomDialog from "../../../components/form/CustomDialog";

    export default {
        name: "MapListView",

        components: {
            CustomDialog, Button, Toolbar, DataTable, Column,
        },

        mixins: [RouterUtils],

        data() {
            return {
                role: SystemRole,
                maps: [],
                selectedMap: null,
                displayConfirmation: false,
            };
        },

        methods: {
            createMap() {
                this.pushAsync({name: "mapCreate", params: {areaId: this.$route.params.id}});
            },

            removeMap() {
                deleteMap({id: this.selectedMap}).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.mapRemoveSuccess"));
                    this.maps = this.maps.filter(p => p.id !== this.selectedMap);
                    this.closeConfirmationDialog();
                });
            },

            openConfirmationDialog(mapId) {
                this.selectedMap = mapId;
                this.displayConfirmation = true;
            },

            closeConfirmationDialog() {
                this.selectedMap = null;
                this.displayConfirmation = false;
            },
        },

        created() {
            getMapIdList({areaId: this.$route.params.id}).then((response) => {
                this.maps = response.data.map(mapId => ({id: mapId, name: "Mapa " + mapId}));
            });
        },
    };
</script>

<style scoped>

</style>
