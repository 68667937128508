/* eslint-disable */
import {GeometryUtils} from "@/utils/GeometryUtils";

export default class MapProjection {
    #A;

    #B;

    #C;

    #D;

    #Ap;

    #Bp;

    #Cp;

    #Dp;

    #E;

    #Ep;

    constructor(pairs) {
        let pairsReordered = this.chooseRightPairsOrder(pairs);
        this.#A = pairsReordered[0][0];
        this.#C = pairsReordered[1][0];
        this.#B = pairsReordered[2][0];
        this.#D = pairsReordered[3][0];
        this.#Ap = pairsReordered[0][1];
        this.#Cp = pairsReordered[1][1];
        this.#Bp = pairsReordered[2][1];
        this.#Dp = pairsReordered[3][1];
        this.#E = GeometryUtils.checkLineIntersection(this.#A, this.#C, this.#B, this.#D);
        this.#Ep = GeometryUtils.checkLineIntersection(this.#Ap, this.#Cp, this.#Bp, this.#Dp);
    }

    chooseRightPairsOrder(pairs) {
        let points = [];
        let orders = [
            [0, 1, 2, 3],
            [0, 2, 1, 3],
            [0, 3, 1, 2],
        ];
        for (let i = 0; i < pairs.length; i += 1) {
            points.push(pairs[i][0]);
        }
        for (let i = 0; i < orders.length; i += 1) {
            let order = orders[i];
            let permutedPoints = this.permutation(points, order);
            let isIntersectionRight = this.isIntersectionOnSegment(
                {start: permutedPoints[0], end: permutedPoints[1]},
                {start: permutedPoints[2], end: permutedPoints[3]}
            );
            if (isIntersectionRight) {
                return this.permutation(pairs, order);
            }
        }
        return pairs;
    }

    isIntersectionOnSegment(line1, line2) {
        let intersection = GeometryUtils.checkLineIntersection(line1.start, line1.end, line2.start, line2.end);
        return intersection.onLine1 && intersection.onLine2;
    }

    permutation(table, permutation) {
        let permutedTable = [];
        for (let i = 0; i < table.length; i++) {
            permutedTable.push(table[permutation[i]]);
        }
        return permutedTable;
    }

    projection(X, savePoints) {
        if (typeof savePoints === "undefined") {
            savePoints = false;
        }
        let M = this.bestVertexForIntersection(X, this.#A, this.#C, [this.#B, this.#D]);
        let N = this.bestVertexForIntersection(X, this.#B, this.#D, [this.#A, this.#C]);
        let Mp = M === this.#B ? this.#Bp : this.#Dp;
        let Np = N === this.#A ? this.#Ap : this.#Cp;

        let K = GeometryUtils.checkLineIntersection(M, X, this.#A, this.#C);
        let L = GeometryUtils.checkLineIntersection(N, X, this.#B, this.#D);

        let crK = GeometryUtils.crossRatio(this.#A, this.#C, this.#E, K);
        let crL = GeometryUtils.crossRatio(this.#B, this.#D, this.#E, L);

        let rKAC = GeometryUtils.ratioFromCrossRatio(this.#Ap, this.#Cp, this.#Ep, crK);
        let rLBD = GeometryUtils.ratioFromCrossRatio(this.#Bp, this.#Dp, this.#Ep, crL);

        let lKAC = GeometryUtils.pointLocationBetween(K, this.#A, this.#C);
        let lLBD = GeometryUtils.pointLocationBetween(L, this.#B, this.#D);

        let Kp = GeometryUtils.pointBetween(this.#Ap, this.#Cp, rKAC, lKAC);
        let Lp = GeometryUtils.pointBetween(this.#Bp, this.#Dp, rLBD, lLBD);

        let object = GeometryUtils.checkLineIntersection(Np, Lp, Mp, Kp);

        if (
            !this.areTwoPointsOnTheOppositeSidesOfLine(object, Mp, this.#Ap, this.#Cp)
            || !this.areTwoPointsOnTheOppositeSidesOfLine(object, Np, this.#Bp, this.#Dp)
        ) {
            object.isSingular = true;
        }

        if (savePoints) {
            object.points = {
                Kp: Kp,
                Lp: Lp,
                Mp: Mp,
                Np: Np,
                Ap: this.#Ap,
                Bp: this.#Bp,
                Cp: this.#Cp,
                Dp: this.#Dp,
                Ep: this.#Ep,
                K: K,
                L: L,
            };
        }

        return object;
    }

    bestVertexForIntersection(sourcePoint, A, B, vertices) {
        let point;
        for (let i = 0; i < vertices.length; i++) {
            point = vertices[i];
            if (this.areTwoPointsOnTheOppositeSidesOfLine(sourcePoint, point, A, B)) {
                return point;
            }
        }
        return point;
    }

    areTwoPointsOnTheOppositeSidesOfLine(point1, point2, lineStart, lineEnd) {
       return  ((lineStart.y - lineEnd.y)*(point1.x - lineStart.x) + (lineEnd.x - lineStart.x)*(point1.y - lineStart.y))
            *((lineStart.y - lineEnd.y)*(point2.x - lineStart.x) + (lineEnd.x - lineStart.x)*(point2.y - lineStart.y))
            < 0;
    }

    controlPoints() {
        return [this.#Ap, this.#Bp, this.#Cp, this.#Dp];
    }
}
