<template>
    <div>
        <div class="info-text" :data-lang-app="this.appLang">
            <div class="info-text-pl">
                <h4>
                    Wirtualne zwiedzanie<br>
                    „Muzeum i Miejsce Pamięci Narodowej w Łambinowicach”
                </h4>
                <h5>Koncepcja spaceru, wybór zdjęć i konsultacja historyczna:</h5>
                <p>Maria Bula, Iwona Cichoń, Anna Czerner, Elżbieta Góra, Krzysztof Harupa,
                    Piotr Jędorowicz, Andrzej Kern, Ewelina Klimczak, Renata Kobylarz-Buła,
                    Sebastian Mikulec, Michał Nadybski, Violetta Rezler-Wasielewska, Monika Sobczak,
                    Piotr Stanek, Krzysztof Sznotala, Kamil Weber, Anna Wickiewicz, Dawid Żak</p>
                <h5>Opracowanie tekstów:</h5>
                <ul>
                    <li>Lucyna Sterniuk-Gronek</li>
                    <li>Anna Czerner</li>
                    <li>Violetta Rezler-Wasielewska</li>
                </ul>
                <h5>Tłumaczenie na język angielski:</h5>
                <ul>
                    <li>Jacek Jędrzejowski</li>
                    <li>Sebastian Mikulec</li>
                </ul>
                <h5>Redakcja stylistyczno-językowa:</h5>
                Aleksandra Domka-Kordek
                <h5>Lektorzy:</h5>
                <ul>
                    <li>Bartosz Głogowski</li>
                    <li>Andrew Edwins</li>
                </ul>
                <h5>Realizacja:</h5>
                Vavatech Sp. z o.o.<br>
                <p>© Centralne Muzeum Jeńców Wojennych, 2020</p>
                <p>
                    Projekt sfinansowany ze środków Unii Europejskiej, Ministra Kultury<br>
                    i Dziedzictwa Narodowego oraz Województwa Opolskiego
                </p>
            </div>
            <div class="info-text-en">
                <h4>
                    A Virtual Visit to<br>
                    The Museum and the Site of National Remembrance in Łambinowice
                </h4>
                <h5>Concept of the virtual walk, selection of photos and historical consultation:</h5>
                <p>Maria Bula, Iwona Cichoń, Anna Czerner, Elżbieta Góra, Krzysztof Harupa,
                    Piotr Jędorowicz, Andrzej Kern, Ewelina Klimczak, Renata Kobylarz-Buła,
                    Sebastian Mikulec, Michał Nadybski, Violetta Rezler-Wasielewska, Monika Sobczak,
                    Piotr Stanek, Krzysztof Sznotala, Kamil Weber, Anna Wickiewicz, Dawid Żak</p>
                <h5>Preparation of texts:</h5>
                <ul>
                    <li>Lucyna Sterniuk-Gronek</li>
                    <li>Anna Czerner</li>
                    <li>Violetta Rezler-Wasielewska</li>
                </ul>
                <h5>Translation into English:</h5>
                <ul>
                    <li>Jacek Jędrzejowski</li>
                    <li>Sebastian Mikulec</li>
                </ul>
                <h5>Proofreading and stylistic editing:</h5>
                Aleksandra Domka-Kordek
                <h5>Text readers:</h5>
                <ul>
                    <li>Bartosz Głogowski</li>
                    <li>Andrew Edwins</li>
                </ul>
                <h5>Production:</h5>
                Vavatech Sp. z o.o.<br>
                <p>© Centralne Muzeum Jeńców Wojennych, 2020</p>
                <p>
                    The project is financed by the European Union, the Minister of Culture and National Heritage
                    and the Opole Voivodeship
                </p>
                <p>
                    Financed with means of the Regional Operating Program of Opole Province for the years
                    2014–2020 and the Minister of National Culture and Heritage (Promesa Program)
                </p>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "AboutAppInfo",
        computed: {
        },

        data() {
            return {
            };
        },

        created() {
        },

    };
</script>

<style lang="less" scope>
    @import "../../../assets/theme/variable.less";
    .info-text-modal .vm--modal {
        padding: 10px;
        background-color: transparent;
        box-shadow: none;
        overflow: auto;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .info-text-modal .vm--overlay {
        background-color: rgba(35, 31, 32, 0.82);
        // z-index: 2;
    }
    .info-modal-content {
        z-index: 999;
        position: relative;
    }
    .info-text {
        color: @white-bg;
        z-index: 999;
        &::-webkit-scrollbar {
            display: none;
        }
        &[data-lang-app='en'] {
            .info-text-pl {
                display: none;
            }
        }
        &[data-lang-app='pl'] {
            .info-text-en {
                display: none;
            }
        }
        h4 {
            margin: 0 0 14px;
        }
        h5 {
            margin: 9px 0 2px 0;
            font-size: 15px;
        }
        ul {
            list-style: none;
            margin: 0 0 0;
            padding: 0;
        }
    }
    .text-info-modal-close {
        display: none;
        border: 0;
        appearance: none;
        opacity: 1;
        padding: 9px;
        position: fixed;
        right: 0;
        top: 0;
        cursor: pointer;
        width: 40px;
        height: 40px;
        padding: 10px;
        z-index: 999;
        background-color: rgba(30, 30, 30, 0.6);
        box-sizing: content-box;
        svg {
            width: 100%;
            height: 100%;
        }
    }
    .info-modal-shown {
        .text-info-modal-close {
            display: block;
        }
    }
</style>
