<template>
    <div>
        <h2 class="page-header"><i class="pi pi-directions p-mr-3"></i>{{ $t('menu.paths.list') }}</h2>

        <Toolbar class="actions">
            <template slot="left">
                <Button v-if="role.isAdmin()" :label="$t('button.addPath')" icon="pi pi-plus" @click="createPath"/>
            </template>
        </Toolbar>

        <PathTable :path-list="paths" @refreshPathList="refresh()"/>
    </div>
</template>

<script>
    import {
        getPathwayListUsingGET as getPathwayList,
    } from "@/swagger/vue-api-client";
    import Button from "primevue/components/button/Button";
    import Toolbar from "primevue/components/toolbar/Toolbar";
    import {SystemRole} from "../../utils/SystemRole";
    import PathTable from "./components/PathTable";
    import {RouterUtils} from "../../utils/RouterUtils";

    export default {
        name: "PathListView",

        components: {
            PathTable, Button, Toolbar,
        },

        mixins: [RouterUtils],

        data() {
            return {
                paths: [],
                role: SystemRole,
            };
        },

        methods: {
            createPath() {
                this.pushAsync({name: "pathCreate"});
            },

            refresh() {
                getPathwayList().then((response) => {
                    this.paths = response.data.sort((item1, item2) => item1.sortOrder - item2.sortOrder);
                });
            },
        },

        created() {
            this.refresh();
        },
    };
</script>

<style scoped>

</style>
