<template>
    <div>
        <h3>{{title}}</h3>
        <ul class="thematic-paths-list">
            <li v-for="path in paths" v-bind:key="path.id">
                <i class="pi pi-arrow-right"></i>
                <router-link :to="{name: 'walk', params: {id: path.poiIds[0], mode: 'sciezka', pathId: path.id}}">
                    {{ path.langData[appLang].name }}
                    <span class="poi-count">
                        ({{ path.poiIds.length }}
                        {{
                            $t('label.poiPluralAbbr.' + numeralForm(path.poiIds.length, appLang))
                        }})
                    </span>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
    import {
        getDetailedPathListDataUsingGET as getDetailedPathListData,
    } from "@/swagger/vue-api-client";
    import {OfflineData} from "@/offline/OfflineData";
    import {TextUtils} from "@/utils/TextUtils";

    export default {
        name: "TemathicPaths",

        mixins: [TextUtils],

        computed: {
            title() {
                return this.appLang === "pl" ? "Ścieżki tematyczne" : "Thematic paths";
            },
        },

        data() {
            return {
                paths: null,
            };
        },

        created() {
            getDetailedPathListData().then((response) => {
                this.paths = response.data;
            }).catch(() => {
                this.paths = OfflineData.getDetailedPathListData();
            }).then(() => {
            });
        },

    };
</script>

<style lang="less">
    @import "../../../assets/theme/variable.less";
    .thematic-path-modal .vm--overlay {
        background-color: rgba(35, 31, 32, 0.82);
        z-index: 999;
    }
    .thematic-path-modal .vm--modal {
        background-color: transparent;
        box-shadow: none;
        color: @white-bg;
        z-index: 999;
    }
    .pi-arrow-right {
        margin-right: 26px;
    }
    .thematic-paths-list {
        li a {
            color: @white-bg;
            text-decoration: none;
            transition: color 0.4s;
            -web-transition: color 0.4s;
            -moz-transition: color 0.4s;
            -o-transition: color 0.4s;
            &:hover {
                color: @orange-light;
                span {
                    color: @orange-light;
                }
            }
        }
        li {
            text-align: left;
            margin-bottom: 14px;
            display: flex;
            align-items: center;
        }
        .poi-count {
            font-weight: 300;
            font-size: 14px;
            color: @white-bg;
            transition: color 0.4s;
            -web-transition: color 0.4s;
            -moz-transition: color 0.4s;
            -o-transition: color 0.4s;
            white-space: nowrap;
        }
    }
</style>
