/* eslint-disable */
import {GeometryUtils} from "@/utils/GeometryUtils";
import PairOfCoordinates from "@/utils/GeographicalUtils";
import {HTMLUtils} from "@/utils/HTMLUtils";

export default class PanoramaHotspot {

    startPoi;
    poi;
    context;
    mode;
    neighbourAdditionalData;

    pairOfCoordinates;
    distanceFlat;
    distance3d;
    elevationDifference;

    _relativePositionOnPath = null;
    _pathId = null;
    _lastPoiFromPathId = null;
    _backToPath = null;



    constructor(startPoi, poi, neighbourAdditionalData, context) {
        this.startPoi = startPoi;
        this.poi = poi;
        this.neighbourAdditionalData = neighbourAdditionalData;
        this.context = context;
        this.mode = context.mode;
        this.calculateProperties();
    }

    set relativePositionOnPath(value) {
        this._relativePositionOnPath = value;
    }

    set pathId(value) {
        this._pathId = value;
    }

    set lastPoiFromPathId(value) {
        this._lastPoiFromPathId = value;
    }

    set backToPath(value) {
        this._backToPath = value;
    }

    calculateProperties() {
        this.pairOfCoordinates = new PairOfCoordinates(
            {lat: this.startPoi.lat, lng: this.startPoi.lng},
            {lat: this.poi.lat, lng: this.poi.lng},
        );
        this.calculateDistances();
        this.calculateDirections();
        this.calculatePitch();
        this.calculateYaw();
        this.calculateTargetDirections();
    }

    calculateDistances() {
        this.distanceFlat = Math.round(this.pairOfCoordinates.distance());
        this.elevationDifference = typeof this.startPoi.panoramas[0] === "undefined" || typeof this.poi.panoramas[0] === "undefined"
            ? 0
            : this.poi.panoramas[0].elevation - this.startPoi.panoramas[0].elevation;
        this.distance3d = GeometryUtils.correctedDistance(this.distanceFlat, this.elevationDifference);
    }

    calculateDirections() {
        this.direction = Math.round(this.pairOfCoordinates.direction());
        this.computedYaw = typeof this.startPoi.panoramas[0] !== "undefined"
            ? this.direction + this.startPoi.panoramas[0].northOffset
            : this.direction;
    }

    calculatePitch() {
        this.computedPitch = this.calculateOriginalPitch(this.distanceFlat, this.elevationDifference);
        this.pitch = this.neighbourAdditionalData.pitch !== null
            ? this.neighbourAdditionalData.pitch
            : this.computedPitch;
    }

    calculateYaw() {
        this.yaw = this.neighbourAdditionalData.yaw !== null
            ? this.neighbourAdditionalData.yaw
            : this.computedYaw;
    }

    calculateOriginalPitch(distance, elevationDifference) {
        if (Math.abs(elevationDifference) < 1.5 || elevationDifference > 10) {
            return 1 - 300 / (distance + 30);
        }
        return GeometryUtils.pitch(distance, elevationDifference);
    }

    calculateTargetDirections() {
        const targetPanorama = this.poi.panoramas[0];
        this.targetPitch = targetPanorama.defaultPitch !== null ? targetPanorama.defaultPitch : 0;
        this.targetYaw = targetPanorama.defaultYaw !== null ? targetPanorama.defaultYaw : this.computedYaw;
        this.targetHfov = PanoramaHotspot.defaultHFOV(targetPanorama.hfov, this.context.isMobile());
    }

    getHotspotObject() {
        return {
            pitch: this.pitch,
            yaw: this.yaw,
            type: "scene",
            cssClass: this.generateClasses().join(" "),
            // text: this.generateDescription(),
            targetPitch: this.targetPitch,
            targetYaw: this.targetYaw,
            targetHfov: this.targetHfov,
            // sceneId: "poi-" + this.poi.id,
            clickHandlerFunc: this.context.hotspotClick,
            clickHandlerArgs: this.routeParams(),
            createTooltipFunc: this.createHotspot,
            createTooltipArgs: this,
        };
    }

    createHotspot(hotspotDiv, hotspotObject) {
        hotspotDiv.innerHTML = "";
        for (const lang in hotspotObject.poi.langData) {
            hotspotDiv.innerHTML += "<div data-lang='" + lang + "' style='display: none' class='"
                + (lang === hotspotObject.context.appLang ? "tooltip-content" : "")
                + "'>"
                + hotspotObject.generateName(lang)
                + hotspotObject.generateDescription()
                + "</div>";
        }
        // hotspotDiv.innerHTML = "<div class='tooltip-content' style='display: none'>test tooltip content</div>";
        hotspotDiv.addEventListener("mouseenter", HTMLUtils.methods.showTooltip);
        hotspotDiv.addEventListener("mouseleave", HTMLUtils.methods.hideTooltip);
    }

    routeParams() {
        let params = {
            id: this.poi.id,
        };
        if (this._pathId !== null && this._lastPoiFromPathId === null) {
            params.mode = "sciezka";
            params.pathId = this._pathId;
        }
        // if (this._lastPoiFromPathId !== null) {
        //     params.lastPoiFromPath = this._lastPoiFromPathId;
        // }
        return params;
    }

    generateName(lang) {
        if (this.poi.areaId !== this.startPoi.areaId) {
            return this.neighbourAdditionalData.area.langData[lang].name;
        }
        return this.poi.langData[lang].name;
    }

    generateDescription() {
        return "<div class='hotspot-distance'>" + this.generateAdditionalDescription() + "</div>";
    }

    generateAdditionalDescription() {
        return Math.round(this.distance3d) + " m"
            + (this.mode === "dev"
                ? " / " + this.elevationDifference + " m, "
                + this.direction + "° / "
                + this.computedYaw + "° / "
                + Math.round(this.pitch) + "°"
                + "<br/>id: " + this.poi.id
                : ""
            );
    }

    generateClasses() {
        const cssClasses = ["hotspot"];
        if (this.isFromOtherArea()) {
            cssClasses.push("different-area-poi");
            this.addRotationClass(cssClasses);
        } else if (this.isFromDrone()) {
            cssClasses.push("aerial-view-poi");
        } else if (this.isOnPath()) {
            cssClasses.push("path-poi");
            this.addRotationClass(cssClasses);
        } else {
            cssClasses.push("neighbour-poi");
            this.addRotationClass(cssClasses);
        }
        cssClasses.push("poi-" + this.poi.id);
        return cssClasses;
    }

    addRotationClass(cssClasses) {
        let angle;
        if (!this.isManuallyCorrected()) {
            return;
        }
        if (this.neighbourAdditionalData.arrowAngle) {
            angle = this.roundAngle(this.neighbourAdditionalData.arrowAngle);
        } else {
            let computedAngle = this.calculateArrowAngle();
            angle = this.recalculateAndRoundAngle(computedAngle);
        }
        cssClasses.push("rotate-" + angle);
    }

    calculateArrowAngle() {
        const p1 = {x: this.yaw, y: this.pitch};
        let p2 = {x: this.computedYaw, y: this.computedPitch};
        if (p2.x - p1.x > 180) {
            p2.x -= 360;
        }
        if (p2.x - p1.x < -180) {
            p2.x += 360;
        }
        return GeometryUtils.angleBetweenTwoPointsOnSphere(p1, p2);
    }

    recalculateAndRoundAngle(originalAngle) {
        let angle = (originalAngle + 90);
        if (angle < 0) {
            angle += 360;
        }
        return this.roundAngle(angle);
    }

    roundAngle(angle) {
        return 15 * Math.round(angle / 15);
    }

    static defaultHFOV(hfovFromPanorama, isMobile) {
        let hfov;
        if (isMobile) {
            hfov = 40;
        } else if (this.mode === "without-controls") {
            hfov = 100;
        } else {
            hfov = 120;
        }
        if (hfovFromPanorama < hfov) {
            return hfovFromPanorama;
        }
        return hfov;
    }

    isFromDrone() {
        return this.elevationDifference > 10;
    }

    isFromOtherArea() {
        return this.poi.areaId !== this.startPoi.areaId;
    }

    isManuallyCorrected() {
        return this.neighbourAdditionalData.pitch !== null;
    }

    isOnPath() {
        return this._relativePositionOnPath !== null;
    }
}
