<template>
    <div id="map-view">
        <div id="tooltip" style="position: absolute; display: none;"></div>
        <div v-dragscroll class="map-container resizable-container" style="position: relative">
            <div class="map-heading">
                <div class="main-container-heading">
                    <div class="main-container-heading-inner">
                        <router-link :to="{name: 'public'}" class="home" :title="titleBackToHome">
                            <i class="pi pi-home"></i>
                            <span class="visually-hidden">{{ $t('label.mainPage') }}</span>
                        </router-link>
                        <router-link :to="{name: 'areaView', params: {id: areaData.parentId}}"
                                     v-if="areaData.parentId"
                                     :title="titleBack">
                            <div class="center-con">
                                <div class="round">
                                    <div id="cta">
                                        <span class="arrow primera next ">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="24" height="24" viewBox="0 0 24 24">
                                                <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/>
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                        <div class="heading-toolbar-mobile">
                            <AccessibilityToolbar/>
                            <CustomSimpleChangeLanguage/>
                        </div>
                    </div>
                    <h1 v-if="title">{{ title }}</h1>
                </div>
                <div class="lang-back">
                    <AccessibilityToolbar/>
                    <CustomSimpleChangeLanguage/>
                </div>
            </div>
            <img :src="mapParams.imageUrl" alt="" class="resizable"/>
            <svg id="map"
                 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                 :viewBox="'0 0 ' + mapParams.width + ' ' + mapParams.height"
                 :data-marker-margin="mapParams.markerMargin"
                 style='position: absolute; top: 0; left: 0;' alt="">
                <image :xlink:href="mapParams.imageUrl"
                       :width="mapParams.width" :height="mapParams.height"/>
                <rect class="image-cover" :width="mapParams.width" :height="mapParams.height"></rect>
                <CustomLinkedMarker v-for="marker in markers" v-bind:key="marker.id + $i18n.locale"
                                    :point="marker"
                                    :tooltip="getMarkerTitle(marker)"
                                    :classes="'panorama' + (getCurrentPoiId() === marker.id ? ' current-poi' : '')"
                                    :marker-id="marker.id"
                />
                <g v-if="typeof debug.points !== 'undefined'">
                    <circle v-for="point in debug.controlPoints" v-bind:key="point.x"
                            :cx="point.x" :cy="point.y" r="10" class="control-point"
                    />
                    <CustomLine :from="debug.points.Ap" :to="debug.points.Cp" classes="primary"/>
                    <CustomLine :from="debug.points.Bp" :to="debug.points.Dp" classes="primary"/>
                    <CustomLine :from="debug.points.Mp" :to="debug.points.Xp" classes="secondary"/>
                    <CustomLine :from="debug.points.Np" :to="debug.points.Xp" classes="secondary"/>
                    <CustomLine :from="debug.points.Mp" :to="debug.points.Kp" classes="tertiary"/>
                    <CustomLine :from="debug.points.Mp" :to="debug.points.Lp" classes="tertiary"/>
                    <CustomLine :from="{x: mapParams.width / 2, y: mapParams.height / 2}"
                                :to="debug.points.Xp" classes="from-central-point"/>
                    <rect :x="mapParams.markerMargin" :y="mapParams.markerMargin"
                          :width="mapParams.width - 2 * mapParams.markerMargin"
                          :height="mapParams.height - 2 * mapParams.markerMargin"
                          class="marker-margins"
                    />
                </g>
            </svg>
        </div>
        <div class="map-legend" :class="{ mapLegenglangEn: appLang !== 'pl'}">
            <div class="map-legend-title">{{legendTitle}}</div>
            <div class="map-legend-list">
                <ul>
                    <li><span class="circle circle-panoramas"></span><span>{{legendPanoramas}}</span></li>
                    <li><span class="circle circle-you-are-here"></span><span>{{legendYouAreHere}}</span></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getDetailedAreaListDataUsingGET as getDetailedAreaListData,
    } from "@/swagger/vue-api-client";
    import MapProjection from "@/utils/MapProjection";
    import Point, {GeometryUtils} from "@/utils/GeometryUtils";
    import CustomLine from "@/components/svg/CustomLine";
    import CustomLinkedMarker from "@/components/svg/CustomLinkedMarker";
    import CustomSimpleChangeLanguage from "@/components/CustomSimpleChangeLanguage";
    import AccessibilityToolbar from "@/components/AccessibilityToolbar";
    import {HTMLUtils} from "@/utils/HTMLUtils";
    import {dragscroll} from "vue-dragscroll";
    import {OfflineData} from "@/offline/OfflineData";

    export default {
        name: "MapView",
        directives: {
            dragscroll,
        },

        mixins: [HTMLUtils],

        components: {
            CustomLine,
            CustomLinkedMarker,
            CustomSimpleChangeLanguage,
            AccessibilityToolbar,
        },

        data() {
            return {
                mapParams: {
                    markerMargin: 50,
                    width: 4000,
                    height: 3000,
                },
                poiList: [],
                controlPointPairs: [],
                mapProjection: {},
                markers: [],
                debug: {},
                indexedAreaListData: {},
                areaData: {},
                mode: null,
                currentPoiId: null,
            };
        },

        watch: {
            // eslint-disable-next-line func-names
            "$route.params.id": function () {
                this.reloadData();
            },
        },
        created() {
            document.addEventListener("dragstart", (e) => { e.preventDefault(); });
            getDetailedAreaListData().then((response) => {
                this.indexAreaListData(response.data);
            }).catch(() => {
                this.indexAreaListData(OfflineData.getDetailedAreaListData());
            }).then(() => {
                this.reloadData();
                window.addEventListener("resize", this.setCircleSizes);
                if (this.$route.params.mode === "dev") {
                    this.debugPreprocessing(parseInt(this.$route.params.pointNumber, 10));
                }
            });
        },

        mounted() {
            window.addEventListener("resize", this.resizeAndCenterImages);
            window.addEventListener("resize", this.recalculateMarkerPositions);
            const resizables = document.querySelectorAll(".resizable");
            for (let i = 0; i < resizables.length; i += 1) {
                resizables[i].addEventListener("load", this.resizeAndCenterImages);
                resizables[i].addEventListener("load", this.recalculateMarkerPositions);
                resizables[i].addEventListener("load", this.setCircleSizes);
            }
            this.setCircleSizes();
        },

        destroyed() {
            window.removeEventListener("resize", this.resizeAndCenterImages);
            window.removeEventListener("resize", this.recalculateMarkerPositions);
            window.removeEventListener("resize", this.setCircleSizes);
        },

        computed: {
            title() {
                if (this.areaData && this.areaData.langData) {
                    return this.areaData.langData[this.appLang].name;
                }
                return "";
            },
            titleBack() {
                return this.appLang === "pl" ? "Powrót do nadobszaru" : "Back";
            },
            titleBackToHome() {
                return this.appLang === "pl" ? "Strona główna" : "Home page";
            },
            legendPanoramas() {
                return this.appLang === "pl" ? "Panoramy" : "Panoramas";
            },
            legendYouAreHere() {
                return this.appLang === "pl" ? "Jesteś tutaj" : "You are here";
            },
            legendTitle() {
                return this.appLang === "pl" ? "Legenda" : "Legend";
            },
        },
        methods: {
            reloadData() {
                this.mode = this.$route.params.mode;
                this.currentPoiId = this.$route.params.pointNumber;
                this.areaData = this.indexedAreaListData[this.$route.params.id];
                this.mapParams.imageUrl = this.staticFileUrl(this.areaData.maps[0].path);
                this.poiList = this.areaData.poiList;
                this.controlPointPairs = this.prepareControlPointPairs(this.areaData.maps[0].controlPoints);
                this.mapProjection = new MapProjection(this.controlPointPairs);
                this.poiPreprocessing();
            },

            getMarkerTitle(marker) {
                if (this.$i18n.locale === "en") {
                    return marker.titleEN;
                }
                return marker.titlePL;
            },

            indexAreaListData(flatList) {
                for (let i = 0; i < flatList.length; i += 1) {
                    this.indexedAreaListData[flatList[i].id] = flatList[i];
                }
            },

            poiPreprocessing() {
                for (let i = 0; i < this.poiList.length; i += 1) {
                    const poi = this.poiList[i];
                    if (poi.lat && poi.lng) {
                        const point = this.mapProjection.projection({x: poi.lat, y: poi.lng});
                        this.markers.push({
                            x: point.x,
                            y: point.y,
                            number: poi.number,
                            id: poi.id,
                            titleEN: poi.langData.en.name,
                            titlePL: poi.langData.pl.name,
                            isSingular: point.isSingular,
                        });
                    }
                }
            },

            debugPreprocessing(poiId) {
                this.debug.controlPoints = [];
                for (let i = 0; i < this.controlPointPairs.length; i += 1) {
                    const point = this.controlPointPairs[i][1];
                    this.debug.controlPoints.push({x: point.x, y: point.y});
                }
                const sourcePoint = this.findPoiById(poiId);
                const projection = this.mapProjection.projection({x: sourcePoint.lat, y: sourcePoint.lng}, true);
                this.debug.points = projection.points;
                this.debug.points.Xp = {x: projection.x, y: projection.y};
            },

            findPoiById(poiId) {
                return this.poiList.find(poi => poi.id.toString() === poiId.toString());
            },

            getCurrentPoiId() {
                return this.currentPoiId ? parseInt(this.currentPoiId, 10) : this.poiList[0].id;
            },

            prepareControlPointPairs(rawData) {
                const controlPointPairs = [];
                for (let i = 0; i < rawData.length; i += 1) {
                    const pointData = rawData[i];
                    controlPointPairs.push([
                        new Point(pointData.lat, pointData.lng),
                        new Point(pointData.x, pointData.y),
                    ]);
                }
                return controlPointPairs;
            },

            setCircleSizes() {
                const mapContainers = document.querySelectorAll(".map-container");
                for (let i = 0; i < mapContainers.length; i += 1) {
                    const mapContainer = mapContainers[i];
                    this.setCircleSizesInMap(mapContainer);
                }
            },

            setCircleSizesInMap(mapContainer) {
                const size = this.calculateCircleSize(mapContainer);
                const circles = mapContainer.querySelectorAll("circle");
                for (let i = 0; i < circles.length; i += 1) {
                    const circle = circles[i];
                    if (circle.classList.contains("panorama")) {
                        this.setMarkerSize(circle.parentNode, size, 20);
                    } else if (circle.classList.contains("control-point")) {
                        circle.setAttribute("r", size / 4);
                    }
                }
                const map = mapContainer.querySelector("svg");
                map.dataset.markerMargin = (5 * size).toString();
            },

            setMarkerSize(marker, size, defaultSize) {
                const ratio = size / defaultSize;
                const circle = marker.querySelector("circle");
                circle.setAttribute("r", size);
                const scaleGroup = marker.querySelector("g.scale-group");
                scaleGroup.setAttribute(
                    "transform",
                    "scale(" + ratio + ")",
                );
            },

            calculateCircleSize(mapContainer) {
                const {width} = mapContainer.getBoundingClientRect();
                const thresholds = [
                    {x: 440, y: 30},
                    {x: 1120, y: 20},
                ];
                // const biggerDimension = Math.max(width, height);
                // const area = width * height;
                const dimension = width;
                const factor = this.isMobile() ? 2 : 1;
                if (dimension < thresholds[0].x) {
                    return thresholds[0].y * factor;
                }
                if (dimension > thresholds[1].x) {
                    return thresholds[1].y * factor;
                }
                const linearFunction = GeometryUtils.linearFunctionBetweenTwoPoints(
                    thresholds[0],
                    thresholds[1],
                );
                return linearFunction(dimension) * factor;
            },

            recalculateMarkerPositions() {
                // eslint-disable-next-line no-constant-condition
                const map = document.getElementById("map");
                const mapDimensions = this.getMapDimensions(map);
                const margin = parseInt(map.dataset.markerMargin, 10);
                const markers = map.querySelectorAll(".hotspot");
                for (let i = 0; i < markers.length; i += 1) {
                    this.checkAndCorrectMarker(markers[i], mapDimensions, margin);
                }
            },

            checkAndCorrectMarker(marker, mapDimensions, margin) {
                const markerPoint = this.getMarkerOriginalCoordinates(marker, mapDimensions);
                if (this.isMarkerOutsideMap(markerPoint, mapDimensions, margin)) {
                    const intersection = this.calculateMarkerCorrectedPosition(markerPoint, mapDimensions, margin);
                    this.changeMarkerPosition(marker, intersection);
                    this.showDirectionArrow(marker);
                } else {
                    this.resetMarkerPosition(marker);
                }
            },

            showDirectionArrow(marker) {
                const group = marker.parentNode.querySelector("g.direction-arrow");
                const rotationGroup = group.querySelector("g.rotation-group");
                group.setAttribute(
                    "transform",
                    "translate(" + marker.getAttribute("cx") + ", " + marker.getAttribute("cy") + ")",
                );
                rotationGroup.setAttribute(
                    "transform",
                    "rotate(" + -marker.dataset.directionFromCentralPoint + ")",
                );
                group.classList.add("visible");
            },

            resetMarkerPosition(marker) {
                marker.setAttribute("cx", marker.dataset.originalX);
                marker.setAttribute("cy", marker.dataset.originalY);
            },

            changeMarkerPosition(marker, newPosition) {
                if (newPosition === null) {
                    return;
                }
                marker.setAttribute("cx", newPosition.x);
                marker.setAttribute("cy", newPosition.y);
                marker.dataset.directionFromCentralPoint = newPosition.angleFromCentralPoint;
            },

            getMarkerOriginalCoordinates(marker, mapDimensions) {
                const point = new Point(
                    marker.dataset.originalX,
                    marker.dataset.originalY,
                );
                if (!marker.dataset.isSingular) {
                    return point;
                }
                const centralPoint = this.centralPoint(mapDimensions);
                const direction = GeometryUtils.directionToPoint(point, centralPoint);
                const correctedFakePoint = GeometryUtils.pointInInfinityWithDirection(direction).plus(centralPoint);
                correctedFakePoint.angleFromCentralPoint = direction;
                return correctedFakePoint;
            },

            centralPoint(mapDimensions) {
                return new Point(
                    mapDimensions.x / 2,
                    mapDimensions.y / 2,
                );
            },

            calculateMarkerCorrectedPosition(markerPoint, mapDimensions, margin) {
                const mapCentralPoint = this.centralPoint(mapDimensions);
                const edges = this.getMarginEdges(mapDimensions, margin);
                return this.findProperIntersection(markerPoint, mapCentralPoint, edges);
            },

            findProperIntersection(markerPoint, mapCentralPoint, edges) {
                for (let i = 0; i < edges.length; i += 1) {
                    const edge = edges[i];
                    const intersection = GeometryUtils.checkLineIntersection(
                        mapCentralPoint,
                        markerPoint,
                        edge[0],
                        edge[1],
                    );
                    if (intersection.onLine1 && intersection.onLine2) {
                        // eslint-disable-next-line max-len
                        intersection.angleFromCentralPoint = GeometryUtils.directionToPoint(mapCentralPoint, markerPoint);
                        return intersection;
                    }
                }
                return null;
            },

            getMarginEdges(mapDimensions, margin) {
                const marginPositions = [
                    [margin, mapDimensions.x - margin],
                    [margin, mapDimensions.y - margin],
                ];
                const pairIndices = [
                    [0, 0],
                    [0, 1],
                    [1, 1],
                    [1, 0],
                ];
                const corners = [];
                for (let i = 0; i < pairIndices.length; i += 1) {
                    corners.push(new Point(
                        marginPositions[0][pairIndices[i][0]],
                        marginPositions[1][pairIndices[i][1]],
                    ));
                }
                const cornerPairs = [];
                for (let i = 0; i < 4; i += 1) {
                    cornerPairs.push([
                        corners[i % 4],
                        corners[(i + 1) % 4],
                    ]);
                }
                return cornerPairs;
            },

            isMarkerOutsideMap(markerPoint, mapDimensions, margin) {
                const {x, y} = markerPoint;
                return (x < margin || x > mapDimensions.x - margin
                    || y < margin || y > mapDimensions.y - margin);
            },

            getMapDimensions(map) {
                const unparsedDimensions = map.getAttribute("viewBox");
                const matches = unparsedDimensions.match(/(\d+)/g);
                return {
                    x: parseInt(matches[2], 10),
                    y: parseInt(matches[3], 10),
                };
            },
        },
    };
</script>

<style lang="less">
    @import "../../assets/theme/variable.less";

    svg.map {
        width: 100%;
    }

    svg .hotspot {
        stroke-width: 2;
        fill-opacity: 50%;
    }

    svg .hotspot.control-point {
        stroke: black;
        fill: black;
        fill-opacity: 0;
    }

    svg circle {
        //stroke: red;
        //fill: white;
    }

    svg line {
        stroke: white;
        stroke-width: 2px;
    }

    svg line.primary {
        stroke: yellow;
    }
    svg line.secondary {
        stroke: orange;
    }
    svg line.tertiary {
        stroke: red;
    }
    svg line.from-central-point {
        stroke: blue;
    }

    svg rect.image-cover {
        fill: none;
        //fill: white;
        //fill-opacity: 0.13;
    }
    svg rect.marker-margins {
        fill: none;
        stroke: black;
    }

    #map-view #tooltip {
        background: #ff9100;
        color: white;
        border-radius: 5px;
        border: none;
        padding: 5px;
        opacity: 0.8;
        z-index: 100;
    }
    .map-container {
        overflow: auto;
        cursor: default;
        pointer-events: auto;
        opacity: 1;
        width: 100vw;
        height: 100vh;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        #map {
            a:focus {
                outline: 4px solid #000;
            }
        }
        .main-container-heading {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .main-container-heading-inner {
                display: flex;
                align-items: center;
            }
            .pi-arrow-left,
            .pi-home,
            .pi-table {
                // color: #534b41;
                color: @brown-light-2;
                font-size: 14px;
                margin-right: 20px;
                position: relative;
                &:after {
                    content: '';
                    display: block;
                    width: 1px;
                    position: absolute;
                    top: -21%;
                    right: -10px;
                    bottom: -21%;
                    background-color: @brown-light;
                }
            }
        }
        .map-heading {
            position: fixed;
            right: 0;
            left: 0;
            z-index: 2;
            display: flex;
            justify-content: space-between;
            background-color: rgba(35, 31, 32, 0.82);
            color: rgb(254, 254, 254);
            padding: 10px 15px;
            align-items: center;
            &:before {
                // content: '';
                position: absolute;
                left: 0;
                top: 0;
                background: url('../../assets/shadow_tour.png') repeat-x left top;
                width: 100%;
                padding: 50px 0 25px 0;
                font-size: 13px;
                transform: rotate(180deg);
                z-index: -1;
                height: 100%;
                background-size: contain;
            }
            h1 {
                margin: 0;
                position: relative;
                font-weight: 400;
                font-size: 24px;
                text-align: left;
                color: #fefefe;

            }
            .lang-back {
                display: flex;
                align-items: center;
                .pi-arrow-left {
                    color: #fefefe;
                    position: relative;
                    font-weight: 400;
                    font-size: 16px;
                    z-index: 2;
                    cursor: pointer;
                }
            }
        }
    }
    .map-container::-webkit-scrollbar {
        display: none;
    }
    @media screen and (max-width: 767px) {
        .map-container {
            .map-heading {
                padding: 10px;
                flex-direction: column;
                align-items: flex-start;
                h1 {
                    font-size: 20px;
                    margin-top: 7px;
                }
            }
            .map-heading .lang-back {
                padding-top: 7px;
                display: none;
            }
            .main-container-heading {
                display: block;
                width: 100%;
            }
            .main-container-heading-inner {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    width: 100%;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
            }
        }
    }
    .map-legend {
        position: fixed;
        bottom: 20px;
        right: -133px;
        width: 161px;
        // background-color: #fefefe;
        background-color: rgba(35, 31, 32, 0.82);
        z-index: 2;
        display: flex;
        align-items: initial;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
        &.mapLegenglangEn {
            right: -151px;
            width: 180px;
        }
        .map-legend-title {
            writing-mode: tb-rl;
            transform: rotate(-180deg);
            // background-color: rgba(35, 31, 32, 0.82);
            color: #fff;
            text-transform: uppercase;
            padding: 5px;
            letter-spacing: 1px;
            border-left: 1px solid #eac587;
        }
        .map-legend-list {
            padding: 15px 10px;
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
            }
            .circle-panoramas {
                background-color: #fff;
                width: 13px;
                height: 13px;
                border-radius: 50%;
                border: 2px solid #ff9100;
            }
            .circle {
                display: block;
                margin-right: 10px;
            }
            li {
                display: flex;
                color: #fefefe;
                align-items: center;
                margin-bottom: 10px
            }
            .circle-you-are-here {
                background-color: #ff9100;
                width: 13px;
                height: 13px;
                border-radius: 50%;
            }
        }
        &:hover {
            right: 0;
        }
    }
</style>
