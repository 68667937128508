<template>
    <div style="height: 100vh;">
        <l-map
            ref="map"
            :zoom="zoom"
            :center="center"
            :max-zoom="maxZoom"
            @update:zoom="zoomUpdated"
            @update:center="centerUpdated"
            @update:bounds="boundsUpdated"
        >
            <l-tile-layer :url="url"></l-tile-layer>
            <l-circle-marker v-for="poi in pois" :key="poi.id"
                             :lat-lng="[poi.lat, poi.lng]" :radius="5" :color="getColor(poi.areaId)"
                             @click="openURL('/#/spacer/' + poi.id + '/dev')">
                <l-tooltip>
                    nr: {{ poi.number }},
                    id: {{ poi.id }}
                </l-tooltip>
            </l-circle-marker>
        </l-map>
    </div>
</template>

<script>
    import {
        getDetailedPOIListDataUsingGET as getDetailedPOIListData,
    } from "@/swagger/vue-api-client";
    import {CRS, Icon} from "leaflet";
    import {
        LMap, LTileLayer, LTooltip, LCircleMarker,
    } from "vue2-leaflet";
    import {HTMLUtils} from "@/utils/HTMLUtils";

    export default {
        name: "GlobalMapView",

        mixins: [HTMLUtils],

        components: {
            LMap,
            LTileLayer,
            LTooltip,
            LCircleMarker,
        },

        data() {
            return {
                // url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                // eslint-disable-next-line max-len
                url: "https://api.tiles.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw",
                zoom: 15,
                maxZoom: 22,
                center: [50.557342588379264, 17.57426261901856],
                bounds: null,
                id: "mapbox.satellite",
                crs: CRS.Earth,
                pois: {},
                colors: {},
            };
        },

        methods: {
            zoomUpdated(zoom) {
                this.zoom = zoom;
            },
            centerUpdated(center) {
                this.center = center;
            },
            boundsUpdated(bounds) {
                this.bounds = bounds;
            },
            openURL(url) {
                window.open(url);
            },

            generateColors() {
                for (let i = 0; i < 13; i += 1) {
                    this.colors[i] = this.generateRandomColor();
                }
            },

            generateRandomColor() {
                const lowerBound = 127;
                const subcolors = [];
                for (let i = 0; i < 3; i += 1) {
                    subcolors.push(this.generateRandomSubcolor(lowerBound));
                }
                const color = "rgb(" + subcolors.join(", ") + ")";
                return color;
            },

            generateRandomSubcolor(lowerBound) {
                return Math.floor(Math.random() * (255 - lowerBound) + lowerBound);
            },

            getColor(index) {
                return this.colors[index % 13];
            },
        },

        created() {
            this.generateColors();
            // eslint-disable-next-line no-underscore-dangle
            delete Icon.Default.prototype._getIconUrl;
            Icon.Default.mergeOptions({
                // eslint-disable-next-line global-require
                iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
                // eslint-disable-next-line global-require
                iconUrl: require("leaflet/dist/images/marker-icon.png"),
                // eslint-disable-next-line global-require
                shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
            });
            getDetailedPOIListData().then((response) => {
                this.pois = response.data;
            });
        },

        mounted() {
            this.map = this.$refs.map.mapObject;
            this.map.on("click", (e) => {
                this.copyToClipboard(e.latlng.lat + "," + e.latlng.lng);
            });
        },
    };
</script>

<style scoped>

</style>
