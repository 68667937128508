<template>
    <div>
        <DataTable ref="userTable" :value="users" :lazy="true" :paginator="true" :rows.sync="rows" removableSort
                   :totalRecords="totalRecords" :loading="loading" @page="onPage" @sort="onPage"
                   :rowsPerPageOptions="[5,10,20,50]">
            <Column field="firstName" :header="$t('label.firstName')" :sortable="true"></Column>
            <Column field="lastName" :header="$t('label.lastName')" :sortable="true"></Column>
            <Column field="email" :header="$t('label.email')" :sortable="true"></Column>
            <Column field="systemRole" :header="$t('label.systemRole')" headerStyle="width: 200px">
                <template #body="slotProps">
                    <CustomBadge label="Administrator" color="primary" v-show="slotProps.data.systemRole === 'ADMIN'"/>
                    <CustomBadge label="Użytkownik" color="tertiary" v-show="slotProps.data.systemRole === 'USER'"/>
                </template>
            </Column>
            <Column headerStyle="width: 135px" v-if="role.isAdmin()">
                <template #body="slotProps">
                    <router-link :to="{name: 'userEdit', params: {'id': slotProps.data.id}}">
                        <Button type="button" icon="pi pi-pencil" class="p-button-info p-mr-1"
                                v-tooltip.top="$t('button.edit')"></Button>
                    </router-link>
                    <Button type="button" icon="pi pi-trash" class="p-button-danger"
                            @click="openConfirmationDialog(slotProps.data.id)"
                            v-tooltip.top="$t('button.remove')"></Button>
                </template>
            </Column>
        </DataTable>
        <CustomDialog :show="displayConfirmation" :header="$t('dialog.userRemoval')"
                      :message="$t('dialog.userRemovalMessage') + ' ' + selectedUserName + '?'"
                      v-on:action="removeUser" v-on:close="closeConfirmationDialog"/>
    </div>
</template>

<script>
    import {
        searchUserUsingPOST as searchUser,
        removeUserUsingDELETE as removeUser,
        searchUserCountUsingPOST as searchUserCount,
    } from "@/swagger/vue-api-client";
    import {SystemRole} from "@/utils/SystemRole";
    import Button from "primevue/components/button/Button";
    import DataTable from "primevue/components/datatable/DataTable";
    import Column from "primevue/components/column/Column";
    import Tooltip from "primevue/tooltip";
    import CustomBadge from "../../../components/CustomBadge";
    import CustomDialog from "../../../components/form/CustomDialog";

    export default {
        name: "UserTable",
        components: {
            CustomBadge, Button, Column, DataTable, CustomDialog,
        },

        directives: {
            tooltip: Tooltip,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        data() {
            return {
                loading: false,
                rows: 5,
                totalRecords: 0,
                users: [],
                role: SystemRole,
                selectedUser: null,
                displayConfirmation: false,
            };
        },

        mounted() {
            this.refresh();
        },

        computed: {
            selectedUserName() {
                let name = "";
                if (this.selectedUser) {
                    this.users.forEach((user) => {
                        if (user.id === this.selectedUser) name = user.firstName + " " + user.lastName;
                    });
                }
                return name;
            },
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            onPage(event) {
                this.loading = true;

                this.searchCriteria.page.offset = event.first;
                this.searchCriteria.page.limit = event.rows;
                this.searchCriteria.page.sortField = event.sortField;
                this.searchCriteria.page.sortOrder = event.sortOrder;

                searchUser({searchCriteria: this.searchCriteria}).then((response) => {
                    this.users = response.data;
                    this.loading = false;
                });
            },

            updateTotalRecords() {
                searchUserCount({searchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            getFirstPage() {
                return {
                    first: 0,
                    rows: this.rows,
                };
            },

            removeUser() {
                removeUser({userId: this.selectedUser}).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.userRemoveSuccess"));
                    this.closeConfirmationDialog();
                    this.refresh();
                }).catch(() => {
                    this.$eventBus.$emit("error", this.$t("snackbar.userRemoveError"));
                    this.closeConfirmationDialog();
                });
            },

            openConfirmationDialog(userId) {
                this.selectedUser = userId;
                this.displayConfirmation = true;
            },

            closeConfirmationDialog() {
                this.selectedUser = null;
                this.displayConfirmation = false;
            },

            refresh() {
                this.updateTotalRecords();
                this.onPage(this.getFirstPage());
            },
        },

    };
</script>

<style scoped>

</style>
