import Vue from "vue";
import VueI18n from "vue-i18n";
import {messagesEN} from "@/messages/messages_en";
import {messagesPL} from "@/messages/messages_pl";
// import store from "./store";

Vue.use(VueI18n);


const messages = {
    en: messagesEN,
    pl: messagesPL,
};

// Create VueI18n instance with options
const i18n = new VueI18n({
    locale: "pl",
    messages,
});

// eslint-disable-next-line import/prefer-default-export
export {i18n};
