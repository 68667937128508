import Vue from "vue";
import VueRouter from "vue-router";
import QuizListView from "@/views/quiz/QuizListView";
import QuizFormView from "@/views/quiz/QuizFormView";
import QuizViewTemplate from "@/views/quiz/QuizViewTemplate";
import QuizQuestionFormView from "@/views/quiz/QuizQuestionFormView";
import QuizAnswerFormView from "@/views/quiz/QuizAnswerFormView";
import LayoutDashboard from "./views/layout/LayoutDashboard";
import LoginView from "./views/LoginView";
import UserListView from "./views/user/UserListView";
import UserFormView from "./views/user/UserFormView";
import {SystemRole} from "./utils/SystemRole";
import LayoutSimple from "./views/layout/LayoutSimple";
import ForbiddenView from "./views/ForbiddenView";
import NotFoundView from "./views/NotFoundView";
import ErrorView from "./views/ErrorView";
import PasswordRemindView from "./views/PasswordRemindView";
import ChangePasswordView from "./views/ChangePasswordView";
import LayoutPublic from "./views/layout/LayoutPublic";
import PublicView from "./views/public/PublicView";
import WalkView from "./views/public/WalkView";
import MapView from "./views/public/MapView";
import AreaView from "./views/public/AreaView";
import AreaListView from "./views/area/AreaListView";
import AreaFormView from "./views/area/AreaFormView";
import UserViewTemplate from "./views/user/UserViewTemplate";
import AreaViewTemplate from "./views/area/AreaViewTemplate";
import POIViewTemplate from "./views/poi/POIViewTemplate";
import POIListView from "./views/poi/POIListView";
import POIFormView from "./views/poi/POIFormView";
import POIEditNeighbourView from "./views/poi/POIEditNeighbourView";
import PanoramaFormView from "./views/poi/components/PanoramaFormView";
import AttachmentFormView from "./views/poi/components/AttachmentFormView";
import PathViewTemplate from "./views/path/PathViewTemplate";
import PathListView from "./views/path/PathListView";
import PathFormView from "./views/path/PathFormView";
import MapFormView from "./views/area/components/MapFormView";
import GlobalMapView from "./views/public/GlobalMapView";

Vue.use(VueRouter);

export default new VueRouter(
    {
        routes: [
        {
            path: "/home",
            name: "home",
            component: LayoutDashboard,
            meta: {requiresAuth: true},
            children: [
                {
                    path: "/user",
                    component: UserViewTemplate,
                    redirect: "/home/user/list",
                    children: [
                        {
                            path: "/user/list",
                            name: "userList",
                            component: UserListView,

                        },
                        {
                            path: "/user/create",
                            name: "userCreate",
                            component: UserFormView,
                            meta: {roles: [SystemRole.ADMIN]},
                        },
                        {
                            path: "/user/edit/:id",
                            name: "userEdit",
                            component: UserFormView,
                            meta: {roles: [SystemRole.ADMIN]},
                        },
                    ],
                },
                {
                    path: "/area",
                    component: AreaViewTemplate,
                    redirect: "/home/area/list",
                    children: [
                        {
                            path: "/area/list",
                            name: "areaList",
                            component: AreaListView,

                        },
                        {
                            path: "/area/create/:parentId?",
                            name: "areaCreate",
                            component: AreaFormView,
                            meta: {roles: [SystemRole.ADMIN]},
                        },
                        {
                            path: "/area/edit/:id",
                            name: "areaEdit",
                            component: AreaFormView,
                            meta: {roles: [SystemRole.ADMIN]},
                        },
                    ],
                },
                {
                    path: "/poi",
                    component: POIViewTemplate,
                    redirect: "/home/poi/list",
                    children: [
                        {
                            path: "/poi/list",
                            name: "poiList",
                            component: POIListView,
                        },
                        {
                            path: "/poi/create",
                            name: "poiCreate",
                            component: POIFormView,
                            meta: {roles: [SystemRole.ADMIN]},
                        },
                        {
                            path: "/poi/edit/:id",
                            name: "poiEdit",
                            component: POIFormView,
                            meta: {roles: [SystemRole.ADMIN]},
                        },
                        {
                            path: "/poi/edit/:id/neighbour/:neighbourId",
                            name: "poiEditNeighbour",
                            component: POIEditNeighbourView,
                            meta: {roles: [SystemRole.ADMIN]},
                        },
                    ],
                },
                {
                    path: "/path",
                    component: PathViewTemplate,
                    redirect: "/home/path/list",
                    children: [
                        {
                            path: "/path/list",
                            name: "pathList",
                            component: PathListView,
                        },
                        {
                            path: "/path/create",
                            name: "pathCreate",
                            component: PathFormView,
                            meta: {roles: [SystemRole.ADMIN]},
                        },
                        {
                            path: "/path/edit/:id",
                            name: "pathEdit",
                            component: PathFormView,
                            meta: {roles: [SystemRole.ADMIN]},
                        },
                    ],
                },
                {
                    path: "/panorama/:poiId/create",
                    name: "panoramaCreate",
                    component: PanoramaFormView,
                    meta: {roles: [SystemRole.ADMIN]},
                },
                {
                    path: "/panorama/edit/:id",
                    name: "panoramaEdit",
                    component: PanoramaFormView,
                    meta: {roles: [SystemRole.ADMIN]},
                },
                {
                    path: "/map/:areaId/create",
                    name: "mapCreate",
                    component: MapFormView,
                    meta: {roles: [SystemRole.ADMIN]},
                },
                {
                    path: "/map/edit/:id",
                    name: "mapEdit",
                    component: MapFormView,
                    meta: {roles: [SystemRole.ADMIN]},
                },
                {
                    path: "/attachment/:poiId/:type/create",
                    name: "attachmentCreate",
                    component: AttachmentFormView,
                    meta: {roles: [SystemRole.ADMIN]},
                },
                {
                    path: "/attachment/:type/edit/:id",
                    name: "attachmentEdit",
                    component: AttachmentFormView,
                    meta: {roles: [SystemRole.ADMIN]},
                },
                {
                    path: "/quiz",
                    component: QuizViewTemplate,
                    redirect: "/home/quiz/list",
                    meta: {roles: [SystemRole.ADMIN]},
                    children: [
                        {
                            path: "/quiz/list",
                            name: "quizList",
                            component: QuizListView,
                        },
                        {
                            path: "/quiz/create",
                            name: "quizCreate",
                            component: QuizFormView,
                        },
                        {
                            path: "/quiz/edit/:id",
                            name: "quizEdit",
                            component: QuizFormView,
                        },
                        {
                            path: "/quiz/:quizId/question/create/",
                            name: "quizQuestionCreate",
                            component: QuizQuestionFormView,
                        },
                        {
                            path: "/quiz/:quizId/question/edit/:id",
                            name: "quizQuestionEdit",
                            component: QuizQuestionFormView,
                        },
                        {
                            path: "/quiz/question/:questionId/answer/create/",
                            name: "quizAnswerCreate",
                            component: QuizAnswerFormView,
                        },
                        {
                            path: "/quiz/question/:questionId/answer/edit/:id",
                            name: "quizAnswerEdit",
                            component: QuizAnswerFormView,
                        },
                    ],
                },

            ],
        },
        {
            component: LayoutPublic,
            path: "/",
            children: [
                {
                    path: "/",
                    name: "public",
                    component: PublicView,
                },
                {
                    path: "/spacer/:id/:mode?/:pathId?/:lastPoiFromPath?",
                    name: "walk",
                    component: WalkView,
                },
                {
                    path: "/mapa/:id/:mode?/:pointNumber?",
                    name: "map",
                    component: MapView,
                },
                {
                    path: "/obszar/:id",
                    name: "areaView",
                    component: AreaView,
                },
                {
                    path: "/global-map",
                    name: "globalMap",
                    component: GlobalMapView,
                },
            ],
        },
        {
            name: "layoutSimple",
            component: LayoutSimple,
            path: "/",
            children: [
                {
                    path: "/login",
                    name: "login",
                    component: LoginView,
                },
                {
                    path: "/remind",
                    name: "remindPassword",
                    component: PasswordRemindView,
                },
                {
                    path: "/change-password",
                    name: "changePassword",
                    component: ChangePasswordView,
                },
                {
                    path: "/forbidden",
                    name: "forbidden",
                    component: ForbiddenView,
                },
                {
                    path: "/error",
                    name: "error",
                    component: ErrorView,
                },
                {
                    path: "/*",
                    name: "notFound",
                    component: NotFoundView,
                },
            ],
        },
    ],
},
);
