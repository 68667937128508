<template>
    <div>
        <h2 class="page-header"><i class="pi pi-image p-mr-3"></i>{{$t('menu.images.title')}}</h2>

        <Toolbar class="actions p-mt-5">
            <template slot="left">
                <Button v-if="role.isAdmin()" @click="$refs.file.click()" icon="pi pi-image"
                        :label="filePath || internalEncodedData ? $t('button.changeImage') : $t('button.addImage')"
                ></Button>
            </template>
        </Toolbar>

        <div class="image-preview p-mt-5 p-mb-5" v-if="internalEncodedData">
            <img class="preview" :src="internalEncodedData" alt="Zdjęcie" style="width: 70%; height: auto">
        </div>
        <div class="image-preview p-mt-5 p-mb-5" v-else-if="filePath">
            <img class="preview" :src="staticFileUrl(filePath)"
                 alt="Zdjęcie" style="width: 70%; height: auto">
        </div>

        <input type="file" ref="file" accept="image/png, image/jpeg" v-show="false" v-on:change="handleUpload">
    </div>
</template>

<script>
    import Button from "primevue/components/button/Button";
    import Toolbar from "primevue/components/toolbar/Toolbar";
    import {SystemRole} from "../../../utils/SystemRole";
    import {FileUtils} from "../../../utils/FileUtils";

    export default {
        name: "MapImageView",

        components: {
            Toolbar, Button,
        },

        mixins: [FileUtils],

        data() {
            return {
                role: SystemRole,
                internalFileName: null,
                internalEncodedData: null,
            };
        },

        methods: {
            async handleUpload() {
                const file = this.$refs.file.files[0];
                this.internalFileName = file.name;
                this.internalEncodedData = await this.fileToBase64(file);
                this.$emit("image-updated", this.internalFileName, this.filePath, this.internalEncodedData);
            },
        },

        watch: {
            fileName: {
                handler(val) {
                    this.internalFileName = val;
                },
            },

            encodedData: {
                handler(val) {
                    this.internalEncodedData = val;
                },
            },
        },

        props: {
            fileName: String,
            filePath: String,
            encodedData: String,
        },
    };
</script>

<style scoped>

</style>
