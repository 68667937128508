\<template>
    <div>
        <DataTable :value="POIs">
            <Column field="number" :header="$t('label.number')" sortable></Column>
            <Column field="plName" :header="$t('label.plName')" sortable></Column>
            <Column field="enName" :header="$t('label.enName')" sortable></Column>
            <Column field="area" :header="$t('label.area')"></Column>
            <Column :header="$t('label.action')" v-if="role.isAdmin()">
                <template #body="slotProps">
                    <router-link :to="{name: 'poiEdit', params: {'id': slotProps.data.id}}">
                        <Button type="button" icon="pi pi-pencil" class="p-button-info p-mr-1"
                                v-tooltip.top="$t('button.edit')"></Button>
                    </router-link>
                    <Button type="button" icon="pi pi-chevron-up" class="p-button-info p-mr-1"
                            v-if="slotProps.data.area" @click="increaseSortOrder(slotProps.data.id)"
                            v-tooltip.top="$t('button.changeOrder')"></Button>
                    <Button type="button" icon="pi pi-chevron-down" class="p-button-info p-mr-1"
                            v-if="slotProps.data.area" @click="decreaseSortOrder(slotProps.data.id)"
                            v-tooltip.top="$t('button.changeOrder')"></Button>
                    <Button type="button" icon="pi pi-trash" class="p-button-danger"
                            @click="openConfirmationDialog(slotProps.data.id)" v-tooltip.top="$t('button.remove')">
                    </Button>
                </template>
            </Column>
        </DataTable>
        <CustomDialog :show="displayConfirmation" :header="$t('dialog.poiRemoval')"
                      :message="$t('dialog.poiRemovalMessage') + ' ' + selectedPOIName + '? ' +
                          $t('dialog.poiRemovalWarning')"
                      v-on:action="removePOI" v-on:close="closeConfirmationDialog"/>
    </div>
</template>

<script>
    import {
        getPOIListUsingGET as getPOIList,
        deletePOIUsingDELETE as deletePoi,
        increasePOISortOrderUsingPUT as increaseSortOrder,
        decreasePOISortOrderUsingPUT as decreaseSortOrder,
    } from "@/swagger/vue-api-client";
    import Button from "primevue/components/button/Button";
    import DataTable from "primevue/components/datatable/DataTable";
    import Column from "primevue/components/column/Column";
    import {SystemRole} from "@/utils/SystemRole";
    import Tooltip from "primevue/tooltip";
    import CustomDialog from "../../../components/form/CustomDialog";

    export default {
        name: "POITable",

        components: {
            CustomDialog, Column, DataTable, Button,
        },

        directives: {
            tooltip: Tooltip,
        },

        data() {
            return {
                POIs: [],
                role: SystemRole,
                selectedPOI: null,
                displayConfirmation: false,
            };
        },

        created() {
            this.refresh();
        },

        computed: {
            selectedPOIName() {
                let name = "";
                if (this.selectedPOI) {
                    this.POIs.forEach((poi) => {
                        if (poi.id === this.selectedPOI) name = this.appLang === "pl" ? poi.plName : poi.enName;
                    });
                }
                return name;
            },
        },

        methods: {
            removePOI() {
                deletePoi({id: this.selectedPOI}).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.poiRemoveSuccess"));
                    this.closeConfirmationDialog();
                    this.refresh();
                });
            },

            increaseSortOrder(poiId) {
                increaseSortOrder({id: poiId}).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.poiOrderChangeSuccess"));
                    this.refresh();
                });
            },

            decreaseSortOrder(poiId) {
                decreaseSortOrder({id: poiId}).then(() => {
                    this.$eventBus.$emit("success", this.$t("snackbar.poiOrderChangeSuccess"));
                    this.refresh();
                });
            },

            openConfirmationDialog(poiId) {
                this.selectedPOI = poiId;
                this.displayConfirmation = true;
            },

            closeConfirmationDialog() {
                this.selectedPOI = null;
                this.displayConfirmation = false;
            },

            refresh() {
                getPOIList().then((response) => {
                    this.POIs = response.data.sort((item1, item2) => {
                        if (!item1.area) return 1;
                        return item1.area.localeCompare(item2.area) || item1.sortOrder - item2.sortOrder;
                    });
                });
            },
        },
    };
</script>

<style scoped>

</style>
