<template>
    <div>
        <Toast/>
        <h2 class="page-header"><i class="pi pi-microsoft p-mr-3"></i>{{headerTitle}}</h2>
        <ValidationObserver ref="formObserver">
            <div class="p-col-4">
                <CustomInputText name="plName" :label="$t('label.plName')"
                                 v-model="area.plName" rules="required"/>
                <CustomInputText name="enName" :label="$t('label.enName')"
                                 v-model="area.enName" rules="required"/>
                <CustomInputText name="slug" :label="$t('label.slug')" v-model="area.slug"/>
            </div>
        </ValidationObserver>

        <MapImageView class="p-mt-5 p-mb-5" v-if="$route.params.id" @image-updated="updateImage"
                      :filePath="area.filePath" :fileName="area.fileName" :encodedData="area.encodedData"/>

        <Toolbar class="actions p-ml-5 p-mt-5">
            <template slot="left">
                <Button v-if="role.isAdmin()" :label="$t('button.saveAreaData')"
                        icon="pi pi-save" @click="saveArea(true)"/>
            </template>
        </Toolbar>

        <MapListView class="p-mt-5" v-if="$route.params.id"/>
    </div>
</template>

<script>
    import {
        createOrUpdateAreaUsingPOST as createOrUpdateArea,
        getAreaUsingGET as getArea,
    } from "@/swagger/vue-api-client";
    import Button from "primevue/components/button/Button";
    import Toolbar from "primevue/components/toolbar/Toolbar";
    import Toast from "primevue/toast";
    import {ValidationObserver} from "vee-validate";
    import {SystemRole} from "../../utils/SystemRole";
    import {RouterUtils} from "../../utils/RouterUtils";
    import CustomInputText from "../../components/form/CustomInputText";
    import MapListView from "./components/MapListView";
    import MapImageView from "./components/MapImageView";

    export default {
        name: "AreaFormView",

        components: {
            MapListView, Button, Toolbar, CustomInputText, Toast, ValidationObserver, MapImageView,
        },

        mixins: [RouterUtils],

        data() {
            return {
                role: SystemRole,
                area: {
                    id: null,
                    parentId: null,
                    plName: null,
                    enName: null,
                    slug: null,
                    filePath: null,
                    fileName: null,
                    encodedData: null,
                },
                headerTitle: this.$t("menu.areas.add"),
            };
        },

        created() {
            if (this.$route.params.id) {
                this.headerTitle = this.$t("menu.areas.edit");
                getArea({id: this.$route.params.id}).then((response) => {
                    this.area = response.data;
                });
            }
            if (this.$route.params.parentId) {
                this.area.parentId = this.$route.params.parentId;
            }
        },

        methods: {
            saveArea(pageReturn) {
                this.$refs.formObserver.validate().then((valid) => {
                    if (!valid) {
                        return;
                    }
                    createOrUpdateArea({areaDto: this.area}).then((response) => {
                        if (pageReturn) this.pushAsync("/area/list");
                        else {
                            this.headerTitle = this.$t("menu.areas.edit");
                            getArea({id: response.data}).then((areaResponse) => {
                                this.area = areaResponse.data;
                            });
                            this.pushAsync("/area/edit/" + response.data);
                        }
                        this.$eventBus.$emit("success", this.$t("snackbar.areaAddSuccess"));
                    });
                });
            },

            updateImage(fileName, filePath, encodedData) {
                this.area.fileName = fileName;
                this.area.filePath = filePath;
                this.area.encodedData = encodedData;
            },
        },
    };
</script>

<style scoped>

</style>
