/* eslint-disable */
import axios from 'axios'
import qs from 'qs'
let domain = ''
export const getDomain = () => {
  return domain
}
export const setDomain = ($domain) => {
  domain = $domain
}
export const request = (method, url, body, queryParameters, form, config) => {
  method = method.toLowerCase()
  let keys = Object.keys(queryParameters)
  let queryUrl = url
  if (keys.length > 0) {
    queryUrl = url + '?' + qs.stringify(queryParameters)
  }
  // let queryUrl = url+(keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
  if (body) {
    return axios[method](queryUrl, body, config)
  } else if (method === 'get') {
    return axios[method](queryUrl, {
      params: form
    }, config)
  } else {
    return axios[method](queryUrl, qs.stringify(form), config)
  }
}
/*==========================================================
 *                    Api Documentation
 ==========================================================*/
/**
 * removeUser
 * request: removeUserUsingDELETE
 * url: removeUserUsingDELETEURL
 * method: removeUserUsingDELETE_TYPE
 * raw_url: removeUserUsingDELETE_RAW_URL
 * @param userId - userId
 */
export const removeUserUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['userId'] !== undefined) {
    queryParameters['userId'] = parameters['userId']
  }
  if (parameters['userId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: userId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const removeUserUsingDELETE_RAW_URL = function() {
  return '/'
}
export const removeUserUsingDELETE_TYPE = function() {
  return 'delete'
}
export const removeUserUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/'
  if (parameters['userId'] !== undefined) {
    queryParameters['userId'] = parameters['userId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateArea
 * request: createOrUpdateAreaUsingPOST
 * url: createOrUpdateAreaUsingPOSTURL
 * method: createOrUpdateAreaUsingPOST_TYPE
 * raw_url: createOrUpdateAreaUsingPOST_RAW_URL
 * @param areaDto - areaDTO
 */
export const createOrUpdateAreaUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/area'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['areaDto'] !== undefined) {
    body = parameters['areaDto']
  }
  if (parameters['areaDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: areaDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateAreaUsingPOST_RAW_URL = function() {
  return '/area'
}
export const createOrUpdateAreaUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateAreaUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/area'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getDetailedAreaListData
 * request: getDetailedAreaListDataUsingGET
 * url: getDetailedAreaListDataUsingGETURL
 * method: getDetailedAreaListDataUsingGET_TYPE
 * raw_url: getDetailedAreaListDataUsingGET_RAW_URL
 */
export const getDetailedAreaListDataUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/area/detailed-list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDetailedAreaListDataUsingGET_RAW_URL = function() {
  return '/area/detailed-list'
}
export const getDetailedAreaListDataUsingGET_TYPE = function() {
  return 'get'
}
export const getDetailedAreaListDataUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/area/detailed-list'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAreaList
 * request: getAreaListUsingGET
 * url: getAreaListUsingGETURL
 * method: getAreaListUsingGET_TYPE
 * raw_url: getAreaListUsingGET_RAW_URL
 */
export const getAreaListUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/area/list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAreaListUsingGET_RAW_URL = function() {
  return '/area/list'
}
export const getAreaListUsingGET_TYPE = function() {
  return 'get'
}
export const getAreaListUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/area/list'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * decreaseAreaSortOrder
 * request: decreaseAreaSortOrderUsingPUT
 * url: decreaseAreaSortOrderUsingPUTURL
 * method: decreaseAreaSortOrderUsingPUT_TYPE
 * raw_url: decreaseAreaSortOrderUsingPUT_RAW_URL
 * @param id - id
 */
export const decreaseAreaSortOrderUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/area/order-decrease/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const decreaseAreaSortOrderUsingPUT_RAW_URL = function() {
  return '/area/order-decrease/{id}'
}
export const decreaseAreaSortOrderUsingPUT_TYPE = function() {
  return 'put'
}
export const decreaseAreaSortOrderUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/area/order-decrease/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * increaseAreaSortOrder
 * request: increaseAreaSortOrderUsingPUT
 * url: increaseAreaSortOrderUsingPUTURL
 * method: increaseAreaSortOrderUsingPUT_TYPE
 * raw_url: increaseAreaSortOrderUsingPUT_RAW_URL
 * @param id - id
 */
export const increaseAreaSortOrderUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/area/order-increase/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const increaseAreaSortOrderUsingPUT_RAW_URL = function() {
  return '/area/order-increase/{id}'
}
export const increaseAreaSortOrderUsingPUT_TYPE = function() {
  return 'put'
}
export const increaseAreaSortOrderUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/area/order-increase/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getArea
 * request: getAreaUsingGET
 * url: getAreaUsingGETURL
 * method: getAreaUsingGET_TYPE
 * raw_url: getAreaUsingGET_RAW_URL
 * @param id - id
 */
export const getAreaUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/area/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAreaUsingGET_RAW_URL = function() {
  return '/area/{id}'
}
export const getAreaUsingGET_TYPE = function() {
  return 'get'
}
export const getAreaUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/area/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteArea
 * request: deleteAreaUsingDELETE
 * url: deleteAreaUsingDELETEURL
 * method: deleteAreaUsingDELETE_TYPE
 * raw_url: deleteAreaUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteAreaUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/area/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteAreaUsingDELETE_RAW_URL = function() {
  return '/area/{id}'
}
export const deleteAreaUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteAreaUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/area/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAttachment
 * request: getAttachmentUsingGET
 * url: getAttachmentUsingGETURL
 * method: getAttachmentUsingGET_TYPE
 * raw_url: getAttachmentUsingGET_RAW_URL
 * @param attachmentId - attachmentId
 */
export const getAttachmentUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/attachment'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['attachmentId'] !== undefined) {
    queryParameters['attachmentId'] = parameters['attachmentId']
  }
  if (parameters['attachmentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: attachmentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAttachmentUsingGET_RAW_URL = function() {
  return '/attachment'
}
export const getAttachmentUsingGET_TYPE = function() {
  return 'get'
}
export const getAttachmentUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/attachment'
  if (parameters['attachmentId'] !== undefined) {
    queryParameters['attachmentId'] = parameters['attachmentId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createAttachment
 * request: createAttachmentUsingPOST
 * url: createAttachmentUsingPOSTURL
 * method: createAttachmentUsingPOST_TYPE
 * raw_url: createAttachmentUsingPOST_RAW_URL
 * @param updateAttachmentDto - updateAttachmentDTO
 */
export const createAttachmentUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/attachment'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['updateAttachmentDto'] !== undefined) {
    body = parameters['updateAttachmentDto']
  }
  if (parameters['updateAttachmentDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: updateAttachmentDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createAttachmentUsingPOST_RAW_URL = function() {
  return '/attachment'
}
export const createAttachmentUsingPOST_TYPE = function() {
  return 'post'
}
export const createAttachmentUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/attachment'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * editAttachment
 * request: editAttachmentUsingPUT
 * url: editAttachmentUsingPUTURL
 * method: editAttachmentUsingPUT_TYPE
 * raw_url: editAttachmentUsingPUT_RAW_URL
 * @param updateAttachmentDto - updateAttachmentDTO
 */
export const editAttachmentUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/attachment'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['updateAttachmentDto'] !== undefined) {
    body = parameters['updateAttachmentDto']
  }
  if (parameters['updateAttachmentDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: updateAttachmentDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const editAttachmentUsingPUT_RAW_URL = function() {
  return '/attachment'
}
export const editAttachmentUsingPUT_TYPE = function() {
  return 'put'
}
export const editAttachmentUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/attachment'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * removeAttachment
 * request: removeAttachmentUsingDELETE
 * url: removeAttachmentUsingDELETEURL
 * method: removeAttachmentUsingDELETE_TYPE
 * raw_url: removeAttachmentUsingDELETE_RAW_URL
 * @param attachmentId - attachmentId
 */
export const removeAttachmentUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/attachment'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['attachmentId'] !== undefined) {
    queryParameters['attachmentId'] = parameters['attachmentId']
  }
  if (parameters['attachmentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: attachmentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const removeAttachmentUsingDELETE_RAW_URL = function() {
  return '/attachment'
}
export const removeAttachmentUsingDELETE_TYPE = function() {
  return 'delete'
}
export const removeAttachmentUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/attachment'
  if (parameters['attachmentId'] !== undefined) {
    queryParameters['attachmentId'] = parameters['attachmentId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllAttachments
 * request: getAllAttachmentsUsingGET
 * url: getAllAttachmentsUsingGETURL
 * method: getAllAttachmentsUsingGET_TYPE
 * raw_url: getAllAttachmentsUsingGET_RAW_URL
 * @param attachmentType - attachmentType
 * @param poiId - poiId
 */
export const getAllAttachmentsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/attachment/list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['attachmentType'] !== undefined) {
    queryParameters['attachmentType'] = parameters['attachmentType']
  }
  if (parameters['attachmentType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: attachmentType'))
  }
  if (parameters['poiId'] !== undefined) {
    queryParameters['poiId'] = parameters['poiId']
  }
  if (parameters['poiId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: poiId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllAttachmentsUsingGET_RAW_URL = function() {
  return '/attachment/list'
}
export const getAllAttachmentsUsingGET_TYPE = function() {
  return 'get'
}
export const getAllAttachmentsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/attachment/list'
  if (parameters['attachmentType'] !== undefined) {
    queryParameters['attachmentType'] = parameters['attachmentType']
  }
  if (parameters['poiId'] !== undefined) {
    queryParameters['poiId'] = parameters['poiId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * decreaseAttachmentSortOrder
 * request: decreaseAttachmentSortOrderUsingPUT
 * url: decreaseAttachmentSortOrderUsingPUTURL
 * method: decreaseAttachmentSortOrderUsingPUT_TYPE
 * raw_url: decreaseAttachmentSortOrderUsingPUT_RAW_URL
 * @param id - id
 */
export const decreaseAttachmentSortOrderUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/attachment/order-decrease/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const decreaseAttachmentSortOrderUsingPUT_RAW_URL = function() {
  return '/attachment/order-decrease/{id}'
}
export const decreaseAttachmentSortOrderUsingPUT_TYPE = function() {
  return 'put'
}
export const decreaseAttachmentSortOrderUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/attachment/order-decrease/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * increaseAttachmentSortOrder
 * request: increaseAttachmentSortOrderUsingPUT
 * url: increaseAttachmentSortOrderUsingPUTURL
 * method: increaseAttachmentSortOrderUsingPUT_TYPE
 * raw_url: increaseAttachmentSortOrderUsingPUT_RAW_URL
 * @param id - id
 */
export const increaseAttachmentSortOrderUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/attachment/order-increase/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const increaseAttachmentSortOrderUsingPUT_RAW_URL = function() {
  return '/attachment/order-increase/{id}'
}
export const increaseAttachmentSortOrderUsingPUT_TYPE = function() {
  return 'put'
}
export const increaseAttachmentSortOrderUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/attachment/order-increase/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingGET
 * url: errorHtmlUsingGETURL
 * method: errorHtmlUsingGET_TYPE
 * raw_url: errorHtmlUsingGET_RAW_URL
 */
export const errorHtmlUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingGET_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingGET_TYPE = function() {
  return 'get'
}
export const errorHtmlUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingHEAD
 * url: errorHtmlUsingHEADURL
 * method: errorHtmlUsingHEAD_TYPE
 * raw_url: errorHtmlUsingHEAD_RAW_URL
 */
export const errorHtmlUsingHEAD = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('head', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingHEAD_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingHEAD_TYPE = function() {
  return 'head'
}
export const errorHtmlUsingHEADURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingPOST
 * url: errorHtmlUsingPOSTURL
 * method: errorHtmlUsingPOST_TYPE
 * raw_url: errorHtmlUsingPOST_RAW_URL
 */
export const errorHtmlUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingPOST_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingPOST_TYPE = function() {
  return 'post'
}
export const errorHtmlUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingPUT
 * url: errorHtmlUsingPUTURL
 * method: errorHtmlUsingPUT_TYPE
 * raw_url: errorHtmlUsingPUT_RAW_URL
 */
export const errorHtmlUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingPUT_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingPUT_TYPE = function() {
  return 'put'
}
export const errorHtmlUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingDELETE
 * url: errorHtmlUsingDELETEURL
 * method: errorHtmlUsingDELETE_TYPE
 * raw_url: errorHtmlUsingDELETE_RAW_URL
 */
export const errorHtmlUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingDELETE_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingDELETE_TYPE = function() {
  return 'delete'
}
export const errorHtmlUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingOPTIONS
 * url: errorHtmlUsingOPTIONSURL
 * method: errorHtmlUsingOPTIONS_TYPE
 * raw_url: errorHtmlUsingOPTIONS_RAW_URL
 */
export const errorHtmlUsingOPTIONS = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('options', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingOPTIONS_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingOPTIONS_TYPE = function() {
  return 'options'
}
export const errorHtmlUsingOPTIONSURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingPATCH
 * url: errorHtmlUsingPATCHURL
 * method: errorHtmlUsingPATCH_TYPE
 * raw_url: errorHtmlUsingPATCH_RAW_URL
 */
export const errorHtmlUsingPATCH = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingPATCH_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingPATCH_TYPE = function() {
  return 'patch'
}
export const errorHtmlUsingPATCHURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * isTokenValid
 * request: isTokenValidUsingGET
 * url: isTokenValidUsingGETURL
 * method: isTokenValidUsingGET_TYPE
 * raw_url: isTokenValidUsingGET_RAW_URL
 */
export const isTokenValidUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/is-token-valid'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const isTokenValidUsingGET_RAW_URL = function() {
  return '/is-token-valid'
}
export const isTokenValidUsingGET_TYPE = function() {
  return 'get'
}
export const isTokenValidUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/is-token-valid'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * login
 * request: loginUsingPOST
 * url: loginUsingPOSTURL
 * method: loginUsingPOST_TYPE
 * raw_url: loginUsingPOST_RAW_URL
 * @param authenticationRequest - authenticationRequest
 */
export const loginUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/login'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['authenticationRequest'] !== undefined) {
    body = parameters['authenticationRequest']
  }
  if (parameters['authenticationRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: authenticationRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const loginUsingPOST_RAW_URL = function() {
  return '/login'
}
export const loginUsingPOST_TYPE = function() {
  return 'post'
}
export const loginUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/login'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getMap
 * request: getMapUsingGET
 * url: getMapUsingGETURL
 * method: getMapUsingGET_TYPE
 * raw_url: getMapUsingGET_RAW_URL
 * @param id - id
 */
export const getMapUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/map'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getMapUsingGET_RAW_URL = function() {
  return '/map'
}
export const getMapUsingGET_TYPE = function() {
  return 'get'
}
export const getMapUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/map'
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createMap
 * request: createMapUsingPOST
 * url: createMapUsingPOSTURL
 * method: createMapUsingPOST_TYPE
 * raw_url: createMapUsingPOST_RAW_URL
 * @param updateAreaMapDto - updateAreaMapDTO
 */
export const createMapUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/map'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['updateAreaMapDto'] !== undefined) {
    body = parameters['updateAreaMapDto']
  }
  if (parameters['updateAreaMapDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: updateAreaMapDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createMapUsingPOST_RAW_URL = function() {
  return '/map'
}
export const createMapUsingPOST_TYPE = function() {
  return 'post'
}
export const createMapUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/map'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * editMap
 * request: editMapUsingPUT
 * url: editMapUsingPUTURL
 * method: editMapUsingPUT_TYPE
 * raw_url: editMapUsingPUT_RAW_URL
 * @param updateAreaMapDto - updateAreaMapDTO
 */
export const editMapUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/map'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['updateAreaMapDto'] !== undefined) {
    body = parameters['updateAreaMapDto']
  }
  if (parameters['updateAreaMapDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: updateAreaMapDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const editMapUsingPUT_RAW_URL = function() {
  return '/map'
}
export const editMapUsingPUT_TYPE = function() {
  return 'put'
}
export const editMapUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/map'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteMap
 * request: deleteMapUsingDELETE
 * url: deleteMapUsingDELETEURL
 * method: deleteMapUsingDELETE_TYPE
 * raw_url: deleteMapUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteMapUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/map'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteMapUsingDELETE_RAW_URL = function() {
  return '/map'
}
export const deleteMapUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteMapUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/map'
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getMapIdList
 * request: getMapIdListUsingGET
 * url: getMapIdListUsingGETURL
 * method: getMapIdListUsingGET_TYPE
 * raw_url: getMapIdListUsingGET_RAW_URL
 * @param areaId - areaId
 */
export const getMapIdListUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/map/id-list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['areaId'] !== undefined) {
    queryParameters['areaId'] = parameters['areaId']
  }
  if (parameters['areaId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: areaId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getMapIdListUsingGET_RAW_URL = function() {
  return '/map/id-list'
}
export const getMapIdListUsingGET_TYPE = function() {
  return 'get'
}
export const getMapIdListUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/map/id-list'
  if (parameters['areaId'] !== undefined) {
    queryParameters['areaId'] = parameters['areaId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getPanorama
 * request: getPanoramaUsingGET
 * url: getPanoramaUsingGETURL
 * method: getPanoramaUsingGET_TYPE
 * raw_url: getPanoramaUsingGET_RAW_URL
 * @param id - id
 */
export const getPanoramaUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/panorama'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPanoramaUsingGET_RAW_URL = function() {
  return '/panorama'
}
export const getPanoramaUsingGET_TYPE = function() {
  return 'get'
}
export const getPanoramaUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/panorama'
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createPanorama
 * request: createPanoramaUsingPOST
 * url: createPanoramaUsingPOSTURL
 * method: createPanoramaUsingPOST_TYPE
 * raw_url: createPanoramaUsingPOST_RAW_URL
 * @param updatePanoramaDto - updatePanoramaDTO
 */
export const createPanoramaUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/panorama'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['updatePanoramaDto'] !== undefined) {
    body = parameters['updatePanoramaDto']
  }
  if (parameters['updatePanoramaDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: updatePanoramaDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createPanoramaUsingPOST_RAW_URL = function() {
  return '/panorama'
}
export const createPanoramaUsingPOST_TYPE = function() {
  return 'post'
}
export const createPanoramaUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/panorama'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * editPanorama
 * request: editPanoramaUsingPUT
 * url: editPanoramaUsingPUTURL
 * method: editPanoramaUsingPUT_TYPE
 * raw_url: editPanoramaUsingPUT_RAW_URL
 * @param updatePanoramaDto - updatePanoramaDTO
 */
export const editPanoramaUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/panorama'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['updatePanoramaDto'] !== undefined) {
    body = parameters['updatePanoramaDto']
  }
  if (parameters['updatePanoramaDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: updatePanoramaDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const editPanoramaUsingPUT_RAW_URL = function() {
  return '/panorama'
}
export const editPanoramaUsingPUT_TYPE = function() {
  return 'put'
}
export const editPanoramaUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/panorama'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deletePanorama
 * request: deletePanoramaUsingDELETE
 * url: deletePanoramaUsingDELETEURL
 * method: deletePanoramaUsingDELETE_TYPE
 * raw_url: deletePanoramaUsingDELETE_RAW_URL
 * @param id - id
 */
export const deletePanoramaUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/panorama'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deletePanoramaUsingDELETE_RAW_URL = function() {
  return '/panorama'
}
export const deletePanoramaUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deletePanoramaUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/panorama'
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getPanoramaIdList
 * request: getPanoramaIdListUsingGET
 * url: getPanoramaIdListUsingGETURL
 * method: getPanoramaIdListUsingGET_TYPE
 * raw_url: getPanoramaIdListUsingGET_RAW_URL
 * @param poiId - poiId
 */
export const getPanoramaIdListUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/panorama/id-list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['poiId'] !== undefined) {
    queryParameters['poiId'] = parameters['poiId']
  }
  if (parameters['poiId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: poiId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPanoramaIdListUsingGET_RAW_URL = function() {
  return '/panorama/id-list'
}
export const getPanoramaIdListUsingGET_TYPE = function() {
  return 'get'
}
export const getPanoramaIdListUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/panorama/id-list'
  if (parameters['poiId'] !== undefined) {
    queryParameters['poiId'] = parameters['poiId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getPathway
 * request: getPathwayUsingGET
 * url: getPathwayUsingGETURL
 * method: getPathwayUsingGET_TYPE
 * raw_url: getPathwayUsingGET_RAW_URL
 * @param id - id
 */
export const getPathwayUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/pathway'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPathwayUsingGET_RAW_URL = function() {
  return '/pathway'
}
export const getPathwayUsingGET_TYPE = function() {
  return 'get'
}
export const getPathwayUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/pathway'
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdatePathway
 * request: createOrUpdatePathwayUsingPOST
 * url: createOrUpdatePathwayUsingPOSTURL
 * method: createOrUpdatePathwayUsingPOST_TYPE
 * raw_url: createOrUpdatePathwayUsingPOST_RAW_URL
 * @param pathwayDto - pathwayDTO
 */
export const createOrUpdatePathwayUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/pathway'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['pathwayDto'] !== undefined) {
    body = parameters['pathwayDto']
  }
  if (parameters['pathwayDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pathwayDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdatePathwayUsingPOST_RAW_URL = function() {
  return '/pathway'
}
export const createOrUpdatePathwayUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdatePathwayUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/pathway'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deletePathway
 * request: deletePathwayUsingDELETE
 * url: deletePathwayUsingDELETEURL
 * method: deletePathwayUsingDELETE_TYPE
 * raw_url: deletePathwayUsingDELETE_RAW_URL
 * @param id - id
 */
export const deletePathwayUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/pathway'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deletePathwayUsingDELETE_RAW_URL = function() {
  return '/pathway'
}
export const deletePathwayUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deletePathwayUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/pathway'
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getDetailedPathListData
 * request: getDetailedPathListDataUsingGET
 * url: getDetailedPathListDataUsingGETURL
 * method: getDetailedPathListDataUsingGET_TYPE
 * raw_url: getDetailedPathListDataUsingGET_RAW_URL
 */
export const getDetailedPathListDataUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/pathway/detailed-list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDetailedPathListDataUsingGET_RAW_URL = function() {
  return '/pathway/detailed-list'
}
export const getDetailedPathListDataUsingGET_TYPE = function() {
  return 'get'
}
export const getDetailedPathListDataUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/pathway/detailed-list'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getPathwayList
 * request: getPathwayListUsingGET
 * url: getPathwayListUsingGETURL
 * method: getPathwayListUsingGET_TYPE
 * raw_url: getPathwayListUsingGET_RAW_URL
 */
export const getPathwayListUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/pathway/list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPathwayListUsingGET_RAW_URL = function() {
  return '/pathway/list'
}
export const getPathwayListUsingGET_TYPE = function() {
  return 'get'
}
export const getPathwayListUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/pathway/list'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * decreasePathwaySortOrder
 * request: decreasePathwaySortOrderUsingPUT
 * url: decreasePathwaySortOrderUsingPUTURL
 * method: decreasePathwaySortOrderUsingPUT_TYPE
 * raw_url: decreasePathwaySortOrderUsingPUT_RAW_URL
 * @param id - id
 */
export const decreasePathwaySortOrderUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/pathway/order-decrease/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const decreasePathwaySortOrderUsingPUT_RAW_URL = function() {
  return '/pathway/order-decrease/{id}'
}
export const decreasePathwaySortOrderUsingPUT_TYPE = function() {
  return 'put'
}
export const decreasePathwaySortOrderUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/pathway/order-decrease/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * increasePathwaySortOrder
 * request: increasePathwaySortOrderUsingPUT
 * url: increasePathwaySortOrderUsingPUTURL
 * method: increasePathwaySortOrderUsingPUT_TYPE
 * raw_url: increasePathwaySortOrderUsingPUT_RAW_URL
 * @param id - id
 */
export const increasePathwaySortOrderUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/pathway/order-increase/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const increasePathwaySortOrderUsingPUT_RAW_URL = function() {
  return '/pathway/order-increase/{id}'
}
export const increasePathwaySortOrderUsingPUT_TYPE = function() {
  return 'put'
}
export const increasePathwaySortOrderUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/pathway/order-increase/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdatePOI
 * request: createOrUpdatePOIUsingPOST
 * url: createOrUpdatePOIUsingPOSTURL
 * method: createOrUpdatePOIUsingPOST_TYPE
 * raw_url: createOrUpdatePOIUsingPOST_RAW_URL
 * @param poiDto - poiDTO
 */
export const createOrUpdatePOIUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/poi'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['poiDto'] !== undefined) {
    body = parameters['poiDto']
  }
  if (parameters['poiDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: poiDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdatePOIUsingPOST_RAW_URL = function() {
  return '/poi'
}
export const createOrUpdatePOIUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdatePOIUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/poi'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getDetailedPOIData
 * request: getDetailedPOIDataUsingGET
 * url: getDetailedPOIDataUsingGETURL
 * method: getDetailedPOIDataUsingGET_TYPE
 * raw_url: getDetailedPOIDataUsingGET_RAW_URL
 * @param id - id
 */
export const getDetailedPOIDataUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/poi/detailed'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDetailedPOIDataUsingGET_RAW_URL = function() {
  return '/poi/detailed'
}
export const getDetailedPOIDataUsingGET_TYPE = function() {
  return 'get'
}
export const getDetailedPOIDataUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/poi/detailed'
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getDetailedPOIListData
 * request: getDetailedPOIListDataUsingGET
 * url: getDetailedPOIListDataUsingGETURL
 * method: getDetailedPOIListDataUsingGET_TYPE
 * raw_url: getDetailedPOIListDataUsingGET_RAW_URL
 */
export const getDetailedPOIListDataUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/poi/detailed-list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDetailedPOIListDataUsingGET_RAW_URL = function() {
  return '/poi/detailed-list'
}
export const getDetailedPOIListDataUsingGET_TYPE = function() {
  return 'get'
}
export const getDetailedPOIListDataUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/poi/detailed-list'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getPOIList
 * request: getPOIListUsingGET
 * url: getPOIListUsingGETURL
 * method: getPOIListUsingGET_TYPE
 * raw_url: getPOIListUsingGET_RAW_URL
 */
export const getPOIListUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/poi/list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPOIListUsingGET_RAW_URL = function() {
  return '/poi/list'
}
export const getPOIListUsingGET_TYPE = function() {
  return 'get'
}
export const getPOIListUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/poi/list'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getPOIListWithNeighboursData
 * request: getPOIListWithNeighboursDataUsingGET
 * url: getPOIListWithNeighboursDataUsingGETURL
 * method: getPOIListWithNeighboursDataUsingGET_TYPE
 * raw_url: getPOIListWithNeighboursDataUsingGET_RAW_URL
 * @param id - id
 */
export const getPOIListWithNeighboursDataUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/poi/list-with-neighbours-data'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPOIListWithNeighboursDataUsingGET_RAW_URL = function() {
  return '/poi/list-with-neighbours-data'
}
export const getPOIListWithNeighboursDataUsingGET_TYPE = function() {
  return 'get'
}
export const getPOIListWithNeighboursDataUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/poi/list-with-neighbours-data'
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * decreasePOISortOrder
 * request: decreasePOISortOrderUsingPUT
 * url: decreasePOISortOrderUsingPUTURL
 * method: decreasePOISortOrderUsingPUT_TYPE
 * raw_url: decreasePOISortOrderUsingPUT_RAW_URL
 * @param id - id
 */
export const decreasePOISortOrderUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/poi/order-decrease/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const decreasePOISortOrderUsingPUT_RAW_URL = function() {
  return '/poi/order-decrease/{id}'
}
export const decreasePOISortOrderUsingPUT_TYPE = function() {
  return 'put'
}
export const decreasePOISortOrderUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/poi/order-decrease/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * increasePOISortOrder
 * request: increasePOISortOrderUsingPUT
 * url: increasePOISortOrderUsingPUTURL
 * method: increasePOISortOrderUsingPUT_TYPE
 * raw_url: increasePOISortOrderUsingPUT_RAW_URL
 * @param id - id
 */
export const increasePOISortOrderUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/poi/order-increase/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const increasePOISortOrderUsingPUT_RAW_URL = function() {
  return '/poi/order-increase/{id}'
}
export const increasePOISortOrderUsingPUT_TYPE = function() {
  return 'put'
}
export const increasePOISortOrderUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/poi/order-increase/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getPOI
 * request: getPOIUsingGET
 * url: getPOIUsingGETURL
 * method: getPOIUsingGET_TYPE
 * raw_url: getPOIUsingGET_RAW_URL
 * @param id - id
 */
export const getPOIUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/poi/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPOIUsingGET_RAW_URL = function() {
  return '/poi/{id}'
}
export const getPOIUsingGET_TYPE = function() {
  return 'get'
}
export const getPOIUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/poi/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deletePOI
 * request: deletePOIUsingDELETE
 * url: deletePOIUsingDELETEURL
 * method: deletePOIUsingDELETE_TYPE
 * raw_url: deletePOIUsingDELETE_RAW_URL
 * @param id - id
 */
export const deletePOIUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/poi/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deletePOIUsingDELETE_RAW_URL = function() {
  return '/poi/{id}'
}
export const deletePOIUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deletePOIUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/poi/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateQuiz
 * request: createOrUpdateQuizUsingPOST
 * url: createOrUpdateQuizUsingPOSTURL
 * method: createOrUpdateQuizUsingPOST_TYPE
 * raw_url: createOrUpdateQuizUsingPOST_RAW_URL
 * @param quizDto - quizDTO
 */
export const createOrUpdateQuizUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/quiz'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['quizDto'] !== undefined) {
    body = parameters['quizDto']
  }
  if (parameters['quizDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: quizDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateQuizUsingPOST_RAW_URL = function() {
  return '/quiz'
}
export const createOrUpdateQuizUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateQuizUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/quiz'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateQuizAnswer
 * request: createOrUpdateQuizAnswerUsingPOST
 * url: createOrUpdateQuizAnswerUsingPOSTURL
 * method: createOrUpdateQuizAnswerUsingPOST_TYPE
 * raw_url: createOrUpdateQuizAnswerUsingPOST_RAW_URL
 * @param quizAnswerDto - quizAnswerDTO
 */
export const createOrUpdateQuizAnswerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/quiz/answer'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['quizAnswerDto'] !== undefined) {
    body = parameters['quizAnswerDto']
  }
  if (parameters['quizAnswerDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: quizAnswerDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateQuizAnswerUsingPOST_RAW_URL = function() {
  return '/quiz/answer'
}
export const createOrUpdateQuizAnswerUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateQuizAnswerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/quiz/answer'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getQuizAnswerList
 * request: getQuizAnswerListUsingGET
 * url: getQuizAnswerListUsingGETURL
 * method: getQuizAnswerListUsingGET_TYPE
 * raw_url: getQuizAnswerListUsingGET_RAW_URL
 * @param questionId - questionId
 */
export const getQuizAnswerListUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/quiz/answer/list/{questionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{questionId}', `${parameters['questionId']}`)
  if (parameters['questionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: questionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getQuizAnswerListUsingGET_RAW_URL = function() {
  return '/quiz/answer/list/{questionId}'
}
export const getQuizAnswerListUsingGET_TYPE = function() {
  return 'get'
}
export const getQuizAnswerListUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/quiz/answer/list/{questionId}'
  path = path.replace('{questionId}', `${parameters['questionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * decreaseQuizAnswerSortOrder
 * request: decreaseQuizAnswerSortOrderUsingPUT
 * url: decreaseQuizAnswerSortOrderUsingPUTURL
 * method: decreaseQuizAnswerSortOrderUsingPUT_TYPE
 * raw_url: decreaseQuizAnswerSortOrderUsingPUT_RAW_URL
 * @param id - id
 */
export const decreaseQuizAnswerSortOrderUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/quiz/answer/order-decrease/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const decreaseQuizAnswerSortOrderUsingPUT_RAW_URL = function() {
  return '/quiz/answer/order-decrease/{id}'
}
export const decreaseQuizAnswerSortOrderUsingPUT_TYPE = function() {
  return 'put'
}
export const decreaseQuizAnswerSortOrderUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/quiz/answer/order-decrease/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * increaseQuizAnswerSortOrder
 * request: increaseQuizAnswerSortOrderUsingPUT
 * url: increaseQuizAnswerSortOrderUsingPUTURL
 * method: increaseQuizAnswerSortOrderUsingPUT_TYPE
 * raw_url: increaseQuizAnswerSortOrderUsingPUT_RAW_URL
 * @param id - id
 */
export const increaseQuizAnswerSortOrderUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/quiz/answer/order-increase/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const increaseQuizAnswerSortOrderUsingPUT_RAW_URL = function() {
  return '/quiz/answer/order-increase/{id}'
}
export const increaseQuizAnswerSortOrderUsingPUT_TYPE = function() {
  return 'put'
}
export const increaseQuizAnswerSortOrderUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/quiz/answer/order-increase/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getQuizAnswer
 * request: getQuizAnswerUsingGET
 * url: getQuizAnswerUsingGETURL
 * method: getQuizAnswerUsingGET_TYPE
 * raw_url: getQuizAnswerUsingGET_RAW_URL
 * @param id - id
 */
export const getQuizAnswerUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/quiz/answer/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getQuizAnswerUsingGET_RAW_URL = function() {
  return '/quiz/answer/{id}'
}
export const getQuizAnswerUsingGET_TYPE = function() {
  return 'get'
}
export const getQuizAnswerUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/quiz/answer/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteQuizAnswer
 * request: deleteQuizAnswerUsingDELETE
 * url: deleteQuizAnswerUsingDELETEURL
 * method: deleteQuizAnswerUsingDELETE_TYPE
 * raw_url: deleteQuizAnswerUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteQuizAnswerUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/quiz/answer/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteQuizAnswerUsingDELETE_RAW_URL = function() {
  return '/quiz/answer/{id}'
}
export const deleteQuizAnswerUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteQuizAnswerUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/quiz/answer/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getDetailedQuizListData
 * request: getDetailedQuizListDataUsingGET
 * url: getDetailedQuizListDataUsingGETURL
 * method: getDetailedQuizListDataUsingGET_TYPE
 * raw_url: getDetailedQuizListDataUsingGET_RAW_URL
 */
export const getDetailedQuizListDataUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/quiz/detailed-list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDetailedQuizListDataUsingGET_RAW_URL = function() {
  return '/quiz/detailed-list'
}
export const getDetailedQuizListDataUsingGET_TYPE = function() {
  return 'get'
}
export const getDetailedQuizListDataUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/quiz/detailed-list'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getQuizList
 * request: getQuizListUsingGET
 * url: getQuizListUsingGETURL
 * method: getQuizListUsingGET_TYPE
 * raw_url: getQuizListUsingGET_RAW_URL
 */
export const getQuizListUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/quiz/list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getQuizListUsingGET_RAW_URL = function() {
  return '/quiz/list'
}
export const getQuizListUsingGET_TYPE = function() {
  return 'get'
}
export const getQuizListUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/quiz/list'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateQuizQuestion
 * request: createOrUpdateQuizQuestionUsingPOST
 * url: createOrUpdateQuizQuestionUsingPOSTURL
 * method: createOrUpdateQuizQuestionUsingPOST_TYPE
 * raw_url: createOrUpdateQuizQuestionUsingPOST_RAW_URL
 * @param quizQuestionDto - quizQuestionDTO
 */
export const createOrUpdateQuizQuestionUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/quiz/question'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['quizQuestionDto'] !== undefined) {
    body = parameters['quizQuestionDto']
  }
  if (parameters['quizQuestionDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: quizQuestionDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateQuizQuestionUsingPOST_RAW_URL = function() {
  return '/quiz/question'
}
export const createOrUpdateQuizQuestionUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateQuizQuestionUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/quiz/question'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getQuestionNames
 * request: getQuestionNamesUsingGET
 * url: getQuestionNamesUsingGETURL
 * method: getQuestionNamesUsingGET_TYPE
 * raw_url: getQuestionNamesUsingGET_RAW_URL
 * @param questionId - questionId
 */
export const getQuestionNamesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/quiz/question-names'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['questionId'] !== undefined) {
    queryParameters['questionId'] = parameters['questionId']
  }
  if (parameters['questionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: questionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getQuestionNamesUsingGET_RAW_URL = function() {
  return '/quiz/question-names'
}
export const getQuestionNamesUsingGET_TYPE = function() {
  return 'get'
}
export const getQuestionNamesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/quiz/question-names'
  if (parameters['questionId'] !== undefined) {
    queryParameters['questionId'] = parameters['questionId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getQuizQuestionList
 * request: getQuizQuestionListUsingGET
 * url: getQuizQuestionListUsingGETURL
 * method: getQuizQuestionListUsingGET_TYPE
 * raw_url: getQuizQuestionListUsingGET_RAW_URL
 * @param quizId - quizId
 */
export const getQuizQuestionListUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/quiz/question/list/{quizId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{quizId}', `${parameters['quizId']}`)
  if (parameters['quizId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: quizId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getQuizQuestionListUsingGET_RAW_URL = function() {
  return '/quiz/question/list/{quizId}'
}
export const getQuizQuestionListUsingGET_TYPE = function() {
  return 'get'
}
export const getQuizQuestionListUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/quiz/question/list/{quizId}'
  path = path.replace('{quizId}', `${parameters['quizId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * decreaseQuizQuestionSortOrder
 * request: decreaseQuizQuestionSortOrderUsingPUT
 * url: decreaseQuizQuestionSortOrderUsingPUTURL
 * method: decreaseQuizQuestionSortOrderUsingPUT_TYPE
 * raw_url: decreaseQuizQuestionSortOrderUsingPUT_RAW_URL
 * @param id - id
 */
export const decreaseQuizQuestionSortOrderUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/quiz/question/order-decrease/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const decreaseQuizQuestionSortOrderUsingPUT_RAW_URL = function() {
  return '/quiz/question/order-decrease/{id}'
}
export const decreaseQuizQuestionSortOrderUsingPUT_TYPE = function() {
  return 'put'
}
export const decreaseQuizQuestionSortOrderUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/quiz/question/order-decrease/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * increaseQuizQuestionSortOrder
 * request: increaseQuizQuestionSortOrderUsingPUT
 * url: increaseQuizQuestionSortOrderUsingPUTURL
 * method: increaseQuizQuestionSortOrderUsingPUT_TYPE
 * raw_url: increaseQuizQuestionSortOrderUsingPUT_RAW_URL
 * @param id - id
 */
export const increaseQuizQuestionSortOrderUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/quiz/question/order-increase/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const increaseQuizQuestionSortOrderUsingPUT_RAW_URL = function() {
  return '/quiz/question/order-increase/{id}'
}
export const increaseQuizQuestionSortOrderUsingPUT_TYPE = function() {
  return 'put'
}
export const increaseQuizQuestionSortOrderUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/quiz/question/order-increase/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getQuizQuestion
 * request: getQuizQuestionUsingGET
 * url: getQuizQuestionUsingGETURL
 * method: getQuizQuestionUsingGET_TYPE
 * raw_url: getQuizQuestionUsingGET_RAW_URL
 * @param id - id
 */
export const getQuizQuestionUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/quiz/question/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getQuizQuestionUsingGET_RAW_URL = function() {
  return '/quiz/question/{id}'
}
export const getQuizQuestionUsingGET_TYPE = function() {
  return 'get'
}
export const getQuizQuestionUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/quiz/question/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteQuizQuestion
 * request: deleteQuizQuestionUsingDELETE
 * url: deleteQuizQuestionUsingDELETEURL
 * method: deleteQuizQuestionUsingDELETE_TYPE
 * raw_url: deleteQuizQuestionUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteQuizQuestionUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/quiz/question/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteQuizQuestionUsingDELETE_RAW_URL = function() {
  return '/quiz/question/{id}'
}
export const deleteQuizQuestionUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteQuizQuestionUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/quiz/question/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getQuizNames
 * request: getQuizNamesUsingGET
 * url: getQuizNamesUsingGETURL
 * method: getQuizNamesUsingGET_TYPE
 * raw_url: getQuizNamesUsingGET_RAW_URL
 * @param quizId - quizId
 */
export const getQuizNamesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/quiz/quiz-names'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['quizId'] !== undefined) {
    queryParameters['quizId'] = parameters['quizId']
  }
  if (parameters['quizId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: quizId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getQuizNamesUsingGET_RAW_URL = function() {
  return '/quiz/quiz-names'
}
export const getQuizNamesUsingGET_TYPE = function() {
  return 'get'
}
export const getQuizNamesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/quiz/quiz-names'
  if (parameters['quizId'] !== undefined) {
    queryParameters['quizId'] = parameters['quizId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getQuiz
 * request: getQuizUsingGET
 * url: getQuizUsingGETURL
 * method: getQuizUsingGET_TYPE
 * raw_url: getQuizUsingGET_RAW_URL
 * @param id - id
 */
export const getQuizUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/quiz/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getQuizUsingGET_RAW_URL = function() {
  return '/quiz/{id}'
}
export const getQuizUsingGET_TYPE = function() {
  return 'get'
}
export const getQuizUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/quiz/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteQuiz
 * request: deleteQuizUsingDELETE
 * url: deleteQuizUsingDELETEURL
 * method: deleteQuizUsingDELETE_TYPE
 * raw_url: deleteQuizUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteQuizUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/quiz/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteQuizUsingDELETE_RAW_URL = function() {
  return '/quiz/{id}'
}
export const deleteQuizUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteQuizUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/quiz/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchUser
 * request: searchUserUsingPOST
 * url: searchUserUsingPOSTURL
 * method: searchUserUsingPOST_TYPE
 * raw_url: searchUserUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchUserUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchUserUsingPOST_RAW_URL = function() {
  return '/search/user'
}
export const searchUserUsingPOST_TYPE = function() {
  return 'post'
}
export const searchUserUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/user'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchUserCount
 * request: searchUserCountUsingPOST
 * url: searchUserCountUsingPOSTURL
 * method: searchUserCountUsingPOST_TYPE
 * raw_url: searchUserCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchUserCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/user/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchUserCountUsingPOST_RAW_URL = function() {
  return '/search/user/count'
}
export const searchUserCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchUserCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/user/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateUser
 * request: createOrUpdateUserUsingPOST
 * url: createOrUpdateUserUsingPOSTURL
 * method: createOrUpdateUserUsingPOST_TYPE
 * raw_url: createOrUpdateUserUsingPOST_RAW_URL
 * @param userDto - userDTO
 */
export const createOrUpdateUserUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['userDto'] !== undefined) {
    body = parameters['userDto']
  }
  if (parameters['userDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: userDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateUserUsingPOST_RAW_URL = function() {
  return '/user'
}
export const createOrUpdateUserUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateUserUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * changePassword
 * request: changePasswordUsingPOST
 * url: changePasswordUsingPOSTURL
 * method: changePasswordUsingPOST_TYPE
 * raw_url: changePasswordUsingPOST_RAW_URL
 * @param passwordChangeRequest - passwordChangeRequest
 */
export const changePasswordUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/password/change'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['passwordChangeRequest'] !== undefined) {
    body = parameters['passwordChangeRequest']
  }
  if (parameters['passwordChangeRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: passwordChangeRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const changePasswordUsingPOST_RAW_URL = function() {
  return '/user/password/change'
}
export const changePasswordUsingPOST_TYPE = function() {
  return 'post'
}
export const changePasswordUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/password/change'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * remindPassword
 * request: remindPasswordUsingPOST
 * url: remindPasswordUsingPOSTURL
 * method: remindPasswordUsingPOST_TYPE
 * raw_url: remindPasswordUsingPOST_RAW_URL
 * @param passwordRemindRequest - passwordRemindRequest
 */
export const remindPasswordUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/password/remind'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['passwordRemindRequest'] !== undefined) {
    body = parameters['passwordRemindRequest']
  }
  if (parameters['passwordRemindRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: passwordRemindRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const remindPasswordUsingPOST_RAW_URL = function() {
  return '/user/password/remind'
}
export const remindPasswordUsingPOST_TYPE = function() {
  return 'post'
}
export const remindPasswordUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/password/remind'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUserRoles
 * request: getUserRolesUsingGET
 * url: getUserRolesUsingGETURL
 * method: getUserRolesUsingGET_TYPE
 * raw_url: getUserRolesUsingGET_RAW_URL
 */
export const getUserRolesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/roles'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserRolesUsingGET_RAW_URL = function() {
  return '/user/roles'
}
export const getUserRolesUsingGET_TYPE = function() {
  return 'get'
}
export const getUserRolesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/roles'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUser
 * request: getUserUsingGET
 * url: getUserUsingGETURL
 * method: getUserUsingGET_TYPE
 * raw_url: getUserUsingGET_RAW_URL
 * @param id - id
 */
export const getUserUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserUsingGET_RAW_URL = function() {
  return '/user/{id}'
}
export const getUserUsingGET_TYPE = function() {
  return 'get'
}
export const getUserUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}