/* eslint-disable */
export const OfflineData = {

	isOffline() {
	    return navigator.onLine === false;
    },

    getOfflineResourcesPath() {
        return window.OFFLINE_RESOURCES_PATH;
    },

    getDetailedPoiListData() {
        return window.DETAILED_POI_LIST_DATA;
    },

    getDetailedAreaListData() {
        return window.DETAILED_AREA_LIST_DATA;
    },

    getDetailedPathListData() {
        return window.DETAILED_PATH_LIST_DATA;
    },

    getDetailedQuizListData() {
	    return window.DETAILED_QUIZ_LIST_DATA;
    },

    getRedirectUrlPath() {
	    return window.REDIRECT_URL_PATH;
    },
};
