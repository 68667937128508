<template>
    <div class="thumbnail-bar">
        <button
            v-if="isScrollNeeded"
            tabindex="-1"
            class="left"
            @click="scrollThumbnails($event, -1)"
            @keyup.enter="scrollThumbnails($event, -1)">
            <div class="visually-hidden">{{ $t('button.scrollLeft') }}</div>
            <svg class="prev" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40" height="40">
                <path d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z">
                </path>
            </svg>
        </button>
        <button
            v-if="isScrollNeeded"
            tabindex="-1"
            class="right"
            @click="scrollThumbnails($event, 1)"
            @keyup.enter="scrollThumbnails($event, 1)">
            <div class="visually-hidden">{{ $t('button.scrollRight') }}</div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40" height="40">
                <path d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z">
                </path>
            </svg>
        </button>
        <ul class="thumbnail-list" v-dragscroll>
            <li v-for="thumb in thumbs" :key="thumb.id">
                <a
                    tabindex="-1"
                    @click="calledFunction($event, thumb)"
                    @keyup.enter="calledFunction($event, thumb)"
                    :class="'panorama-thumbnail l-area-item'
                        + (thumb.isCurrent ? ' current-panorama' : '')"
                >
                    <div :style="'background-image: url(\''
                             + thumb.src + '\' )'"
                         :data-tooltip="thumb.title"
                         @mouseover="showTooltip($event)"
                         @mouseleave="hideTooltip()"
                         @click="hideTooltip()"
                    />
                    <div class="visually-hidden">{{thumb.title}}</div>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    import {HTMLUtils} from "@/utils/HTMLUtils";
    import {dragscroll} from "vue-dragscroll";

    export default {
        name: "ThumbnailBar",

        mixins: [HTMLUtils],

        directives: {
            dragscroll,
        },

        data() {
            return {
                listContainer: null,
                list: null,
                currentElement: null,
                isScrollNeeded: false,
            };
        },

        props: {
            thumbs: Array,
            calledFunction: Function,
        },

        created() {
            document.addEventListener("dragstart", (e) => { e.preventDefault(); });
            window.addEventListener("resize", this.setScrollControls);
        },

        mounted() {
            this.setData();
            this.centerThumbnails();
            this.setScrollControls();
        },

        destroyed() {
            window.removeEventListener("resize", this.setScrollControls);
            this.$off();
        },

        methods: {
            setData() {
                this.listContainer = this.$el;
                this.list = this.listContainer.querySelector(".thumbnail-list");
                this.currentElement = this.list.querySelector(".current-panorama");
            },

            scrollThumbnails(event, direction) {
                this.list.scrollLeft += direction * this.getThumbnailWidth();
                event.stopPropagation();
            },

            getThumbnailWidth() {
                const element = this.findFirstFullElement(this.list);
                const boundingRect = element.getBoundingClientRect();
                return boundingRect.width;
            },

            findFirstFullElement(list) {
                const elements = list.querySelectorAll("li");
                let element;
                for (let i = 0; i < elements.length; i += 1) {
                    element = elements[i];
                    if (element.getBoundingClientRect().x >= 0) {
                        return element;
                    }
                }
                return element;
            },

            centerThumbnails() {
                const containerWidth = this.list.getBoundingClientRect().width;
                const elementBCR = this.currentElement.getBoundingClientRect();
                const elementOffset = elementBCR.left;
                const elementWidth = elementBCR.width;
                this.list.scrollLeft = elementOffset - containerWidth / 2 + elementWidth / 2;
            },

            setScrollControls() {
                this.isScrollNeeded = this.list.scrollWidth > this.list.getBoundingClientRect().width;
            },
        },
    };
</script>

<style scoped lang="less">
    @import "../views/assets/less/bottom-bar.less";

    .panorama-thumbnail {
        cursor: pointer;
    }
    .panorama-thumbnail.current-panorama {
        cursor: auto;
        border: 3px solid white;
    }

    .thumbnail-bar {
        position: relative;
        button {
            cursor: pointer;
            width: 2em;
            height: 2em;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            padding: 0;
            opacity: .7;
            background: #ccc;
            svg {
                width: 1.2em;
                height: 1.2em;
                &.prev {
                    transform: scaleX(-1);
                }
            }
        }
    }
    button.left,
    button.right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    button.left {
        left: 10px;
    }
    button.right {
        right: 10px;
    }

</style>
