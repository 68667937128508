<template>
    <Dialog :header="header" :visible="show" :style="{width: '550px', border: '1px solid red'}"
            :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem; color: red"></i>
            <span>{{message}}</span>
        </div>
        <template #footer>
            <Button :label="$t('button.no')" icon="pi pi-times" @click="close"
                    class="p-button-text p-button-info"/>
            <Button :label="$t('button.yes')" icon="pi pi-check" @click="emitAction"
                    class="p-button-text p-button-info" autofocus/>
        </template>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import Button from "primevue/components/button/Button";

    export default {
        name: "CustomDialog",

        components: {
            Dialog, Button,
        },

        methods: {
            emitAction() {
                this.$emit("action");
            },

            close() {
                this.$emit("close");
            },
        },

        props: {
            header: String,
            message: String,
            show: {type: Boolean, default: false},
        },
    };
</script>

<style scoped>

</style>
