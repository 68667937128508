<template>
    <Toast/>
</template>

<script>
    import Toast from "primevue/components/toast/Toast";

    export default {
        name: "CustomSnackbar",
        components: {Toast},

        methods: {
            showSuccess(message) {
                this.$toast.add({
                    severity: "success",
                    summary: this.appLang === "pl" ? "Sukces" : "Success",
                    detail: message,
                    life: 3000,
                });
            },

            showError(message) {
                this.$toast.add({
                    severity: "error",
                    summary: this.appLang === "pl" ? "Błąd" : "Error",
                    detail: message,
                    life: 3000,
                });
            },
        },

        created() {
            this.$eventBus.$on("success", this.showSuccess);
            this.$eventBus.$on("error", this.showError);
        },
    };
</script>

<style scoped>

</style>
