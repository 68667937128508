<template>
    <div>
        <loading :active.sync="isLoading">
        </loading>

        <h2 class="page-header"><i class="pi pi-image p-mr-3"></i>{{headerTitle}}</h2>

        <ValidationObserver ref="formObserver">
            <div class="p-grid p-ml-4 p-mr-4 p-mt-5">
                <div class="p-field p-col-12 p-md-4">
                    <CustomInputNumber name="panoramaLat" v-model="panorama.lat" :min="-90"
                                       :max="90" :minFractionDigits="1" :maxFractionDigits="7"
                                       :label="$t('label.lat')" rules="required"/>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <CustomInputNumber name="panoramaLng" v-model="panorama.lng" :min="-180"
                                       :max="180" :minFractionDigits="1" :maxFractionDigits="7"
                                       :label="$t('label.lng')" rules="required"/>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <CustomInputNumber name="panoramaElevation" v-model="panorama.elevation" :min="0"
                                       :max="9999.99" :minFractionDigits="0" :maxFractionDigits="2"
                                       :label="$t('label.elevation')" rules="required"/>
                </div>
            </div>

            <div class="p-grid p-ml-4 p-mr-4 p-mt-5">
                <div class="p-field p-col-12 p-md-4">
                    <CustomInputNumber name="panoramaNorthOffset" v-model="panorama.northOffset" :min="-180"
                                       :max="180" :minFractionDigits="1" :maxFractionDigits="7"
                                       :label="$t('label.northOffset')" rules="required"/>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <CustomInputNumber name="panoramaDefaultPitch" v-model="panorama.defaultPitch" :min="-90"
                                       :max="90" :minFractionDigits="1" :maxFractionDigits="7"
                                       :label="$t('label.defaultPitch')" rules="required"/>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <CustomInputNumber name="panoramaDefaultYaw" v-model="panorama.defaultYaw" :min="-180"
                                       :max="180" :minFractionDigits="1" :maxFractionDigits="7"
                                       :label="$t('label.defaultYaw')" rules="required"/>
                </div>
            </div>

            <div class="p-grid p-ml-4 p-mr-4 p-mt-5">
                <div class="p-field p-col-12 p-md-4"></div>
                <div class="p-field p-col-12 p-md-4">
                    <CustomInputNumber name="panoramaHfov" v-model="panorama.hfov" :min="0"
                                       :max="180" :minFractionDigits="1" :maxFractionDigits="7"
                                       :label="$t('label.hfov')"/>
                </div>
                <div class="p-field p-col-12 p-md-4"></div>
            </div>
        </ValidationObserver>

        <h3>{{ $t('label.defaultViewerConfig') }}</h3>
        <div id="panorama-preview"></div>
        <Toolbar class="actions p-mt-5 p-ml-5">
            <template slot="left">
                <Button @click="rewriteViewerData()" :label="$t('button.rewriteViewerData')"></Button>
            </template>
        </Toolbar>

        <Toolbar class="actions p-mt-5 p-ml-5">
            <template slot="left">
                <Button v-if="role.isAdmin()" @click="$refs.file.click()" icon="pi pi-image"
                        :label="panorama.filePath ? $t('button.changePanorama') : $t('button.addPanorama')"></Button>
            </template>
        </Toolbar>

        <div class="image-preview p-mt-5 p-mb-5" v-if="panorama.encodedData">
            <img class="preview" :src="panorama.encodedData" alt="Panorama" style="width: 70%; height: auto">
        </div>
        <div class="image-preview p-mt-5 p-mb-5" v-else-if="panorama.filePath">
            <img class="preview" :src="staticFileUrl(panorama.filePath)"
                 alt="Panorama" style="width: 70%; height: auto">
        </div>

        <div v-if="panoramaAttached">
            <Toolbar class="actions p-mt-5 p-ml-5">
                <template slot="left">
                    <Button v-if="role.isAdmin()" @click="$refs.thumbnailFile.click()" icon="pi pi-image"
                            :label="panorama.thumbnailFilePath ? $t('button.changeThumbnail')
                                : $t('button.addThumbnail')"></Button>
                </template>
            </Toolbar>
            <div class="image-preview p-mt-5 p-mb-5" v-if="panorama.encodedThumbnailData">
                <img class="preview" :src="panorama.encodedThumbnailData" alt="Miniaturka"
                     style="max-width: 70%; height: auto">
            </div>
            <div class="image-preview p-mt-5 p-mb-5" v-else-if="panorama.thumbnailFilePath">
                <img class="preview" :src="staticFileUrl(panorama.thumbnailFilePath)"
                     alt="Miniaturka" style="max-width: 70%; height: auto">
            </div>
        </div>

        <Toolbar class="actions p-mt-5 p-ml-5">
            <template slot="left">
                <Button v-if="role.isAdmin()" :label="$t('button.savePanoramaData')" icon="pi pi-save"
                        @click="savePanorama(true)"></Button>
            </template>
        </Toolbar>

        <input type="file" ref="file" accept="image/png, image/jpeg" v-show="false" v-on:change="handleUpload">
        <input type="file" ref="thumbnailFile" accept="image/png, image/jpeg" v-show="false"
               v-on:change="handleThumbnailUpload">
    </div>
</template>

<script>
    import {
        createPanoramaUsingPOST as createPanorama,
        editPanoramaUsingPUT as savePanorama,
        getPanoramaUsingGET as getPanorama,
    } from "@/swagger/vue-api-client";
    import Button from "primevue/components/button/Button";
    import Toolbar from "primevue/components/toolbar/Toolbar";
    import {ValidationObserver} from "vee-validate";
    import "vue-loading-overlay/dist/vue-loading.css";
    import Loading from "vue-loading-overlay";
    import {SystemRole} from "../../../utils/SystemRole";
    import {RouterUtils} from "../../../utils/RouterUtils";
    import {FileUtils} from "../../../utils/FileUtils";
    import CustomInputNumber from "../../../components/form/CustomInputNumber";

    export default {
        name: "PanoramaFormView",

        components: {
            Button, Toolbar, CustomInputNumber, ValidationObserver, Loading,
        },

        mixins: [RouterUtils, FileUtils],

        data() {
            return {
                role: SystemRole,
                headerTitle: this.$t("menu.panoramas.add"),
                panorama: {
                    id: null,
                    poiId: null,
                    lat: 0,
                    lng: 0,
                    elevation: 1.5,
                    northOffset: 0,
                    defaultPitch: 0,
                    defaultYaw: 0,
                    hfov: 0,
                    filePath: null,
                    fileName: null,
                    encodedData: null,
                    thumbnailFilePath: null,
                    thumbnailFileName: null,
                    encodedThumbnailData: null,
                },
                viewer: null,
                isLoading: false,
            };
        },

        computed: {
            panoramaAttached() {
                return this.panorama.encodedData || this.panorama.filePath;
            },
        },

        methods: {
            savePanorama(pageReturn) {
                this.$refs.formObserver.validate().then((valid) => {
                    if (!valid) {
                        return;
                    }
                    if (!this.panoramaAttached) {
                        this.$eventBus.$emit("error", this.$t("snackbar.panoramaAddError"));
                        return;
                    }
                    if (this.panorama.id === null) this.createPanorama(pageReturn);
                    else this.editPanorama(pageReturn);
                });
            },

            async createPanorama(pageReturn) {
                this.isLoading = true;
                createPanorama({updatePanoramaDto: this.panorama}).then(
                    (response) => {
                        this.isLoading = false;
                        this.$eventBus.$emit("success", this.$t("snackbar.panoramaAddSuccess"));
                        if (pageReturn) this.$router.go(-1);
                        else {
                            this.headerTitle = this.$t("menu.panoramas.edit");
                            getPanorama({id: response.data}).then((panoramaResponse) => {
                                this.panorama = panoramaResponse.data;
                            });
                            this.pushAsync("/panorama/edit/" + response.data);
                        }
                    },
                    () => {
                        this.isLoading = false;
                        this.$eventBus.$emit("error", this.$t("snackbar.panoramaAddBackendError"));
                    },
                );
            },

            editPanorama(pageReturn) {
                this.isLoading = true;
                savePanorama({updatePanoramaDto: this.panorama}).then(
                    () => {
                        this.isLoading = false;
                        this.$eventBus.$emit("success", this.$t("snackbar.panoramaAddSuccess"));
                        if (pageReturn) this.$router.go(-1);
                        else this.refresh();
                    },
                    () => {
                        this.isLoading = false;
                        this.$eventBus.$emit("error", this.$t("snackbar.panoramaAddBackendError"));
                    },
                );
            },

            async handleUpload() {
                const file = this.$refs.file.files[0];
                this.panorama.fileName = file.name;
                this.panorama.encodedData = await this.fileToBase64(file);
                this.savePanorama(false);
            },

            async handleThumbnailUpload() {
                const file = this.$refs.thumbnailFile.files[0];
                this.panorama.thumbnailFileName = file.name;
                this.panorama.encodedThumbnailData = await this.fileToBase64(file);
                this.savePanorama(false);
            },

            refresh() {
                if (this.$route.params.id) {
                    this.headerTitle = this.$t("menu.panoramas.edit");
                    getPanorama({id: this.$route.params.id}).then((response) => {
                        this.panorama = response.data;
                        this.prepareViewer();
                    });
                }
                if (this.$route.params.poiId) {
                    this.panorama.poiId = this.$route.params.poiId;
                }
            },

            prepareViewer() {
                this.viewer = this.$pannellum.viewer("panorama-preview", {
                    strings: {
                        loadingLabel: "Ładowanie...",
                    },
                    autoLoad: true,
                    ignoreGPanoXMP: true,
                    pitch: this.panorama.defaultPitch,
                    yaw: this.panorama.defaultYaw,
                    northOffset: this.panorama.northOffset,
                    hfov: this.panorama.hfov ? this.panorama.hfov : 120,
                    type: "equirectangular",
                    panorama: this.staticFileUrl(this.panorama.filePath),
                });
            },

            rewriteViewerData() {
                const {viewer} = this;
                this.panorama.defaultPitch = viewer.getPitch();
                this.panorama.defaultYaw = viewer.getYaw();
                this.panorama.hfov = viewer.getHfov();
            },
        },

        created() {
            this.refresh();
        },
    };
</script>

<style scoped>
    #panorama-preview {
        width: 800px;
        height: 450px;
        margin: 0 auto;
    }

    h3 {
        text-align: left;
    }
</style>
