export default class PairOfCoordinates {
    constructor(startPoint, endPoint) {
        this.startPoint = startPoint;
        this.endPoint = endPoint;
    }

    distance() {
        const R = 6371000;
        const phi1 = this.startPoint.lat * Math.PI / 180;
        const phi2 = this.endPoint.lat * Math.PI / 180;
        const deltaPhi = phi2 - phi1;
        const deltaLambda = (this.endPoint.lng - this.startPoint.lng) * Math.PI / 180;

        const a = Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2)
            + Math.cos(phi1) * Math.cos(phi2)
            * Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c;
    }

    direction() {
        const phi1 = this.startPoint.lat * Math.PI / 180;
        const phi2 = this.endPoint.lat * Math.PI / 180;
        const lambda1 = this.startPoint.lng * Math.PI / 180;
        const lambda2 = this.endPoint.lng * Math.PI / 180;

        const y = Math.sin(lambda2 - lambda1) * Math.cos(phi2);
        const x = Math.cos(phi1) * Math.sin(phi2)
            - Math.sin(phi1) * Math.cos(phi2) * Math.cos(lambda2 - lambda1);
        const phi = Math.atan2(y, x);
        return (phi * 180 / Math.PI + 360) % 360;
    }
}
