<template>
    <div>
        <Toast/>
        <h2 class="page-header"><i class="pi pi-list p-mr-3"></i>{{headerTitle}} {{quizHeader}}</h2>

        <ValidationObserver ref="formObserver">
            <div class="p-col-4">
                <CustomInputText name="plContent" :label="$t('label.plContent')"
                                 v-model="quizQuestion.plContent" rules="required"/>
                <CustomInputText name="enContent" :label="$t('label.enContent')"
                                 v-model="quizQuestion.enContent" rules="required"/>
            </div>
        </ValidationObserver>

        <Toolbar class="actions p-ml-2 p-mt-3">
            <template slot="left">
                <Button :label="$t('button.saveQuizQuestionData')" icon="pi pi-plus" @click="saveQuizQuestion"/>
            </template>
        </Toolbar>
    </div>
</template>


<script>
    import Button from "primevue/components/button/Button";
    import Toolbar from "primevue/components/toolbar/Toolbar";
    import CustomInputText from "@/components/form/CustomInputText";
    import Toast from "primevue/toast";
    import {ValidationObserver} from "vee-validate";
    import {
        createOrUpdateQuizQuestionUsingPOST as createOrUpdateQuizQuestion,
        getQuizQuestionUsingGET as getQuizQuestion,
        getQuizNamesUsingGET as getQuizNames,
    } from "@/swagger/vue-api-client";

    export default {
        name: "QuizFormView",
        components: {
            Button, Toolbar, CustomInputText, Toast, ValidationObserver,
        },

        data() {
            return {
                quizNames: null,
                headerTitle: this.$t("menu.quizzes.questions.add"),
                quizQuestion: {
                    id: null,
                    quizId: null,
                    plContent: null,
                    enContent: null,
                },
            };
        },

        methods: {
            saveQuizQuestion() {
                this.$refs.formObserver.validate().then((valid) => {
                    if (!valid) {
                        return;
                    }
                    createOrUpdateQuizQuestion({quizQuestionDto: this.quizQuestion}).then(() => {
                        this.$router.go(-1);
                        this.$eventBus.$emit("success", this.$t("snackbar.questionAddSuccess"));
                    });
                });
            },
        },

        computed: {
            quizHeader() {
                if (this.quizNames) {
                    return "(Quiz: " + (this.appLang === "pl" ? this.quizNames.plName : this.quizNames.enName) + ")";
                }
                return "";
            },
        },

        created() {
            if (this.$route.params.id) {
                getQuizQuestion({id: this.$route.params.id}).then((response) => {
                    this.quizQuestion = response.data;
                });
                this.headerTitle = this.$t("menu.quizzes.questions.edit");
            }
            this.quizQuestion.quizId = this.$route.params.quizId;
            getQuizNames({quizId: this.quizQuestion.quizId}).then((response) => {
                this.quizNames = response.data;
            });
        },
    };
</script>

<style scoped>

</style>
