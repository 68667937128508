<template>
    <div class="select-language">
        <CustomSelectOne name="language" :items="items" v-model="internalValue" item-label="value"
                         item-value="value" :empty-value="false" @input="inputListener"/>
    </div>
</template>

<script>
    import CustomSelectOne from "./inner/CustomSelectOne";

    export default {
        name: "CustomSelectLanguage",

        data() {
            return {
                internalValue: this.$i18n.locale,
                items: [
                    {
                        value: "pl",
                    },
                    {
                        value: "en",
                    },
                ],
            };
        },

        methods: {
            inputListener(value) {
                this.$root.$i18n.locale = value;
                this.$cookie.set("lang", value);
                document.getElementById("app").setAttribute("data-reclick-bottom-bar", "true");
                /* TODO może kiedyś przerobić to na przeładowywanie odpowiednich komponentów,
                    na ten moment niech będzie tak */
                window.location.reload();
            },
        },

        components: {CustomSelectOne},
    };
</script>

<style lang="less">
    .select-language {
        margin-top: -15px;

        label {
            display: none;
        }
    }
</style>
