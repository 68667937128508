<template>
    <div style="height: 100%">
        <div id="panorama" style="height: 100%">
            <div id="tooltip" style="position: absolute; display: none;"></div>
            <div class="panorama-heading" v-if="mode !== 'without-controls'">
                <div class="main-container-heading">
                    <div class="panorama-main-container-heading-inner">
                        <router-link :to="{name: 'public'}" class="home" :title="titleBackToHome">
                            <i class="pi pi-home"></i>
                            <span class="visually-hidden">{{ $t('label.mainPage') }}</span>
                        </router-link>
                        <router-link :to="{name: 'areaView', params: {id: superareaId}}"
                                     v-if="superareaId"
                                     :title="titleBack">
                            <div class="center-con arrows">
                                <div class="round">
                                    <div id="cta">
                                        <span class="arrow primera next ">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="24" height="24" viewBox="0 0 24 24">
                                                <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/>
                                            </svg>
                                        </span>
                                        <span class="arrow segunda next ">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="24" height="24" viewBox="0 0 24 24">
                                                <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/>
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                        <router-link :to="{
                                         name: 'map',
                                         params: {id: currentPoi.areaId, mode: 'przystanek', pointNumber: currentPoi.id}
                                     }"
                                     v-if="currentAreaHasMap"
                                     :title="titleBackToMap">
                            <div class="center-con">
                                <div class="round">
                                    <div id="cta">
                                        <span class="arrow primera next ">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="24" height="24" viewBox="0 0 24 24">
                                                <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/>
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                        <div class="heading-toolbar-mobile">
                            <AccessibilityToolbar/>
                            <CustomSimpleChangeLanguage/>
                        </div>
                    </div>
                    <h1 class="panorama-title" :key="'title' + currentPoiId + $i18n.locale"
                        v-if="mode !== 'without-controls'">{{ currentPoiTitle }}</h1>
                </div>
                <div class="panorama-acc-lang">
                    <AccessibilityToolbar/>
                    <CustomSimpleChangeLanguage/>
                </div>
                <div id="path-header" v-if="currentPath" :class="{'last': !nextPoiOnPathId}">
                    <div class="path-header-nav">
                        <div
                            class="path-header-prev"
                            v-if="previousPoiOnPathId"
                            @click="changePoiOnPath(previousPoiOnPathId, currentPath.id)">
                            <span class="visually-hidden">{{ $t('label.previous') }}</span>
                            <div class="center-con">
                                <div class="round">
                                    <div id="cta">
                                        <span class="arrow primera next ">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="24" height="24" viewBox="0 0 24 24">
                                                <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/>
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span class="clicking-hides-bottom-bar">
                            {{ $t('label.POI') }} {{ indexOnPath }} / {{ currentPath.poiIds.length }}
                        </span>
                        <div class="path-header-next"
                             v-if="nextPoiOnPathId"
                             @click="changePoiOnPath(nextPoiOnPathId, currentPath.id)">
                            <span class="visually-hidden">{{ $t('label.next')}}</span>
                            <div class="center-con">
                                <div class="round">
                                    <div id="cta">
                                        <span class="arrow primera next ">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="24" height="24" viewBox="0 0 24 24">
                                                <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/>
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2>{{ $t('label.path') }}: {{ currentPath.langData[appLang].name }}</h2>
                </div>
            </div>
            <PanoramaBottomBar :key="'bottom-bar' + currentPoiId + $i18n.locale"
                               v-if="mode !== 'without-controls' && Object.keys(currentPoi).length > 0"
                               :poi-data="currentPoi" :poi-list="poiListData"
                               :current-area-has-map="currentAreaHasMap"
            />
        </div>
    </div>
</template>

<script>
    import PanoramaBottomBar from "@/components/PanoramaBottomBar";
    import {
        getDetailedPOIListDataUsingGET as getDetailedPOIListData,
        getDetailedAreaListDataUsingGET as getDetailedAreaListData,
        getDetailedPathListDataUsingGET as getDetailedPathListData,
    } from "@/swagger/vue-api-client";
    import PanoramaHotspot from "@/utils/PanoramaHotspot";
    import Path from "@/utils/Path";
    import AccessibilityToolbar from "@/components/AccessibilityToolbar";
    import CustomSimpleChangeLanguage from "@/components/CustomSimpleChangeLanguage";
    import {OfflineData} from "@/offline/OfflineData";
    import {HTMLUtils} from "@/utils/HTMLUtils";

    export default {
        name: "PanoramaView",
        components: {
            PanoramaBottomBar,
            AccessibilityToolbar,
            CustomSimpleChangeLanguage,
        },

        data() {
            return {
                currentPoiId: -1,
                poiTitle: {},
                currentPoi: {},
                mode: null,
                viewer: null,
                poiListData: null,
                superareaId: null,
                currentAreaHasMap: false,
                currentPath: null,
                currentPathObject: null,
                indexOnPath: null,
                nextPoiOnPathId: null,
                previousPoiOnPathId: null,
                lastPoiFromPathId: null,
                areaListIndexed: {},
                hotspotTooltipCurrentlyDescribingId: -1,
            };
        },
        computed: {
            currentPoiTitle() {
                return this.poiTitle[this.$i18n.locale];
            },
            titleBackToMap() {
                return this.appLang === "pl" ? "Powrót do mapy" : "Back to map";
            },
            titleBack() {
                return this.appLang === "pl" ? "Powrót do nadobszaru" : "Back";
            },
            titleBackToHome() {
                return this.appLang === "pl" ? "Strona główna" : "Home page";
            },
        },

        watch: {
            // eslint-disable-next-line func-names
            "$i18n.locale": function () {
                this.setTooltipLangContent();
            },
            // eslint-disable-next-line func-names
            "$route.params.id": function (poiId) {
                this.goToPanorama(poiId, this.$route.params.pathId ? this.$route.params.pathId : null);
            },
        },

        mixins: [HTMLUtils],

        created() {
            this.mode = typeof this.$route.params.mode !== "undefined" ? this.$route.params.mode : null;
        },

        mounted() {
            this.loadPoiDataAndKeepGoing();
            document.getElementById("panorama").addEventListener(
                "touchend",
                (event) => {
                    if (!event.doNotHideHotspotTooltip && event.target.id !== "tooltip") {
                        this.hotspotTooltipCurrentlyDescribingId = -1;
                        HTMLUtils.methods.hideTooltip();
                    }
                },
            );
            document.getElementById("tooltip").addEventListener(
                "click",
                () => {
                    if (this.hotspotTooltipCurrentlyDescribingId !== -1) {
                        this.hotspotClick(null, {id: this.hotspotTooltipCurrentlyDescribingId});
                    }
                },
            );
        },

        destroyed() {
            this.$off();
        },

        methods: {
            loadPoiDataAndKeepGoing() {
                getDetailedPOIListData().then((response) => {
                    this.poiListData = response.data;
                }).catch(() => {
                    this.poiListData = OfflineData.getDetailedPoiListData();
                }).then(() => {
                    this.loadAreaDataAndKeepGoing();
                });
            },

            loadAreaDataAndKeepGoing() {
                getDetailedAreaListData().then((response) => {
                    this.areaList = response.data;
                }).catch(() => {
                    this.areaList = OfflineData.getDetailedPoiListData();
                }).then(() => {
                    if (this.mode === "sciezka") {
                        this.loadPathDataAndKeepGoing();
                    } else {
                        this.processData();
                    }
                });
            },

            loadPathDataAndKeepGoing() {
                getDetailedPathListData().then((response) => {
                    this.pathListData = response.data;
                }).catch(() => {
                    this.pathListData = OfflineData.getDetailedPathListData();
                }).then(() => {
                    this.processData();
                });
            },

            processData() {
                this.indexAreaList();
                this.reloadData(this.$route.params.id);
                this.preparePOIMap(this.poiListData);
                if (this.mode === "sciezka") {
                    this.preparePathDataAndViewer(
                        this.$route.params.pathId,
                        this.$route.params.lastPoiFromPath
                            ? this.$route.params.lastPoiFromPath
                            : null,
                    );
                } else {
                    this.resetPathData();
                    this.prepareViewer();
                }
                this.setListeners();
                this.findAndSetSuperareaIdAndCheckCurrentAreaMap();
            },

            indexAreaList() {
                for (let i = 0; i < this.areaList.length; i += 1) {
                    const area = this.areaList[i];
                    this.areaListIndexed[area.id] = area;
                }
            },

            resetPathData() {
                this.currentPath = null;
                this.currentPathObject = null;
                this.indexOnPath = null;
                this.nextPoiOnPathId = null;
                this.previousPoiOnPathId = null;
                this.lastPoiFromPathId = null;
            },

            preparePathDataAndViewer(pathId, lastPoiFromPathId) {
                this.preparePathData(pathId, lastPoiFromPathId);
                this.prepareViewer();
            },

            preparePathData(pathId, lastPoiFromPathId) {
                this.currentPath = this.pathListData.find(path => path.id.toString() === pathId.toString());
                this.currentPathObject = new Path(this.currentPath);
                const {poiIds} = this.currentPath;
                const poiIndex = poiIds.findIndex(poiId => poiId === this.currentPoi.id);

                if (poiIndex < 0) {
                    this.currentPath = null;
                    this.currentPathObject = null;
                    this.lastPoiFromPathId = lastPoiFromPathId;
                } else {
                    this.indexOnPath = poiIndex + 1;
                    this.setPreviousAndNextPoisOnPath(poiIds, poiIndex);
                }
            },

            setPreviousAndNextPoisOnPath(poiIds, poiIndex) {
                if (typeof poiIds[poiIndex - 1] !== "undefined") {
                    this.previousPoiOnPathId = poiIds[poiIndex - 1];
                } else {
                    this.previousPoiOnPathId = null;
                }
                if (typeof poiIds[poiIndex + 1] !== "undefined") {
                    this.nextPoiOnPathId = poiIds[poiIndex + 1];
                } else {
                    this.nextPoiOnPathId = null;
                }
            },

            setListeners() {
                if (this.mode === "dev") {
                    this.viewer.getContainer().addEventListener("mouseup", this.showCurrentViewerData);
                    this.viewer.getContainer().addEventListener("zoomchange", this.showCurrentViewerData);
                }
            },

            setTooltipLangContent() {
                const hotspots = document.querySelectorAll(".hotspot");
                hotspots.forEach((container) => {
                    const langData = container.querySelectorAll("*[data-lang]");
                    langData.forEach((div) => {
                        if (div.dataset.lang === this.appLang) {
                            div.classList.add("tooltip-content");
                        } else {
                            div.classList.remove("tooltip-content");
                        }
                    });
                });
            },

            reloadData(poiId) {
                this.findPoiData(poiId);
                this.poiTitle = {
                    pl: this.currentPoi.langData.pl.name,
                    en: this.currentPoi.langData.en.name,
                };
                this.currentPoiId = poiId;
            },

            findAndSetSuperareaIdAndCheckCurrentAreaMap() {
                this.findSuperareaId();
                this.checkCurrentAreaMap();
            },

            findSuperareaId() {
                const {areaId} = this.currentPoi;
                this.superareaId = this.areaList.find(area => area.id === areaId).parentId;
            },

            checkCurrentAreaMap() {
                const {areaId} = this.currentPoi;
                this.currentAreaHasMap = typeof this.areaList.find(area => area.id === areaId).maps[0] !== "undefined";
            },

            findPoiData(poiId) {
                for (let i = 0; i < this.poiListData.length; i += 1) {
                    const poi = this.poiListData[i];
                    if (poi.id.toString() === poiId.toString()) {
                        this.currentPoi = poi;
                        return;
                    }
                }
                this.currentPoi = {};
            },

            changePoiOnPath(targetPoiId, pathId) {
                this.$router.push({
                    name: "walk",
                    params: {
                        id: targetPoiId,
                        mode: "sciezka",
                        pathId,
                    },
                });
            },

            hotspotClick(event, args) {
                if (event) {
                    event.doNotHideHotspotTooltip = true;
                    if (event.type !== "click") {
                        return;
                    }
                }
                if (this.hotspotTooltipCurrentlyDescribingId === args.id
                    || !this.isMobile()) {
                    this.$router.push({
                        name: "walk",
                        params: args,
                    });
                } else {
                    HTMLUtils.methods.showTooltip(event);
                    this.hotspotTooltipCurrentlyDescribingId = args.id;
                }
            },

            goToPanorama(poiId, pathId) {
                this.hotspotTooltipCurrentlyDescribingId = -1;
                HTMLUtils.methods.hideTooltip();
                this.reloadData(poiId);
                if (typeof pathId !== "undefined" && pathId !== null) {
                    this.preparePathData(pathId);
                } else {
                    this.resetPathData();
                }
                this.viewer.loadScene("poi-" + poiId);
            },

            preparePOIMap(poiList) {
                this.poiMap = {};
                for (let i = 0; i < poiList.length; i += 1) {
                    this.poiMap[poiList[i].id] = poiList[i];
                }
            },

            generateScenes(poiList) {
                // eslint-disable-next-line
                let scenes = {};
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < poiList.length; i++) {
                    if (poiList[i].lat) {
                        scenes["poi-" + poiList[i].id] = this.generateScene(poiList[i]);
                    }
                }
                return scenes;
            },

            generateScene(poi) {
                // if (this.$route.params.id.toString() === poi.id.toString()) {
                //     console.log(poi);
                //     console.log(PanoramaHotspot.defaultHFOV(poi.panoramas[0].hfov, this.isMobile()));
                // }
                if (typeof poi.panoramas[0] === "undefined") {
                    return {};
                }
                return {
                    autoLoad: true,
                    ignoreGPanoXMP: true,
                    pitch: poi.panoramas[0].defaultPitch,
                    yaw: poi.panoramas[0].defaultYaw,
                    northOffset: poi.panoramas[0].northOffset,
                    hfov: PanoramaHotspot.defaultHFOV(poi.panoramas[0].hfov, this.isMobile()),
                    type: "equirectangular",
                    panorama: this.staticFileUrl(poi.panoramas[0].filePath),
                    hotSpots: this.generateHotspots(poi.neighbours, poi),
                };
            },

            generateHotspots(neighbours, startPOI) {
                if (this.mode === "without-controls" || this.mode === "sciezka") {
                    return [];
                }
                let hotspots = [];
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < neighbours.length; i++) {
                    const neighbourData = neighbours[i];
                    neighbourData.area = this.findNeighbourArea(neighbourData.id);
                    const poi = this.poiMap[neighbourData.id];
                    if (poi.lat) {
                        hotspots.push(this.generateHotspot(startPOI, poi, neighbourData));
                    }
                }
                if (this.mode === "dev" && this.currentPoi.id === startPOI.id) {
                    const yaw = startPOI.panoramas[0].northOffset;
                    hotspots = hotspots.concat(
                        this.generateControlHotspots(yaw, 10),
                        this.generateControlAdditionalHotspots(yaw, 10),
                    );
                }
                return hotspots;
            },

            findNeighbourArea(neighbourId) {
                const poi = this.poiMap[neighbourId];
                return this.areaListIndexed[poi.areaId];
            },

            generateHotspot(startPOI, poi, neighbourAdditionalData) {
                const hotspot = new PanoramaHotspot(startPOI, poi, neighbourAdditionalData, this);
                if (this.currentPathObject !== null) {
                    hotspot.pathId = this.currentPath.id;
                    const previousPoiOnPathId = this.currentPathObject.getNeighbourPoiId(startPOI.id, -1);
                    const nextPoiOnPathId = this.currentPathObject.getNeighbourPoiId(startPOI.id, 1);
                    if (poi.id === previousPoiOnPathId) {
                        hotspot.relativePositionOnPath = -1;
                    } else if (poi.id === nextPoiOnPathId) {
                        hotspot.relativePositionOnPath = 1;
                    } else {
                        hotspot.lastPoiFromPathId = startPOI.id;
                    }
                }
                return hotspot.getHotspotObject();
            },

            generateControlHotspots(yaw, interval) {
                const hotspots = [];
                for (let direction = 0; direction < 360; direction += interval) {
                    hotspots.push(this.generateControlHotspot(direction));
                }
                return hotspots;
            },

            generateControlHotspot(direction) {
                return {
                    pitch: 0.8,
                    yaw: direction,
                    type: "info",
                    text: direction + "°",
                    createTooltipFunc: this.setHotspotContent,
                    createTooltipArgs: {
                        direction,
                    },
                    cssClass: "hotspot-direction",
                };
            },

            generateControlAdditionalHotspots(yaw, interval) {
                const hotspots = [];
                for (let direction = 0; direction < 360; direction += 1) {
                    hotspots.push(this.generateControlAdditionalHotspot(
                        direction,
                        direction % (interval / 2) === 0,
                    ));
                }
                return hotspots;
            },

            generateControlAdditionalHotspot(direction, marked) {
                return {
                    pitch: -0,
                    yaw: direction,
                    type: "info",
                    text: "•",
                    createTooltipFunc: this.setHotspotContent,
                    createTooltipArgs: {
                        direction: "•",
                    },
                    cssClass: "hotspot-direction-dot" + (marked ? " hotspot-direction-dot-marked" : ""),
                };
            },

            setHotspotContent(element, params) {
                element.innerHTML = params.direction;
            },

            prepareViewer() {
                this.viewer = this.$pannellum.viewer("panorama", {
                    default: {
                        firstScene: "poi-" + this.$route.params.id,
                        sceneFadeDuration: 2500,
                    },
                    strings: {
                        loadingLabel: "Ładowanie...",
                    },
                    showControls: this.mode !== "without-controls",

                    scenes: this.generateScenes(this.poiListData),
                });
                this.setTooltipLangContent();
            },

            showCurrentViewerData() {
                const pitch = this.viewer.getPitch().toFixed(1);
                const yaw = this.viewer.getYaw().toFixed(1);
                const hfov = this.viewer.getHfov();
                const textToCopy = "("
                    + this.currentPoi.number
                    + ","
                    + pitch
                    + ","
                    + yaw
                    + ","
                    + hfov
                    + "),\n";
                this.copyToClipboard(textToCopy);
                // console.log(this.viewer.mouseEventToCoords(event));
            },
        },
    };
</script>

<style lang="less">
    @import "../assets/less/maps-and-panoramas.less";
    @import "../../assets/theme/variable.less";

    #tooltip {
        background: @orange-light-opacity;
        color: white;
        border: none;
        padding: 5px;
        z-index: 100;
        font-family: 'Chivo', "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
    #panorama {
        width: 100vw;
        height: 100vh;
        #path-header {
            display: flex;
            width: 100%;
            align-items: center;
            position: relative;
            margin-top: 13px;
            font-family: 'Chivo', "Helvetica Neue", Helvetica, Arial, sans-serif;
            &.last {
                h2 {
                    margin-left: 82px;
                }
            }
            h2 {
                margin: 0 0 0 20px;
                line-height: 1.2;
                font-size: 16px;
                font-weight: normal;
                font-family: 'Chivo', "Helvetica Neue", Helvetica, Arial, sans-serif;
            }
            &:after {
                content: "";
                display: block;
                position: absolute;
                left: -15px;
                right: -15px;
                height: 1px;
                background-color: @brown-light;
                top: -9px;
            }
            .path-header-next {
                margin: 0 0 0 20px;
                .center-con {
                    transform: none;
                }
            }
            .path-header-nav {
                display: flex;
                #cta .arrow {
                    left: 37%;
                }
                .center-con:after {
                    content: '';
                    display: block;
                    width: 1px;
                    position: absolute;
                    top: 5%;
                    left: -4px;
                    bottom: -5%;
                    background-color: #c3a887;
                }
            }
        }
    }
    div.hotspot-direction {
        color: white;
        background: rgba(0, 0, 0, 0.5);
    }
    div.hotspot-direction-dot {
        color: white;
    }
    div.hotspot-direction-dot.hotspot-direction-dot-marked {
        color: red;
    }
    .hotspot-distance {
        font-size: 80%;
        text-align: right;
        margin-top: 5px;
    }
    .panorama-heading {
        position: fixed;
        top: 0;
        left: 38px;
        right: 0;
        background-color: rgba(35, 31, 32, 0.82);
        padding: 10px 15px;
        color: #fefefe;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        z-index: 2;
        h1 {
            margin: 0;
            font-size: 24px;
            line-height: 1;
            text-align: left;
            color: @rose;
            font-family: "Warnock";
            font-weight: bold;
        }
        .panorama-acc-lang {
            display: flex;
            align-items: center;
        }
        .main-container-heading {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .panorama-main-container-heading-inner {
                display: flex;
                align-items: center;
            }
            .pi-arrow-left,
            .pi-home,
            .pi-map-marker,
            .pi-table {
                color: @brown-light-2;
                font-size: 14px;
                margin-right: 20px;
                position: relative;
                transition: .2s all;
                &:after {
                    content: '';
                    display: block;
                    width: 1px;
                    position: absolute;
                    top: -21%;
                    right: -10px;
                    bottom: -21%;
                    background-color: @brown-light;
                }
                &:hover {
                    color: @brown-light;
                }
            }
        }
    }
    .heading-toolbar-mobile {
        display: none;
    }
    @media screen and (max-width: 767px) {
        #panorama {
            #path-header {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                &.last {
                    h2 {
                        margin-left: 0;
                    }
                }
                h2 {
                    text-align: left;
                    margin-left: 0;
                    margin-top: 4px;
                }
            }
        }
        .panorama-heading {
            flex-direction: column;
            align-items: flex-start;
            h1 {
                font-size: 20px;
                margin-top: 7px;
            }
        }
        .panorama-heading .panorama-acc-lang {
            display: none;
        }
        .panorama-heading .main-container-heading {
            display: block;
            width: 100%;
        }
        .panorama-main-container-heading-inner {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
        }
        .heading-toolbar-mobile {
            display: flex;
            margin-left: auto;
            align-items: center;
            .acc-group {
                p {
                    display: none;
                }
            }
        }
    }
.main-map-outer {
    @media screen and (max-width: 767px) {
        .heading-toolbar-mobile {
            display: flex;
            margin-left: 0;
            margin-bottom: 10px;
            align-items: center;
            .acc-group {
                p {
                    display: none;
                }
            }
        }
    }
}
</style>
