<template>
    <div>
        <div id="tooltip" style="position: absolute; display: none;"></div>
        <div class="wrapper">
            <div class="main-map-outer" id="out">
                <div class="main-map-heading">
                    <div class="heading-toolbar-mobile">
                        <AccessibilityToolbar/>
                        <CustomSimpleChangeLanguage/>
                    </div>
                    <h1>{{ title }}</h1>
                    <div class="acc-lang">
                        <AccessibilityToolbar/>
                        <CustomSimpleChangeLanguage/>
                    </div>
                    <div
                        class="thematic-path-button-outer"
                        :class="{ langEn: appLang !== 'pl'}">
                        <modal
                            name="thematic-path-modal"
                            scrollabel="true"
                            height="auto"
                            :adaptive="true"
                            class="thematic-path-modal"
                            @before-open="beforeOpenModalTepathicPaths"
                            @before-close="beforeCloseModalTepathicPaths">
                            <div class="thematic-path-modal-content">
                                <ThematicPaths />
                            </div>
                        </modal>
                        <button
                            type="button"
                            @click="hideModalTepathicPaths"
                            class="close-modal">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path d="M12 10.6L6.6 5.2 5.2 6.6l5.4 5.4-5.4 5.4
                                        1.4 1.4 5.4-5.4 5.4 5.4 1.4-1.4-5.4-5.4 5.4-5.4-1.4-1.4-5.4 5.4z">
                                </path>
                            </svg>
                            <div class="visually-hidden">zamknij ścieżki tematyczne</div>
                        </button>
                        <button
                            type="button"
                            class="thematic-path-button"
                            @click="showModalTepathicPaths"
                            @keyup.enter="showModalTepathicPaths">
                            <!-- eslint-disable -->
                            <lord-icon
                                palette="#eac587;#eac587"
                                animation="hover"
                                icon="pinOnMap">
                            </lord-icon>
                            <div class="visually-hidden">Ścieżki tematyczne</div>
                        </button>
                    </div>
                </div>
                <div class="info-outher">
                    <modal name="info-modal"
                           :height="625"
                           :adaptive="true"
                           class="info-text-modal"
                           @before-open="beforeOpenModalInfo"
                           @before-close="beforeCloseModalInfo">
                        <div class="info-modal-content">
                            <AboutAppInfo />
                        </div>
                    </modal>
                    <button @click="closeModalInfo()" class="text-info-modal-close">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fill="#fff" d="M12 10.6L6.6 5.2 5.2 6.6l5.4 5.4-5.4 5.4 1.4 1.4 5.4-5.4
                                    5.4 5.4 1.4-1.4-5.4-5.4 5.4-5.4-1.4-1.4-5.4 5.4z"></path>
                        </svg>
                        <div class="visually-hidden">zamknij informacje o aplikacji</div>
                    </button>
                    <div tabindex="0" class="info" @click="showModalInfo()" @keyup.enter="showModalInfo()">
                        <i class="pi pi-info-circle"></i>
                    </div>
                </div>
                <a class="author" href="//vavatech.pl">{{ author }}</a>
                <div class="key legend">
                    <span>{{ mapKey }}</span>
                    <div class="legend-list">
                        <ul>
                            <li>
                                <div id="key-stalagi" class="key-element">
                                    <div id="key-stalagi-square" class="key-stalagi"></div>
                                    <div class="key-stalagi">{{ postCampGrounds }}</div>
                                </div>
                            </li>
                            <li>
                                <div id="key-cmentarze" class="key-element">
                                    <div id="key-cmentarze-square" class="key-cmentarze"></div>
                                    <div class="key-cmentarze">{{ cemeteries }}</div>
                                </div>
                            </li>
                            <li>
                                <div id="key-muzeum" class="key-element">
                                    <div id="key-muzeum-square" class="key-muzeum"></div>
                                    <div class="key-muzeum">{{ museum }}</div>
                                </div>
                            </li>
                            <li>
                                <div id="key-poligon" class="key-element">
                                    <div id="key-poligon-square" class="key-poligon"></div>
                                    <div class="key-poligon">{{ militaryTrainingGround }}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-dragscroll class="main-map resizable-container" style="position: relative"
                     data-how-much-right="0.63" data-how-much-bottom="0.5">
                    <img src="@/assets/caly-teren.jpg" alt="" class="resizable"/>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         viewBox="0 0 2209 1135" style="position: absolute; top: 0; left: 0;"
                    >
                        <defs>
                            <pattern id="white-lines" patternUnits="userSpaceOnUse"
                                     width="19.5" height="19.5" patternTransform="rotate(50)">
                                <line x1="0" y1="0" x2="0" y2="19.5" stroke="orange" stroke-width="1"/>
                            </pattern>
                        </defs>
                        <g
                            id="layer4"
                            style="display:inline;opacity:1"
                            transform="translate(0,-100)">
                            <image xlink:href="@/assets/caly-teren.jpg"
                                   x="0" y="0" width="2209" height="1335"/>
                        </g>
                        <g
                            id="layer3"
                            style="display:inline;opacity:1"
                            transform="translate(185.96428,1.7039926)">
                            <!-- eslint-disable -->
                            <path
                                d="m 528.69972,369.85465 c -6.33315,13.44422 36.48627,38.67424 35.70989,61.06226 -0.9106,26.25869 -102.95207,174.1237 -94.5709,197.26606 6.35047,17.53512 63.39343,12.09428 69.19725,28.72256 5.63959,16.15778 -48.09579,129.31133 -69.59275,171.18609 -10.0681,19.61204 -11.07282,42.92748 -12.63953,64.91712 -2.78266,39.05626 5.87577,117.31876 5.87577,117.31876 1.33609,26.6772 -14.77418,49.302 -7.45388,84.5382 l 211.3778,-113.90572 c 0,0 17.01225,77.20612 44.58356,94.82992 29.77003,19.0292 183.90896,-18.9807 183.90896,-18.9807 l 18.49501,77.778 510.3835,2.2868 604.3664,-0.4252 -3.5157,-276.57137 -501.0567,-338.03221 c 0,0 30.138,-21.8346 59.2453,-44.62397 -2.1564,-9.44728 -20.9485,-2.36736 -28.5101,-5.68205 -4.2928,-1.88179 -2.0083,-9.42495 -5.3613,-12.45683 -15.6297,-14.13266 -93.8224,-64.42135 -125.0747,-69.8282 -78.0011,-13.49469 -237.5385,-43.20543 -357.7172,-45.53508 -101.38117,-1.96526 -202.27707,15.22883 -303.0276,26.68895 -28.48124,3.23967 -85.16918,11.88703 -85.16918,11.88703 -8.90738,1.2432 -44.60839,-28.8746 -52.13381,-31.30179 -11.88189,-3.83229 -90.16586,3.67413 -97.32009,18.86137 z"
                                id="poligon"
                                class="poligon"
                                v-if="areasLoaded"
                                :data-tooltip="areasBySlugs['poligon'].langData[appLang].name"
                                @mouseover="showTooltip($event)" @mouseleave="hideTooltip()" @click="goToArea('poligon')"
                            />
                        </g>
                        <g
                            id="layer2"
                            style="display:inline;"
                            transform="translate(185.96428,1.7039926)"
                        >
                            <path
                                d="m 744.21613,837.20846 47.2609,62.29844 90.94142,-38.66802 -52.98949,-59.43415 z"
                                id="muzeum"
                                class="muzea"
                                v-if="areasLoaded"
                                :data-tooltip="areasBySlugs['muzeum'].langData[appLang].name"
                                @mouseover="showTooltip($event)" @mouseleave="hideTooltip()" @click="goToArea('muzeum')"
                            />
                            <path
                                d="m 655.28784,375.94852 -85.02757,36.86992 -3.14708,12.33593 13.22916,1.88704 91.36113,-41.18494 z"
                                id="stalag-lamsdorf-russenlager"
                                class="stalagi"
                                v-if="areasLoaded"
                                :data-tooltip="areasBySlugs['stalag-lamsdorf-russenlager'].langData[appLang].name"
                                @mouseover="showTooltip($event)" @mouseleave="hideTooltip()" @click="goToArea('stalag-lamsdorf-russenlager')"
                            />
                            <path
                                d="m 552.59093,377.07346 2.22841,-1.95903 1.03584,-1.81271 v 0 l -0.0888,-2.20758 c -0.23727,0.0852 0.14325,-0.50609 -0.13446,-0.50751 -0.74073,-0.004 -2.206,0.38865 -2.7452,0.37609 -1.33023,-0.0311 -2.98871,0.29323 -4.4372,0.67775 l -3.28221,1.67338 1.62699,2.7169 2.2496,0.52744 z"
                                id="cmentarz-jencow-radzieckich"
                                class="cmentarze"
                                v-if="areasLoaded"
                                :data-tooltip="areasBySlugs['cmentarz-jencow-radzieckich'].langData[appLang].name"
                                transform="scale(2.5)"
                                transform-origin="551px 374px"
                                @mouseover="showTooltip($event)" @mouseleave="hideTooltip()" @click="goToArea('cmentarz-jencow-radzieckich')"
                            />
                            <path
                                d="m 686.93019,652.4613 25.25564,-10.44814 -14.30511,-21.66148 -25.98809,9.19524 z"
                                id="cmentarz-ofiar-obozu-pracy"
                                class="cmentarze"
                                v-if="areasLoaded"
                                :data-tooltip="areasBySlugs['cmentarz-ofiar-obozu-pracy'].langData[appLang].name"
                                @mouseover="showTooltip($event)" @mouseleave="hideTooltip()" @click="goToArea('cmentarz-ofiar-obozu-pracy')"
                            />
                            <path
                                d="m 1547.1313,484.88763 15.6,5.97035 15.4054,-12.35082 -16.0715,-3.65327 z"
                                id="stary-cmentarz-jeniecki"
                                class="cmentarze"
                                v-if="areasLoaded"
                                :data-tooltip="areasBySlugs['stary-cmentarz-jeniecki'].langData[appLang].name"
                                @mouseover="showTooltip($event)" @mouseleave="hideTooltip()" @click="goToArea('stary-cmentarz-jeniecki')"
                            />
                            <path
                                d="m 1490.6817,542.3492 69.8743,-50.35179 -17.4202,-6.61747 14.3882,-9.91909 -5.4137,-1.30368 -1.1353,-2.27059 -15.8525,5.79219 -19.3614,-4.97165 -30.6127,-12.23704 -86.3732,-10.68969 -33.0041,10.51362 87.4583,55.92195 8.0736,-5.90226 18.3497,11.62693 -7.8525,6.98417 z"
                                id="stalag-lamsdorf-britenlager"
                                class="stalagi"
                                v-if="areasLoaded"
                                :data-tooltip="areasBySlugs['stalag-lamsdorf-britenlager'].langData[appLang].name"
                                @mouseover="showTooltip($event)" @mouseleave="hideTooltip()" @click="goToArea('stalag-lamsdorf-britenlager')"
                            />
                            <path
                                d="m 1536.8445,464.0999 3.7515,9.1348 9.6296,-3.61072 -3.6603,-8.71604 z"
                                id="path3717-5"
                                class="stalagi"
                                v-if="areasLoaded"
                                :data-tooltip="areasBySlugs['stalag-lamsdorf-britenlager'].langData[appLang].name"
                                @mouseover="showTooltip($event)" @mouseleave="hideTooltip()" @click="goToArea('stalag-lamsdorf-britenlager')"
                            />
                        </g>
                    </svg>
                </div>
            </div>
            <div class="regions">
                <div tabindex="0" v-dragscroll class="region-item-outer resizable-container region-stalagi" :style="'background-image: url(\''+ staticFileUrl('/maps/tereny-poobozowe.jpg') + '\' )'" @click="goToArea('tereny-poobozowe')" @keyup.enter="goToArea('tereny-poobozowe')">
                    <h2 v-if="areasLoaded">{{ areasBySlugs['tereny-poobozowe'].langData[appLang].name }}</h2>
                </div>
                <div tabindex="0" v-dragscroll class="region-item-outer resizable-container region-cmentarz" :style="'background-image: url(\''+ staticFileUrl('/maps/cmentarze.jpg') + '\' )'" @click="goToArea('cmentarze')" @keyup.enter="goToArea('cmentarze')">
                    <h2 v-if="areasLoaded">{{ areasBySlugs['cmentarze'].langData[appLang].name }}</h2>
                </div>
                <div tabindex="0" v-dragscroll class="region-item-outer resizable-container region-muzeum" :style="'background-image: url(\''+ staticFileUrl('/maps/muzeum.jpg') + '\' )'" @click="goToArea('muzeum')" @keyup.enter="goToArea('muzeum')">
                    <h2 v-if="areasLoaded">{{ areasBySlugs['muzeum'].langData[appLang].name }}</h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getDetailedAreaListDataUsingGET as getDetailedAreaListData,
    } from "@/swagger/vue-api-client";
    import {dragscroll} from "vue-dragscroll";
    import {HTMLUtils} from "@/utils/HTMLUtils";
    import {OfflineData} from "@/offline/OfflineData";
    import CustomSimpleChangeLanguage from "@/components/CustomSimpleChangeLanguage";
    import AccessibilityToolbar from "@/components/AccessibilityToolbar";
    import ThematicPaths from "@/views/public/components/ThematicPaths";
    import AboutAppInfo from "@/views/public/components/AboutAppInfo";

    export default {
        name: "PublicView",
        components: {
            CustomSimpleChangeLanguage, AccessibilityToolbar, ThematicPaths, AboutAppInfo,
        },
        directives: {
            dragscroll,
        },

        data() {
            return {
                publicPath: process.env.BASE_URL,
                areas: [],
                areasBySlugs: {},
                areasLoaded: false,
                langData: {
                    pl: {
                        title: "Muzeum i Miejsce Pamięci Narodowej w Łambinowicach. Wirtualne zwiedzanie",
                    },
                    en: {
                        title: "The Museum and the Site of National Remembrance in Łambinowice. Virtual Visit",
                    },
                },
            };
        },

        computed: {
            title() {
                return this.langData[this.appLang].title;
            },
            author() {
                return this.appLang === "pl" ? "Wykonanie:\r\n  Vavatech" : "Created by:\r\n  Vavatech";
            },
            mapKey() {
                return this.appLang === "pl" ? "Legenda:" : "Map Legend:";
            },
            postCampGrounds() {
                return this.appLang === "pl" ? "Tereny poobozowe" : "The Stalags";
            },
            cemeteries() {
                return this.appLang === "pl" ? "Cmentarze wojenne" : "The War Cemeteries";
            },
            museum() {
                return this.appLang === "pl" ? "Muzeum" : "The Museum";
            },
            militaryTrainingGround() {
                return this.appLang === "pl" ? "Dawny poligon" : "The Former Military \r\n Training Ground";
            },
        },

        mixins: [HTMLUtils],

        created() {
            document.addEventListener("dragstart", (e) => { e.preventDefault(); });
            getDetailedAreaListData().then((response) => {
                this.findAreasWithoutParentsAndIndex(response.data);
            }).catch(() => {
                this.findAreasWithoutParentsAndIndex(OfflineData.getDetailedAreaListData());
            }).then(() => {
            });
        },

        mounted() {
            this.resizeAndCenterImages();
            window.addEventListener("resize", this.resizeAndCenterImages);
            const self = this;
            document.getElementById("key-stalagi")
                .addEventListener("click", () => self.toggleAreaPolygon("stalagi"));
            document.getElementById("key-cmentarze")
                .addEventListener("click", () => self.toggleAreaPolygon("cmentarze"));
            document.getElementById("key-muzeum")
                .addEventListener("click", () => self.toggleAreaPolygon("muzea"));
            document.getElementById("key-poligon")
                .addEventListener("click", () => {
                    self.toggleAreaPolygon("poligon");
                    const poligonElement = document.getElementById("poligon");
                    if (poligonElement.style.getPropertyValue("stroke") === "none") {
                        poligonElement.style.removeProperty("stroke");
                    } else {
                        poligonElement.style.stroke = "none";
                    }
                });

            setTimeout(() => {
                // do obsługi qrcodów mobilnie
                if (OfflineData.getRedirectUrlPath()) {
                    this.$router.push({path: OfflineData.getRedirectUrlPath()});
                }
            }, 500);
        },

        destroyed() {
            this.$off();
        },

        methods: {
            findAreasWithoutParentsAndIndex(areaList) {
                for (let i = 0; i < areaList.length; i += 1) {
                    const area = areaList[i];
                    this.areasBySlugs[area.slug] = area;
                    if (area.parentId === null) {
                        this.areas.push(area);
                    }
                }
                this.areasLoaded = true;
            },

            goToArea(slug) {
                const area = this.areasBySlugs[slug];
                const areaId = area.id;
                if (typeof area.maps[0] !== "undefined") {
                    this.$router.push({
                        name: "map",
                        params: {
                            id: areaId,
                        },
                    });
                } else if (area.parentId === null) {
                    this.$router.push({
                        name: "areaView",
                        params: {
                            id: areaId,
                        },
                    });
                }
            },

            fontSizeDefault() {
            },

            fontSizeBigger() {
            },

            fontSizeBig() {
            },

            toggleAreaPolygon(cssClass) {
                const all = document.getElementsByClassName(cssClass);
                for (let i = 0; i < all.length; i += 1) {
                    if (all[i].style.fill === "none") {
                        all[i].style.removeProperty("fill");
                    } else {
                        all[i].style.fill = "none";
                    }
                }
            },
            showModalTepathicPaths() {
                this.$modal.show("thematic-path-modal");
            },
            hideModalTepathicPaths() {
                this.$modal.hide("thematic-path-modal");
            },
            beforeOpenModalTepathicPaths() {
                document.querySelector(".wrapper").classList.add("thematic-path-modal-shown");
            },
            beforeCloseModalTepathicPaths() {
                document.querySelector(".wrapper").classList.remove("thematic-path-modal-shown");
            },
            showModalInfo() {
                this.$modal.show("info-modal");
            },
            closeModalInfo() {
                this.$modal.hide("info-modal");
            },
            beforeOpenModalInfo() {
                document.querySelector(".wrapper").classList.add("info-modal-shown");
            },
            beforeCloseModalInfo() {
                document.querySelector(".wrapper").classList.remove("info-modal-shown");
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "../assets/less/public-view";
    #tooltip {
        background: #ff9100;
        color: white;
        border-radius: 5px;
        border: none;
        padding: 5px;
        opacity: 0.8;
        z-index: 100;
    }
    #poligon {
        fill: white;
        fill-opacity: 0;
        stroke: white;
        stroke-opacity: 0.8;
        stroke-width: 2;
        transition: .1s all;
    }
    #poligon:hover {
        fill-opacity: 0.05;
    }
    .muzea {
        //fill: #b80700;
        //fill: #f47121;
        //fill: #07a449;
        fill: @brown;
        fill-opacity: 0.8;
        transition: .2s all;
    }
    .stalagi {
        fill: @orange;
        fill-opacity: 0.8;
        transition: .2s all;
    }
    .cmentarze {
        fill: @rose;
        fill-opacity: 0.6;
        transition: .2s all;
    }
    svg path:hover {
        fill-opacity: 0.95;
    }
    svg path {
        cursor: pointer;
    }
</style>
