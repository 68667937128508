<template>
    <router-link :to="getRoute()"
                 :class="side + '-area-item'"
                 :style="'background-image: url(\''
                     + staticFileUrl('/maps/' + area.slug + '.jpg') + '\')'">
        <div>
            <h2 :class="side + '-area-main-img-title'">
                {{ area.langData[appLang].name }}
            </h2>
        </div>
    </router-link>
</template>

<script>
    export default {
        name: "SubareaTile",

        props: {
            area: Object,
            side: String,
        },

        methods: {
            getRoute() {
                if (this.area.maps[0]) {
                    return {name: "map", params: {id: this.area.id}};
                }
                return {
                    name: "walk",
                    params: {
                        id: this.area.poiList[0].id,
                    },
                };
            },
        },
    };
</script>

<style lang="less">
    @import "../../assets/less/area-view";
</style>
