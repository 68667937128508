<template>
    <div class="p-fluid custom-checkbox">
        <span>
            <Checkbox :id="name" v-model="internalValue" :binary="true" @input="inputListener"/>
            <label :for="name" class="p-ml-3">{{ label }}</label>
        </span>
    </div>
</template>

<script>
    import Checkbox from "primevue/components/checkbox/Checkbox";

    export default {
        name: "CustomCheckbox",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            value: null,
        },

        watch: {
            value: {
                immediate: true,
                handler(val) {
                    this.internalValue = val;
                },
            },
        },

        data() {
            return {internalValue: this.value};
        },

        methods: {
            inputListener(value) {
                this.$emit("input", value);
            },
        },

        components: {Checkbox},
    };
</script>

<style scoped>
    .custom-checkbox {
        margin-bottom: 20px;
    }
</style>
