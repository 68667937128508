<template>
    <div>
        <h2 class="page-header"><i class="pi pi-map-marker p-mr-3"></i>{{$t('menu.pois.adjacent')}}</h2>

        <Toolbar class="actions">
            <template slot="left">
                <Dropdown v-model="selectedPOI" :options="POIOptions"
                          :optionLabel="appLang === 'pl' ? 'plName' : 'enName'" :filter="true"
                          :placeholder="$t('button.choosePOI')" :showClear="true" dataKey="id">
                    <template #value="slotProps">
                        <div v-if="slotProps.value">
                            <div>{{appLang === 'pl' ? slotProps.value.plName : slotProps.value.enName}}</div>
                        </div>
                        <span v-else>
                            {{slotProps.placeholder}}
                        </span>
                    </template>
                    <template #option="slotProps">
                        <div>
                            <div>{{appLang === 'pl' ? slotProps.option.plName : slotProps.option.enName}}</div>
                        </div>
                    </template>
                </Dropdown>
                <Button v-if="role.isAdmin()" :label="$t('button.addPOI')" icon="pi pi-plus" class="p-ml-3"
                        @click="addToList()"/>
            </template>
        </Toolbar>

        <DataTable :value="selectedPOIList" dataKey="index" v-if="selectedPOIList.length > 0">
            <Column field="plName" :header="$t('label.plName')"></Column>
            <Column field="enName" :header="$t('label.enName')"></Column>
            <Column field="yaw" :header="$t('label.yaw')"></Column>
            <Column field="pitch" :header="$t('label.pitch')"></Column>
            <Column field="arrowAngle" :header="$t('label.arrowAngle')"></Column>
            <Column :header="$t('label.action')" v-if="role.isAdmin()">
                <template #body="slotProps">
                    <Button type="button" icon="pi pi-pencil" class="p-button-info p-mr-1" v-if="false"
                            @click="editNeighbour(slotProps.data)" v-tooltip.top="$t('button.edit')"></Button>
                    <Button type="button" icon="pi pi-map-marker" class="p-button-info p-mr-1"
                            @click="showMapEditDialog(slotProps.data)" v-tooltip.top="$t('button.edit')"></Button>
                    <Button type="button" icon="pi pi-trash" class="p-button-danger"
                            @click="removeFromList(slotProps.data.id)" v-tooltip.top="$t('button.remove')"></Button>
                </template>
            </Column>
        </DataTable>

        <NeighbourMap class="p-mt-5 p-mb-5" v-if="$route.params.id" @reassign-poi="reassignPOI" ref="neighbourMap"/>

        <Dialog :header="$t('dialog.poiEdition')" :visible="showEditDialog"
                :style="{width: '50vw'}" :modal="true" :closable="false">
            <div class="p-grid p-ml-2 p-mr-2 p-mt-2">
                <div class="p-field p-col-6 p-md-6">
                    <CustomInputNumber name="yaw" v-model="editedItem.yaw" :min="-180"
                                       :max="180" :minFractionDigits="1" :maxFractionDigits="7"
                                       :label="$t('label.yaw')"/>
                </div>
                <div class="p-field p-col-6 p-md-6">
                    <CustomInputNumber name="pitch" v-model="editedItem.pitch" :min="-90"
                                       :max="90" :minFractionDigits="1" :maxFractionDigits="7"
                                       :label="$t('label.pitch')"/>
                </div>
                <div class="p-field p-col-6 p-md-6">
                    <CustomInputNumber name="arrowAngle" v-model="editedItem.arrowAngle" :min="0"
                                       :max="360" :minFractionDigits="1" :maxFractionDigits="7"
                                       :label="$t('label.arrowAngle')"/>
                </div>
            </div>

            <template #footer>
                <Button :label="$t('button.cancel')" icon="pi pi-times" @click="closeEditDialog"/>
                <Button :label="$t('button.save')" icon="pi pi-check" @click="saveDialogData"/>
            </template>
        </Dialog>

        <Dialog :header="$t('dialog.poiEdition')" :visible="selectedNeighbour.id != null"
                :style="{width: '80vw'}" :modal="true" :closable="false">
            <div class="p-grid p-ml-2 p-mr-2 p-mt-2">
                <POIEditNeighbourView
                    :neighbour-id="selectedNeighbour.id" ref="mapView"
                    :pitch="selectedNeighbour.pitch"
                    :yaw="selectedNeighbour.yaw"/>
            </div>

            <template #footer>
                <div class="p-grid p-ml-2 p-mr-2 p-mt-2">
                    <CustomInputNumber name="arrowAngle" v-model="selectedNeighbour.arrowAngle" :min="0"
                                       :max="360" :minFractionDigits="1" :maxFractionDigits="7"
                                       :label="$t('label.arrowAngle')"/>
                </div>
                <Button :label="$t('button.cancel')" icon="pi pi-times" @click="closeMapEditDialog"/>
                <Button :label="$t('button.save')" icon="pi pi-check" @click="saveMapDialogData"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
    import {
        getPOIListWithNeighboursDataUsingGET as getPOIListWithNeighboursData,
    } from "@/swagger/vue-api-client";
    import Dialog from "primevue/dialog";
    import Dropdown from "primevue/components/dropdown/Dropdown";
    import Button from "primevue/components/button/Button";
    import Toolbar from "primevue/components/toolbar/Toolbar";
    import DataTable from "primevue/components/datatable/DataTable";
    import Column from "primevue/components/column/Column";
    import Tooltip from "primevue/tooltip";
    import {SystemRole} from "../../../utils/SystemRole";
    import NeighbourMap from "./NeighbourMap";
    import CustomInputNumber from "../../../components/form/CustomInputNumber";
    import POIEditNeighbourView from "../POIEditNeighbourView";

    export default {
        name: "NeighbourListView",

        components: {
            POIEditNeighbourView,
            NeighbourMap,
            Dropdown,
            Button,
            Toolbar,
            DataTable,
            Column,
            Dialog,
            CustomInputNumber,
        },

        directives: {
            tooltip: Tooltip,
        },

        data() {
            return {
                POIs: [],
                selectedPOIList: [],
                selectedPOI: null,
                role: SystemRole,
                showEditDialog: false,
                editedId: null,
                editedItem: {},
                selectedNeighbour: {
                    id: null,
                    pitch: null,
                    yaw: null,
                    arrowAngle: null,
                },
            };
        },

        methods: {
            editNeighbour(data) {
                this.editedItem = Object.assign({}, data);
                this.editedId = data.id;
                this.showEditDialog = true;
            },

            saveDialogData() {
                const idx = this.selectedPOIList.findIndex(p => p.id === this.editedId);
                const globalIdx = this.POIs.findIndex(p => p.id === this.editedId);
                if (idx > -1) this.selectedPOIList[idx] = Object.assign({}, this.editedItem);
                if (globalIdx > -1) this.POIs[globalIdx] = Object.assign({}, this.editedItem);
                this.editedId = null;
                this.editedItem = {};
                this.showEditDialog = false;
                this.sync();
            },

            closeEditDialog() {
                this.showEditDialog = false;
                this.editedId = null;
                this.editedItem = {};
            },

            showMapEditDialog(data) {
                this.selectedNeighbour.id = data.id;
                this.selectedNeighbour.pitch = data.pitch;
                this.selectedNeighbour.yaw = data.yaw;
                this.selectedNeighbour.arrowAngle = data.arrowAngle;
            },

            closeMapEditDialog() {
                this.selectedNeighbour = {
                    id: null,
                    pitch: null,
                    yaw: null,
                    arrowAngle: null,
                };
            },

            saveMapDialogData() {
                const idx = this.selectedPOIList.findIndex(p => p.id === this.selectedNeighbour.id);
                const globalIdx = this.POIs.findIndex(p => p.id === this.selectedNeighbour.id);
                let yaw = null;
                let pitch = null;
                if (this.$refs.mapView.currentCoordinates) {
                    if (this.$refs.mapView.currentCoordinates.yaw) {
                        yaw = this.$refs.mapView.currentCoordinates.yaw.toFixed(7);
                    }
                    if (this.$refs.mapView.currentCoordinates.pitch) {
                        pitch = this.$refs.mapView.currentCoordinates.pitch.toFixed(7);
                    }
                }
                if (idx > -1) {
                    this.selectedPOIList[idx].yaw = yaw;
                    this.selectedPOIList[idx].pitch = pitch;
                    this.selectedPOIList[idx].arrowAngle = this.selectedNeighbour.arrowAngle;
                }
                if (globalIdx > -1) {
                    this.POIs[globalIdx].yaw = yaw;
                    this.POIs[globalIdx].pitch = pitch;
                    this.POIs[globalIdx].arrowAngle = this.selectedNeighbour.arrowAngle;
                }
                this.sync();
                this.closeMapEditDialog();
            },

            addToList() {
                if (!this.selectedPOI) return;
                this.selectedPOIList.push({
                    id: this.selectedPOI.id,
                    plName: this.selectedPOI.plName,
                    enName: this.selectedPOI.enName,
                    pitch: null,
                    yaw: null,
                    arrowAngle: null,
                });
                this.$refs.neighbourMap.checkNewNeighbour(this.selectedPOI.id);
                this.selectedPOI = null;
                this.sync();
            },

            removeFromList(poiId) {
                const poiToBeRemoved = this.selectedPOIList.find(p => p.id === poiId);
                const index = this.selectedPOIList.indexOf(poiToBeRemoved);
                if (index > -1) {
                    this.selectedPOIList.splice(index, 1);
                }
                this.$refs.neighbourMap.uncheckNeighbour(poiId);
                this.sync();
            },

            reassignPOI(item, marker) {
                if (marker === "red") return;
                if (marker === "orange") {
                    this.removeFromList(item.id);
                } else {
                    this.selectedPOI = item;
                    this.addToList();
                }
            },

            sync() {
                this.$emit("update:poi-list", this.selectedPOIList.map(p => ({
                    id: p.id, pitch: p.pitch, yaw: p.yaw, arrowAngle: p.arrowAngle,
                })));
            },

            matchPOIs() {
                this.selectedPOIList = this.selectedPOIList.map(item => ({
                    id: item.id,
                    plName: this.POIs.find(p => p.id === item.id).plName,
                    enName: this.POIs.find(p => p.id === item.id).enName,
                    pitch: this.POIs.find(p => p.id === item.id).pitch,
                    yaw: this.POIs.find(p => p.id === item.id).yaw,
                    arrowAngle: this.POIs.find(p => p.id === item.id).arrowAngle,
                }));
            },
        },

        computed: {
            POIOptions() {
                return this.POIs.filter(poi => !this.selectedPOIList.map(sel => sel.id).includes(poi.id)
                    && this.$route.params.id !== poi.id);
            },
        },

        created() {
            getPOIListWithNeighboursData({id: this.$route.params.id}).then((response) => {
                this.POIs = response.data.sort((item1, item2) => {
                    if (!item1.area) return 1;
                    if (item1.area > item2.area) {
                        return 1;
                    }
                    return -1;
                });
                this.matchPOIs();
            });
        },

        watch: {
            poiList: {
                handler(val) {
                    this.selectedPOIList = val;
                    if (this.POIs.length > 0) this.matchPOIs();
                },
                immediate: true,
            },
        },

        props: {
            poiList: Array,
        },
    };
</script>

<style scoped>

</style>
